import React, { useContext } from 'react';
import { TransactionContext } from 'components/TransactionContext';
import { Button } from 'ui';

export const SubmitTransactionBtn = ({ disabled }) => {
    const { 
        onSubmit, 
        showMainSubmitBtn,
        processing, 
        submitBtnText 
    } = useContext(TransactionContext);


    const handleSubmit = e => {
        e.preventDefault();
        onSubmit();
    };

    if (!showMainSubmitBtn) {
        return null;
    }

    return <Button disabled={disabled || processing} loading={processing} fluid black size="large" onClick={handleSubmit}>{submitBtnText}</Button>;
};
