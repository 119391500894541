import React from 'react';
import { CoreSelect } from './CoreSelect';

export const Select = ({ options, children, addNullOption = true, ...props }) => {
    if (options) {
        return (
            <CoreSelect {...props}>
                {addNullOption && <option value={null}></option>}
                {options.map(o => {
                    if (typeof o === 'string') {
                        o = { value: o, text: o };
                    }
                    return <option key={`${props.name}-${o.value}`} value={o.value}>{o.text}</option>;
                })}
            </CoreSelect>
        )
    }

    return <CoreSelect {...props}>{children}</CoreSelect>;
};
