import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Message } from 'ui';
import { validateEmail } from 'utils/validateEmail';
import EmailField from './EmailField';
import SharedEmailMessage, { emailMaybeShared } from './SharedEmailMessage';
import { AuthError, useAuth0 } from 'components/auth0/Auth0Context';
import { STAGES } from '.';
import { TransactionContext } from 'components/TransactionContext';
import axios from 'axios';

const EmailValidationStage = ({ setStage, setEmail: _setEmail, email: _email, setAccountExistsResponse, banSharedEmails, showSharingWarning, allowNewAccounts = false, passwordOnly = false }) => {
    const [error, setError] = useState();
    const [isSending, setIsSending] = useState(false);
    const { startPasswordless } = useAuth0();
    const [email, setEmail] = useState(_email);
    const { recaptcha } = useContext(TransactionContext);

    // on mount, focus the email field
    useEffect(() => {
        const emailField = document.getElementById('email-field');
        if (emailField) {
            emailField.focus();
        }
    }, []);

    const disabled = useMemo(() => {
        if (!email || !email.length) {
            return true;
        }

        if (banSharedEmails && emailMaybeShared(email)) {
            return true;
        }

        return false;
    }, [email, banSharedEmails]);

    const handleEmailChange = e => {
        setError(false);
        setEmail(e.target.value);
    };

    const handleFormSubmit = e => {
        e.preventDefault();

        if (!validateEmail(email)) {
            return setError('Enter a valid email address');
        }
        
        (async () => {
        
            try {            
                setIsSending(true);
                
                const recaptchaToken = await recaptcha.executeAsync();
                recaptcha.reset();
                const accountExistsResponse = await axios.post(`${process.env.REACT_APP_SKIFT_API_DOMAIN}/api/public/pre-login/validate-email`, { email, recaptcha: recaptchaToken });

                if (!accountExistsResponse.data.account_exists && !allowNewAccounts) {
                    throw new AuthError('Account does not exist', 'account_not_found');
                }

                if (accountExistsResponse.data.deleted) {
                    throw new AuthError('Account deleted', 'account_deleted');
                }
                
                let nextStage = STAGES.ENTER_PASSWORD;
                if (!passwordOnly) {
                    await startPasswordless(email);
                    nextStage = STAGES.VERIFY_CODE;
                }
                
                _setEmail(email);
                setAccountExistsResponse(accountExistsResponse.data);
                setStage(nextStage);
                
            } catch (err) {
                if (err.code === 'account_not_found') {
                    return setError("We couldn't find an account with that email address. Please try again.");
                }

                if (err.code === 'account_deleted') {
                    return setError("Your account has been deleted. Please contact support.");
                }

                setError("An error occurred. Please try again.");
            } finally {
                setIsSending(false);
            }
        })();
    };
    
    return (
        <form onSubmit={handleFormSubmit}>
            {error && <Message error>{error}</Message>}
            <SharedEmailMessage email={email} showSharingWarning={showSharingWarning} banSharedEmails={banSharedEmails} />
            <EmailField id="email-field" disabled={isSending} value={email} onChange={handleEmailChange} />
            <Button disabled={disabled} $loading={isSending} black>Continue</Button>
        </form>
    );
};

export default EmailValidationStage;
