import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Message, Panel, TextField, TransactionMessage } from 'ui';

const EnterInviteCode = ({ error }) => {
    const { invitecode: inviteCodeParam } = useParams();

    const [code, setCode] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (inviteCodeParam) {
            setCode(inviteCodeParam);
        }
    }, [inviteCodeParam, setCode]);

    const handleSubmit = e => {
        e.preventDefault();
        navigate('/enterprise-welcome/' + code);
    };
    
    return (
        <TransactionMessage heading="" showDashboardLink={false}>
            <Panel padded>
                <h1>Welcome</h1>
                <p><strong>Enter your invite code below to get started</strong></p>
                {error && <Message error>{error}</Message>}
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Invite Code"
                        name="invite_code"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                    />
                    <Button black disabled={!code}>Continue</Button>
                </form>
            </Panel>
        </TransactionMessage>
    );
};

export default EnterInviteCode;
