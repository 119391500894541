import React from 'react';
import { useGetRegistrationsWithActiveCheckInQuery } from 'store/features/user-api';
import { Message } from 'ui';

const ViewTicketBtn = () => {
    const { data } = useGetRegistrationsWithActiveCheckInQuery();

    if (data && data.length) {
        return data.map(reg => {
            const event = reg?.event || reg?.webinar;
            if (!event) {
                return null;
            }
            return (
                <Message key={`view-ticket-${reg?.id}`} heading={`Your ticket to ${event?.name}`}>
                    <a href={`/event/${event?.event_code}/ticket`} target="_blank" rel="noopener noreferrer">View your ticket</a>
                </Message>
            )
        });
    }

    return null;
};

export default ViewTicketBtn;
