import { Button } from "./Button";

const { default: styled } = require("styled-components");

const Wrap = styled.div`
    margin: 10px;

    padding: 40px 30px;

    max-width: 400px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;

    background-color: ${props => props.color};

    h3 {
        font-size: 33px;
        margin: 0;
    }

    p {
        margin: 15px 0 20px;
        font-size: 16px;
    }

    @media screen and (max-width: 860px) and (min-width: 751px) {
        margin: 8px;
        padding: 30px 15px;
    }
`;

const productColorFromDomain = domain => {
    switch(domain) {
        case 'skift.com':
            return '#4de6be';
        case 'research.skift.com':
            return '#7cccf8';
        case 'dlr.skift.com':
            return '#7CCCF8';
        case 'airlineweekly.skift.com':
            return '#FA8989';
        default:
            return '#FFF22F'; // yellow, 
    }
};

export const ProductSummary = ({ product }) => {
    const color = productColorFromDomain(product.domain);

    if (!product.start_reading) {
        return null;
    }

    return (
        <Wrap color={color}>
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <Button as="a" black href={product.start_reading}>Start Reading</Button>
        </Wrap>
    )
};
