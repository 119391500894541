import React, { useContext } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { TransactionContext } from 'components/TransactionContext';
import { useContentItem } from 'hooks';

import { Panel, SelectField } from 'ui';
import { formatCurrency } from 'utils/formatNumber';
import { setMultiSeatAmount } from 'store/features/transaction';

const Quantity = ({ inactive }) => {
    const { data: item } = useContentItem();
    const dispatch = useDispatch();
    const currency = item?.details?.currency;
    const value = useSelector(state => state.transaction.quantity);

    const prices = item?.details?.group_ticket_prices || [];
    const options = prices.map((price, quantity) => {
                            if (quantity < 2) return null;
                            
                            return {
                                key: `quantity-option-${quantity}`,
                                value: quantity,
                                text: `${quantity} tickets for ${formatCurrency(price, currency)} (${formatCurrency(price / quantity, currency)} each)`
                            };
                        }).filter(opt => opt);

    const { processing } = useContext(TransactionContext);

    const handleChange = e => {
        e.preventDefault();
        const quantity = +e.target.value;

        dispatch(setMultiSeatAmount({
            quantity,
            amount: prices[quantity]
        }));
    };
    
    if (!options.length) {
        return null;
    }

    return (
        <Panel padded inactive={inactive}>
            <h2>How many tickets do you want to purchase?</h2>
            <SelectField
                disabled={processing}
                options={options}
                value={value}
                focus={true}
                addNullOption={false}
                onChange={handleChange}
            />
        </Panel>
    )
};

export default Quantity;
