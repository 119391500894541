import React from 'react';
import { useContentItem } from 'hooks';
import { 
    FullScreenLoader, 
    FullScreenMessage
} from 'ui';
import { DEFAULT_ERROR_MESSAGE } from 'utils/constants';

const EventFetcher = ({ children }) => {
    const { data: item, isLoading, error: contentError } = useContentItem();

    if (isLoading) {
        return <FullScreenLoader text="Loading Event" />
    }
    
    if (!item || contentError) {
        return <FullScreenMessage error heading="Error loading event">{contentError ? (contentError?.data?.error?.message || DEFAULT_ERROR_MESSAGE) : 'Event not found'}</FullScreenMessage>;
    }

    return children;
};

export default EventFetcher;
