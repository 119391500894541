import React from 'react';
import { Link } from 'react-router-dom';
import SummaryPane from './SummaryPane';

const TransactionsPanel = ({ to = '/transactions' }) => (
    <SummaryPane heading="Transactions" to={to} buttonText="View">
        <p><Link to={to}>Click here</Link> to view transactions</p>
    </SummaryPane>
);

export default TransactionsPanel;
