import React from 'react';

import { TransactionMessage, Button } from 'ui';

const LoggedOut = () => (
    <TransactionMessage heading="See you again soon" showDashboardLink={false}>
        <p>You are signed out</p>
        <p><Button as="Link" black to="/login">Sign in</Button></p>
    </TransactionMessage>
);

export default LoggedOut;
