import styled from "styled-components";
import { Flex } from "./Flex";



const StatWrap = styled.div`
`;

const StatLabel = styled.h4`
    margin: 0 0 5px 0;
    font-size: 14px;
    opacity: 0.6;
`;

const StatValue = styled.p`
    margin: 0;
`;
export const Stats = styled(Flex)`
    margin: 0 -10px;
    flex-wrap: wrap;

    ${StatWrap} {
        margin: 10px;
    }

    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
`;

export const Stat = ({ label, children }) => (
    <StatWrap>
        <StatLabel>{label}</StatLabel>
        <StatValue>{children}</StatValue>
    </StatWrap>
);
