import React from 'react';
import AutoRenewIncentive from './AutoRenewIncentive';
import Upsell from './Upsell';

const Promo = ({ subscription }) => {
    const upsellPlan = subscription?.plan?.upsell_plan;
    const upsellText = subscription?.plan?.upsell_text;
    const renewIncentiveEnabled = subscription?.plan?.enable_auto_renew_incentive;

    if (renewIncentiveEnabled && !subscription.auto_renew && subscription.unit_amount_in_cents > 0) {
        return <AutoRenewIncentive subscription={subscription} />
    }

    if (upsellPlan && upsellText) {
        return <Upsell subscription={subscription} />;
    }

    return null;
};

export default Promo;
