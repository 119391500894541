import React from 'react';

import { useContentItem } from 'hooks';
import { TransactionSummary } from 'ui';
import { parseSiteAccessForSiteInfo } from 'utils/parseSiteAccessForSiteInfo';
import styled from 'styled-components';

const ProductHeading = styled.h3`
    font-size: 20px;
`;

const MultiUserTransactionSummary = () => {
    const { data: item, isFetching } = useContentItem();
    
    const siteInfo = parseSiteAccessForSiteInfo(item?.details?.site_access);

    const { name: productName, logo_url } = siteInfo;

    if (!item) {
        return;
    }

    return (
        <TransactionSummary 
            loading={isFetching}
            productName={productName} 
            logoUrl={logo_url} 
        >
            <ProductHeading>{productName} Multi-User</ProductHeading>
        </TransactionSummary>
    );
};

export default MultiUserTransactionSummary;
