import React, { useMemo } from 'react';
import { useGetPurchasesQuery } from 'store/features/user-api';
import SummaryPane from '../SummaryPane';

const ContentSummary = () => {
    const { data: transactions } = useGetPurchasesQuery();
    const content = transactions?.content;

    const text = useMemo(() => {
        return `You have purchased ${content.length} items.`;
    }, [content]);


    if (!content.length) {
        return;
    }

    return (
        <SummaryPane heading="Content Purchases" to="/content">
            <p>{text}</p>
        </SummaryPane>
    );
};

export default ContentSummary;
