import { useContentItem } from 'hooks';
import React, { useMemo } from 'react';
import { Message } from 'ui';

const IncompatiblePlanWarning = () => {
    const { data: item } = useContentItem();

    const incompatiblePlan = useMemo(() => {
        if (!item) return null;

        const { details: { has_incompatible_plans, incompatible_plans } } = item;

        if (!has_incompatible_plans || !has_incompatible_plans.length) {
            return null;
        }

        return incompatible_plans.find(p => p.plan_code === has_incompatible_plans[0].plan_code);
    }, [item]);

    if (!incompatiblePlan) {
        return null;
    }

    return (
        <Message warning heading="You have an incompatible subscription">
            <p>You have an active subscription to <strong>{incompatiblePlan.name}</strong> which is incompatible with this product. For more information please <a href="mailto:support@skift.com">contact us</a>.</p>
        </Message>
    )
};

export default IncompatiblePlanWarning;
