import { useAuth0 } from 'components/auth0/Auth0Context';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useHubspotTracking = () => {
    const [lastLocation, setLastLocation] = useState();
    const email = useSelector(state => state.tracking.email);
    const { isAuthenticated } = useAuth0();
    const location = useLocation();

    useEffect(() => {
        window._hsq = window._hsq || [];

        let sendIdentityPayload = false;
        if (email) {
            // send to the GTM datalayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                email
            });

            const identityPayload = { email };

            if (isAuthenticated) {
                identityPayload.last_login = Math.floor(Date.now());
            }

            window._hsq.push(['identify', identityPayload]);
            sendIdentityPayload = true;
        }
        
        if (sendIdentityPayload || (lastLocation && location !== lastLocation)) {
            window._hsq.push(['trackPageView']);
        }

        setLastLocation(location);
    }, [email, isAuthenticated, lastLocation, location, setLastLocation]);
};

export default useHubspotTracking;
