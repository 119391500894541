import React from 'react';
import { useContentItem } from 'hooks';
import { Message } from 'ui';
import { DEFAULT_TOS_LINK, SHARED_EMAIL_BLACKLIST } from 'utils/constants';
import { validateEmail } from 'utils/validateEmail';

export const emailMaybeShared = email => {
    if (!email) {
        return false;
    }

    const emailUsername = email.split('@')[0];

    return SHARED_EMAIL_BLACKLIST.includes(emailUsername);
};

const SharedEmailWarning = ({ email, showSharingWarning, banSharedEmails }) => {
    const { data: item } = useContentItem();

    if (!emailMaybeShared(email) || !validateEmail(email)) {
        return null;
    }

    if (showSharingWarning) {
        return (
            <Message>
                <p>Looks like you are using a group/shared email to purchase this single-user product, please contact <a href="mailto:support@skift.com">support@skift.com</a> for multi-user licenses. Sharing a single-user license is prohibited under our <a href={item?.details?.toc_link || DEFAULT_TOS_LINK}>terms of use</a>.</p>
            </Message>
        );
    }

    if (banSharedEmails) {
        return (
            <Message error>
                <p>Looks like you are using a group/shared email to purchase this single-user product, please contact <a href="mailto:support@skift.com">support@skift.com</a> for multi-user licenses. Sharing a single-user license is prohibited under our <a href={item?.details?.toc_link || DEFAULT_TOS_LINK}>terms of use</a>.</p>
            </Message>
        );
    }

    return null;
};

export default SharedEmailWarning;
