import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, ProductSummary, TransactionMessage } from 'ui';

const TransactionComplete = () => {
    const transactionResponse = useSelector(state => state.transaction.transactionResponse);

    const subs = transactionResponse?.active_subscriptions || [];

    let body = <p>Click the link below to view your dashboard</p>;

    if (subs.length) {
        const sites = subs[0].plan?.site_access || [];

        body = (
            <>
                <p>You have unlocked the following product{sites.length > 1 && 's'}</p>
                <Flex wrap="wrap" justifyContent="center" alignItems="stretch" style={{ margin: '20px 0'}}>
                    {sites.map(s => <ProductSummary key={`prod-${s.id}`} product={s} />)}
                </Flex>
            </>
        );
    }

    return (
        <TransactionMessage
            heading="Thank you"
        >{body}</TransactionMessage>
    );
};

export default TransactionComplete;
