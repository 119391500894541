import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FullScreenLoader, FullScreenMessage } from 'ui';
import { DEFAULT_ERROR_MESSAGE } from 'utils/constants';
import { useGetRegistrationForTicketQuery } from 'store/features/user-api';

const AddToWalletRedirect = () => {
    const { eventcode: eventCode, platform } = useParams();

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const notOnAppleDevice = platform === 'apple' && !isIOS;
    const validPlatform = platform === 'apple' || platform === 'google';
    const { data: registration, isFetching, error } = useGetRegistrationForTicketQuery({ eventCode }, { skip: notOnAppleDevice || !validPlatform });

    useEffect(() => {
        if (!registration || !registration.token) return;

        window.location.href = `${process.env.REACT_APP_SKIFT_API_DOMAIN}/wallet-passes/${platform}?rt=${registration.token}`;
    }, [registration, platform]);

    if (!validPlatform) {
        return <FullScreenMessage heading="Invalid platform" error>Invalid platform. Please use either 'apple' or 'google'.</FullScreenMessage>;
    }

    if (notOnAppleDevice) {
        return <FullScreenMessage heading="Open your confirmation email on Your iPhone or iPad">Add to Apple Wallet is only on an iOS device like iPhone or iPad.</FullScreenMessage>;
    }

    if (isFetching) {
        return <FullScreenLoader text="Loading registration" />;
    }

    if (error) {
        return <FullScreenMessage error heading="Error loading registration">{error?.data?.error?.message || DEFAULT_ERROR_MESSAGE}</FullScreenMessage>
    }

    if (!registration.webinar.enable_tickets) {
        return <FullScreenMessage heading="Tickets are not enabled for this event" />;
    }

    return <FullScreenLoader text="Redirecting" />;

};

export default AddToWalletRedirect;
