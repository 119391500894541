import React, { useCallback, useState, useContext, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import CardForm from 'components/RecurlyBilling/CardForm';
import { Button, LinkButton, Message } from 'ui';
import { TransactionContext } from 'components/TransactionContext';
import { useUpdateRecurlyBillingMutation } from 'store/features/user-api';

const ChangeBillingForm = ({ cancel }) => {
    const [nextBilling, setNextBilling] = useState({});
    const [uiError, setUIError] = useState();
    const { validators, callTokenize } = useContext(TransactionContext);
    const [saveBilling, { isLoading: isUpdating, error: backendError, isSuccess }] = useUpdateRecurlyBillingMutation();

    const error = useMemo(() => uiError || backendError?.data?.error?.message, [uiError, backendError]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('Profile saved successfully')
            cancel();
        }
    }, [isSuccess, cancel]);

    const handleSave = useCallback(e => {
        e && e.preventDefault();

        (async () => {
            if (validators) {
                const invalid = validators.filter(valid => {
                    return typeof valid === 'function' && !valid({ billingAddress: nextBilling });
                });

                if (invalid.length) {
                    window.scrollTo({
                        top: document.getElementById('edit-billing-form').offsetTop,
                        behavior: 'smooth'
                    });
                    return;
                };
            }

            let token;
            try {
                token = await callTokenize(); 
            } catch {}

            if (!token) {
                setUIError('Error tokenizing card. Please try again.');
                return;
            }

            saveBilling(token);
        })();
    }, [nextBilling, validators, callTokenize, saveBilling]);

    const handleFieldChange = newValues => {
        setNextBilling({
            ...nextBilling,
            ...newValues
        });
    };

    return (
        <div id="edit-billing-form" style={{ maxWidth: '450px' }}>
            {error && <Message error>{error}</Message>}
            <CardForm disabled={isUpdating} passedValues={nextBilling} onChange={handleFieldChange} />
            <Button loading={isUpdating} onClick={handleSave} black fluid style={{ marginTop: '20px' }}>Save</Button>
            {!isUpdating && <p style={{ textAlign: 'center' }}><LinkButton onClick={cancel}>Cancel</LinkButton></p>}
        </div>
    );  
};

export default ChangeBillingForm;
