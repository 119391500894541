import React from 'react';

import { useContentItem } from 'hooks';
import { TransactionSummary } from 'ui';
import { parseSiteAccessForSiteInfo } from 'utils/parseSiteAccessForSiteInfo';
import PlanOptionPicker from './PlanOptionPicker';
import styled from 'styled-components';
import Upsell from './Upsell';

const ProductHeading = styled.h3`
    font-size: 20px;
`;

const ContentTransactionSummary = () => {
    const { data: item, isFetching } = useContentItem();
    
    const siteInfo = parseSiteAccessForSiteInfo(item?.details?.site_access);

    const { id: siteId, name: productName, logo_url } = siteInfo;

    if (!item) {
        return;
    }

    return (
        <TransactionSummary 
            loading={isFetching}
            productName={productName} 
            logoUrl={logo_url} 
        >
            <ProductHeading>{productName}</ProductHeading>
            <PlanOptionPicker siteId={siteId} />
            <Upsell />
        </TransactionSummary>
    );
};

export default ContentTransactionSummary;
