import React from 'react';
import styled from 'styled-components';
import SkiftLogo from 'assets/skift-logo.svg';
import { useAuth0 } from 'components/auth0/Auth0Context';
import { Flex } from 'ui';
import LoginLink from 'components/LoginLink';
import { Link } from 'react-router-dom';


const Wrap = styled.div`
    padding: 15px;
    background-color: #fff;
    box-shadow: 1px 1px 3px rgb(0 0 0 / 15%);
    position: fixed;
    width: 100%;
    z-index: 3;
    height: 65px;

    a {
        font-size: 15px;
        text-decoration: none;
    }
`;

const WidthConstrain = styled(Flex)`
    max-width: 1000px;
    margin: 0 auto;
`;

const Logo = styled.img`
    width: 80px;
`;

const Header = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <Wrap>
            <WidthConstrain justifyContent="space-between" alignItems="center">
                <Link to="/"><Logo src={SkiftLogo} alt="Skift" /></Link>
                {isAuthenticated ? <a href="/loggedout">Sign Out</a> : <LoginLink text="Sign In" />}
            </WidthConstrain>
        </Wrap>
    )
};

export default Header;
