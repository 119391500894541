import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "utils/formatNumber";

export const useVariableAmountValidation = ({ allow_no_payment, variable_min_amount }) => {
    const [errors, setErrors] = useState([]);
    const [validationRan, setValidationRan] = useState();
    const currency = useSelector(state => state.transaction.currency);

    const validate = useCallback(({ amount: value = 0 }) => {        
        setValidationRan(true);

        if (!allow_no_payment && value === 0) {
            setErrors([{
                field: 'variableAmount',
                text: `Amount must be greater than ${formatCurrency(variable_min_amount ? variable_min_amount : 0, currency)}`
            }]);
            
            return false; // invalid
        }

        if (value < 0 || (value > 0 && value < variable_min_amount)) {
            setErrors([{
                field: 'variableAmount',
                text: `Amount must be greater than ${formatCurrency(variable_min_amount, currency)}`
            }]);
            
            return false; // invalid
        }

        setErrors([]);
        
        return true;
    }, [allow_no_payment, variable_min_amount, currency]);

    return [errors, validate, validationRan];
};
