import PageTitle from 'components/PageTitle';
import ViewTransactionPageEvent from 'components/ViewTransactionPageEvent';
import { useContentItem } from 'hooks';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setItemId, setItemName, setTransactionType, setMultiSeatAmount, setCurrency } from 'store/features/transaction';
import { TRANSACTION_TYPES } from 'utils/constants';

import Main from './Main';

const Purchase = ({ transactionType }) => {
    const dispatch = useDispatch();
    const { itemid: itemId } = useParams();
    const { data: item } = useContentItem();
    const { name, enterprise_eligible, multi_user_prices } = item?.details || {};
    const quantity = useSelector((state) => state.transaction.seats || 2);
    const amount = useSelector((state) => state.transaction.amount);

    useEffect(() => {
        dispatch(setTransactionType(transactionType));
    }, [dispatch, transactionType, enterprise_eligible]);

    useEffect(() => {
        dispatch(setItemId(itemId));
    }, [dispatch, itemId]);

    useEffect(() => {
        dispatch(setItemName(name));
    }, [dispatch, name]);

    useEffect(() => {
        if (amount) return;

        if (transactionType === TRANSACTION_TYPES.MULTI_USER_SUBSCRIPTION && enterprise_eligible) {
            const _quantity = (multi_user_prices || []).length < quantity ? 2 : quantity;
            const amount = (multi_user_prices || []).length > 1 ? multi_user_prices[_quantity] : null;
            dispatch(setMultiSeatAmount({ quantity: _quantity, amount }));
            dispatch(setCurrency('USD'));
        }
    }, [dispatch, transactionType, enterprise_eligible, multi_user_prices, quantity, amount]);

    return (
        <>
            <PageTitle title={name && `${transactionType === TRANSACTION_TYPES.SUBSCRIPTION ? 'Subscribe to' : 'Buy'} ${name}`}>
                <Main />
            </PageTitle>;
            <ViewTransactionPageEvent type={transactionType} itemId={itemId} itemName={name} />
        </>
    );
};

export default Purchase;
