import React from 'react';

import { recurlyAltMethods } from 'utils/constants';
import { isApplePayEnabled } from 'utils/isApplePayEnabled';
import { Flex, PaymentMethodButton } from 'ui';

import paypalLogo from 'assets/payments/paypal.png';
import applePayLogo from 'assets/payments/applepay.svg';

const AltMethodPicker = ({ setAltMethod }) => (
    <Flex justifyContent="center" alignItems="stretch" style={{ marginBottom: '30px' }} >
        <PaymentMethodButton onClick={() => setAltMethod(recurlyAltMethods.PAYPAL)}><img src={paypalLogo} alt="PayPal" style={{ height: '30px' }} /></PaymentMethodButton>
        {isApplePayEnabled() && <PaymentMethodButton onClick={() => setAltMethod(recurlyAltMethods.APPLEPAY)}><img src={applePayLogo} alt="ApplePay" /></PaymentMethodButton>}
    </Flex>
);

export default AltMethodPicker;
