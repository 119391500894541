import { ucFirst } from "./ucFirst";

export const splitCollectionIntoCategories = (collection, func, categories) => {
    if (!collection || typeof func !== 'function') return collection;

    const organized = collection.reduce((a, item) => {
        const cat = func(item);
        if (!cat) return a;

        a[cat] = a[cat] || [];
        a[cat].push(item);
        return a;
    }, {});

    if (!categories) {
        return organized;
    }

    return categories.reduce((a, cat) => {
        if (typeof cat === 'string') {
            cat = {
                key: cat,
                heading: ucFirst(cat)
            };
        }

        if (organized[cat.key] && organized[cat.key].length) {
            cat.items = organized[cat.key];
            a.push(cat);
        }

        return a;
    }, []);
};
