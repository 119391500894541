import SupportFooter from 'components/SupportFooter';
import React from 'react';
import styled from 'styled-components';
import { Flex } from 'ui';
import Breadcrumbs from './Breadcrumbs';
// import EmailVerifiedBanner from './EmailVerifiedBanner';
import Nav from './Nav';
import ViewTicketBtn from './ViewTicketBtn';
import SharingWarningBanner from './SharingWarningBanner';

const Wrap = styled.div`
    padding: 40px 15px;

    @media screen and (max-width: 800px) {
        padding: 20px 15px;
    }
`;


const WidthConstrain = styled(Flex)`
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

const Content = styled.div`
    flex-grow: 1;
    background-color: #fff;
    padding: 30px;
    margin-left: 50px;
    box-shadow: 1px 1px 3px rgb(0 0 0 / 15%);
    position: relative;

    @media screen and (max-width: 800px) {
        margin-top: 20px;
        margin-left: 0;
        padding: 20px;
        width: 100%;
    }
`;

const Main = ({ children, breadcrumbs }) => (
    <Wrap>
        <WidthConstrain>
            <Nav />
            <Content>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <SharingWarningBanner />
                {/* <EmailVerifiedBanner /> */}
                <ViewTicketBtn />
                {children}
            </Content>
        </WidthConstrain>
        <SupportFooter />
    </Wrap>
);

export default Main;
