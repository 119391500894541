import React from 'react';
import { useLoggedInUser } from 'hooks/useLoggedInUser';
import { Loader, Message } from 'ui';
import DashboardFrame from '../DashboardFrame';
import Email from './Email';
import Password from './Password';
import Profile from './Profile';
import { DEFAULT_ERROR_MESSAGE } from 'utils/constants';
import NewsletterPreferences from './Newsletters';

const ProfileScreen = () => {
    const { isLoading, error } = useLoggedInUser();

    if (isLoading) {
        return <DashboardFrame><Loader small>Loading profile</Loader></DashboardFrame>;
    }

    if (error) {
        return <DashboardFrame><Message error heading="Error loading profile">{error?.data?.error?.message || DEFAULT_ERROR_MESSAGE}</Message></DashboardFrame>;
    }

    return (
        <DashboardFrame breadcrumbs={[['Home', '/'], ['Profile', null]]}>
            <Profile />
            <Email />
            <Password />
            <NewsletterPreferences />
        </DashboardFrame>
    )
};

export default ProfileScreen;
