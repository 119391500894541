import React from 'react';
import styled from 'styled-components';
import { Message } from 'ui';

const ErrorList = styled.ul`

    li {
        margin: 0;
    }
`;

export const Errors = ({ errors }) => {
    if (!errors || !errors.length) {
        return null;
    }

    if (errors.length === 1) {
        return (
            <Message error>
                <p>{errors[0].text}</p>
            </Message>
        );
    }

    return (
        <Message error heading="Please correct the errors below">
            <ErrorList>
                {errors.map(e => <li key={e.field}>{e.text}</li>)}
            </ErrorList>
        </Message>
    );
};
