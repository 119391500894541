import React, { useEffect, useState } from 'react';

import EmailValidationStage from './EmailValidationStage';
import { useDispatch } from 'react-redux';
import { maybeSetTrackingEmail } from 'store/features/tracking';
import VerifyCode from './VerifyCode';
import PasswordStage from './PasswordStage';
import { rotateNewAccountCacheKey } from 'store/features/mutation-cache-keys';
import ForgotPassword from './ForgotPassword';

export const STAGES = {
    EMAIL_VALIDATION: 'EMAIL_VALIDATION',
    VERIFY_CODE: 'VERIFY_CODE',
    ENTER_PASSWORD: 'ENTER_PASSWORD',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD'
};

const CreateAccount = ({ 
    allowNewAccounts, 
    passwordOnly, 
    banSharedEmails, 
    showSharingWarning, 
    newAccountHeading, 
    existingAccountHeading 
}) => {    
    const dispatch = useDispatch();
    const [stage, setStage] = useState(STAGES.EMAIL_VALIDATION);
    const [email, setEmail] = useState('');
    const [accountExistsResponse, setAccountExistsResponse] = useState();
    const [resetEmailSent, setResetEmailSent] = useState(false);

    useEffect(() => {
        dispatch(maybeSetTrackingEmail(email));
    }, [dispatch, email]);

    const reset = ({ keepEmail = false } = {}) => {
        setStage(STAGES.EMAIL_VALIDATION);
        setAccountExistsResponse(null);
        setResetEmailSent(false);
        dispatch(rotateNewAccountCacheKey());

        !keepEmail && setEmail('');
    };

    const Wrap = props => (
        <div>
            <h1>{accountExistsResponse?.account_exists ? existingAccountHeading : newAccountHeading}</h1>
            {props.children}
        </div>
    );

    if (stage === STAGES.VERIFY_CODE) {
        return (
            <Wrap>
                <VerifyCode 
                    email={email}
                    allowPassword={accountExistsResponse.allow_password} 
                    accountExistsResponse={accountExistsResponse}
                    allowNewAccounts={allowNewAccounts}
                    reset={reset} 
                    setStage={setStage}
                />
            </Wrap>
        );
    }

    if (stage === STAGES.ENTER_PASSWORD) {
        return (
            <Wrap>
                <PasswordStage
                    email={email}
                    setEmail={setEmail}
                    resetEmailSent={resetEmailSent}
                    reset={reset}
                    setStage={setStage}
                    accountExistsResponse={accountExistsResponse}
                />
            </Wrap>
        );
    }

    if (stage === STAGES.FORGOT_PASSWORD) {
        return (
            <Wrap>
                <ForgotPassword 
                    setEmail={setEmail} 
                    email={email} 
                    setStage={setStage} 
                    setResetEmailSent={setResetEmailSent}
                    reset={reset}
                />
            </Wrap>
        );
    }

    return (
        <Wrap>
            <EmailValidationStage 
                setEmail={setEmail} 
                email={email} 
                banSharedEmails={banSharedEmails}
                showSharingWarning={showSharingWarning}
                setStage={setStage} 
                setAccountExistsResponse={setAccountExistsResponse}
                allowNewAccounts={allowNewAccounts}
                passwordOnly={passwordOnly}
            />
        </Wrap>
    );

};

export default CreateAccount;
