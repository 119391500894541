import React from 'react';
import { useAuth0 } from 'components/auth0/Auth0Context';
import { useParams } from 'react-router-dom';
import { FullScreenLoader, FullScreenMessage } from 'ui';
import { DEFAULT_ERROR_MESSAGE } from 'utils/constants';
import { paramsFromQuerystring } from 'utils/paramsFromQuerystring';
import Ticket from './Ticket';
import { useGetRegistrationForTicketQuery } from 'store/features/user-api';

const EventTicket = () => {
    const { eventcode: eventCode } = useParams();
    const { rt: token } = paramsFromQuerystring();   
    const { isAuthenticated } = useAuth0();
    const hasRequiredParams = (isAuthenticated && eventCode) || token;
    const { data: registration, isFetching, error } = useGetRegistrationForTicketQuery({ eventCode, token }, { skip: !hasRequiredParams });

    if (isFetching) {
        return <FullScreenLoader text="Loading ticket" />;
    }

    if (error || !hasRequiredParams) {
        return <FullScreenMessage error heading="Error loading ticket">{error?.data?.error?.message || DEFAULT_ERROR_MESSAGE}</FullScreenMessage>
    }

    if (!registration.event.enable_tickets) {
        return <FullScreenMessage heading="Tickets are not enabled for this event" />;
    }

    return (
        <Ticket registration={registration} />
    );
};

export default EventTicket;
