import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { TRANSACTION_TYPES } from 'utils/constants';

import useHubspotTracking from 'hooks/useHubspotTracking';

import Purchase from './Purchase';
import EventRegistration from './EventRegistration';
import RegisterForGroup from './EventRegistration/RegisterForGroup';
import GroupTicketPurchase from './GroupTicketPurchase';
import EduCreditRedemption from './EduCreditRedemption';
import LoggedOut from './LoggedOut';
import Login from './Login';
import EnterpriseOnboarding from './EnterpriseOnboarding';
import EnterInviteCode from './EnterpriseOnboarding/EnterInviteCode';
import EventTicket from './EventTicket';
import AddToWalletRedirect from './EventTicket/AddToWalletRedirect';
import FourOFour from './FourOFour';
import Dashboard from './Dashboard/DashboardScreen';
import DashboardProfile from './Dashboard/ProfileScreen';
import ContentScreen from './Dashboard/ContentScreen';
import EventRegistrationsScreen from './Dashboard/EventRegistrationsScreen';
import GroupTicketsScreen from './Dashboard/GroupTicketsScreen';
import SubscriptionsScreen from './Dashboard/SubscriptionsScreen';
import EduCreditsScreen from './Dashboard/EduCreditsScreen';
import RequireAuth from './RequireAuth';
import TransactionReceipts from './Dashboard/TransactionReceipts';
import MultiUserScreen from './Dashboard/MultiUserScreen';
import MultiUserUsers from './Dashboard/MultiUserScreen/Users';
import MultiUserPurchases from './Dashboard/MultiUserScreen/Purchases';
import MultiUserTransactions from './Dashboard/MultiUserScreen/MultiUserTransactions';
import EnterpriseSearch from './EnterpriseSearch';
import IPGateway from './IPGateway';

const Main = () => {
    useHubspotTracking();

    return (
        <Routes>
            <Route path="/subscribe/:itemid/multi-user" element={<Purchase transactionType={TRANSACTION_TYPES.MULTI_USER_SUBSCRIPTION} />} />
            <Route path="/subscribe/:itemid" element={<Purchase transactionType={TRANSACTION_TYPES.SUBSCRIPTION} />} />
            <Route path="/content/:itemid" element={<Purchase transactionType={TRANSACTION_TYPES.ITEM} />} />
            <Route path="/event/:eventid" element={<EventRegistration />} />
            <Route path="/event/:eventid/group/:group" element={<RegisterForGroup />} />
            <Route path="/event/:eventid/groups" element={<GroupTicketPurchase />} />
            <Route path="/redeem-edu-credit/:itemid" element={<EduCreditRedemption />} />
            <Route path="/enterprise-welcome" element={<EnterInviteCode />} />
            <Route path="/enterprise-welcome/:invitecode" element={<EnterpriseOnboarding />} />
            <Route path="/event/ticket" element={<EventTicket />} />
            <Route path="/enterprise-search" element={<EnterpriseSearch />} />

            <Route path="/event/:eventcode/ticket" element={<RequireAuth><EventTicket /></RequireAuth>} />
            <Route path="/event/:eventcode/add-to-wallet/:platform" element={<RequireAuth><AddToWalletRedirect /></RequireAuth>} />
            
            <Route path="/" element={<RequireAuth><Dashboard /></RequireAuth>} />
            <Route path="/profile" element={<RequireAuth><DashboardProfile /></RequireAuth>} />
            <Route path="/subscriptions" element={<RequireAuth><SubscriptionsScreen /></RequireAuth>} />
            <Route path="/content" element={<RequireAuth><ContentScreen /></RequireAuth>} />
            <Route path="/event-registrations" element={<RequireAuth><EventRegistrationsScreen /></RequireAuth>} />
            <Route path="/group-tickets" element={<RequireAuth><GroupTicketsScreen /></RequireAuth>} />
            <Route path="/edu-credits" element={<RequireAuth><EduCreditsScreen /></RequireAuth>} />
            <Route path="/transactions" element={<RequireAuth><TransactionReceipts /></RequireAuth>} />
            <Route path="/multi-user" element={<RequireAuth><MultiUserScreen /></RequireAuth>} />
            <Route path="/multi-user/:id/users" element={<RequireAuth><MultiUserUsers /></RequireAuth>} />
            <Route path="/multi-user/:id/users/page/:page" element={<RequireAuth><MultiUserUsers /></RequireAuth>} />
            <Route path="/multi-user/:id/purchases" element={<RequireAuth><MultiUserPurchases /></RequireAuth>} />
            <Route path="/multi-user/:id/transactions" element={<RequireAuth><MultiUserTransactions /></RequireAuth>} />

            <Route path="/loggedout" element={<LoggedOut />} />
            <Route path="/login" element={<Login />} />
            <Route path="/ip-gateway" element={<IPGateway />} />

            <Route path="*" element={<FourOFour />} />
        </Routes>
    );
};

export default Main;
