import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useGetNewsletterPreferencesQuery, useUpdateNewsletterPreferencesMutation } from 'store/features/user-api';
import { Button, Checkbox, LinkButton, Loader, Message } from 'ui';
import SummaryPane from '../SummaryPane';
import { DEFAULT_ERROR_MESSAGE } from 'utils/constants';

const Properties = ({ prefs }) => {
    const subscribed = (prefs || []).filter(newsletter => newsletter.value);

    if (!subscribed.length) {
        return <p>You're not subscribed to any newsletters yet</p>;
    }
    return (
        <p>You are subscribed to {subscribed.length} newsletter{subscribed.length > 1 ? 's' : ''}</p>
    );
};

const EditForm = ({ setEditing, prefs }) => {
    const [nextPrefs, setNextPrefs] = useState();
    const [savePrefs, { isLoading: isUpdating, error: updateError, isSuccess }] = useUpdateNewsletterPreferencesMutation();

    const error = useMemo(() => updateError?.data?.error?.message, [updateError]);

    useEffect(() => {
        if (!prefs) return;

        setNextPrefs(prefs.reduce((a, { newsletter_code, value }) => {
            a[newsletter_code] = value;
            return a;
        }, {}));
    }, [setNextPrefs, prefs]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('Newsletter preferences updated successfully');
            setEditing(false);
        }
    }, [isSuccess, setEditing]);

    const handleSave = useCallback(e => {
        e.preventDefault();

        savePrefs(nextPrefs);
    }, [nextPrefs, savePrefs]);

    const handleFieldChange = e => {
        const next = { [e.target.name]: e.target.checked };
        setNextPrefs({
            ...nextPrefs,
            ...next
        });
    };

    if (!nextPrefs) {
        // loading current values
        return null;
    }

    return (
        <>
            {error && <Message error>{error}</Message>}
            {prefs.map(({ name: nlName, newsletter_code: key }) => (
                <Checkbox
                    key={key}
                    id={key}
                    name={key}
                    label={nlName}
                    checked={nextPrefs[key]}
                    onChange={handleFieldChange}
                    disabled={isUpdating}
                />
            ))}
            <Button loading={isUpdating} black fluid onClick={handleSave}>Save</Button>
            {!isUpdating && <p style={{ textAlign: 'center' }}><LinkButton onClick={() => setEditing(false)}>Cancel</LinkButton></p>}
        </>
    );
};

const NewsletterPreferences = () => {
    const [editing, setEditing] = useState();
    const { data: prefs, isFetching, error } = useGetNewsletterPreferencesQuery();

    if (error) {
        return (
            <SummaryPane heading='Newsletter Preferences' disabled={true}>
                <Message error>{DEFAULT_ERROR_MESSAGE}</Message>
            </SummaryPane>
        );
    }

    if (isFetching) {
        return (
            <SummaryPane heading='Newsletter Preferences' disabled={true}>
                <Loader small>Loading newsletter preferences</Loader>
            </SummaryPane>
        );
    }

    return (
        <SummaryPane heading='Newsletter Preferences' onClick={() => setEditing(!editing)} buttonText={editing ? 'Cancel' : 'Edit'}>
            {editing ? <EditForm prefs={prefs} setEditing={setEditing} /> : <Properties prefs={prefs} />}
        </SummaryPane>
    );
};

export default NewsletterPreferences;
