import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { triggerEvent } from "store/features/tracking";

const useTriggerEvent = () => {
    const dispatch = useDispatch();
    const trigger = useCallback((type, payload) => {
        dispatch(triggerEvent({ type, payload }));
    }, [dispatch]);

    return trigger;
};

export default useTriggerEvent;
