import styled from 'styled-components';
import { FormLabel } from 'ui/FormLabel';
import chevronDown from 'assets/icons/chevron-down-solid.svg';

const focusStyle = `
    color: #000;
    border-color: #000;

    + ${FormLabel} {
        color: #000;
        font-size: 0.7rem;
        top: -10px;
        transform: translateY(0);
        padding: 3px 6px;
        margin: 0 !important
    }
`;

export const CoreSelect = styled.select`
    color: #b1b1b1;
    border: 1px solid #b1b1b1;
    padding: 15px 20px;
    outline: #000;
    transition: all 0.2s;
    width: 100%;
    background-color: inherit;
    appearance: none;
    font-size: 1rem;
    border-radius: 0;

    background-image: url(${chevronDown});
    background-position: center right 15px;
    background-repeat: no-repeat;
    background-size: auto 15px;

    + ${FormLabel} {
        position: absolute;
        top: 13px;
        left: 20px;
        
        color: #b1b1b1;
        background-color: #fff;
        
        transition: all 0.2s;
        pointer-events: none;
    }
    
    &:focus {
        ${focusStyle}
    }

    ${props => {
        if ((props.value && props.value.length) || props.focus) {
            return focusStyle;
        }
    }}

    ${props => {
        if (props.error) {
            return `
                border-color: ${props.theme.errorColor} !important;

                + ${FormLabel} {
                    color: ${props.theme.errorColor} !important;
                }
            `;
        }
    }}

    &[disabled], &[disabled] + ${FormLabel} {
        cursor: not-allowed;
    }

`;
