/**
 * This file allows us to call "getAccessTokenSilently" from outside the Auth0 context.
 * It's used to add the auth token in the header of all RTK calls.
 * Mount the "Auth0Tokens" component somewhere in the tree as a child of the Auth0 Provider.
 */

import { useAuth0 } from './Auth0Context';

let getAccessTokenSilently = null;

export const auth0HookBridge = {
    getAccessTokenSilently: () => getAccessTokenSilently,
    setAccessTokenSilently: func => (getAccessTokenSilently = func)
};

export const Auth0Tokens = () => {
    const { isAuthenticated, getAccessTokenSilently: auth0GetAccessTokenSilently } = useAuth0();
    auth0HookBridge.isAuthenticated = isAuthenticated;
    auth0HookBridge.setAccessTokenSilently(auth0GetAccessTokenSilently);
    
    return null;
};
