import { useContentItem } from 'hooks';
import React from 'react';
import { Flex, ProductSummary, TransactionMessage } from 'ui';

const TransactionComplete = () => {
    const { data: item, isFetching } = useContentItem();
    const sites = item?.details?.site_access || [];

    let body = <p>Click the link below to view your dashboard</p>;

    if (sites.length) {
        body = (
            <>
                <p>You have unlocked the following product{sites.length > 1 && 's'}</p>
                <Flex wrap="wrap" justifyContent="center" alignItems="stretch" style={{ margin: '20px 0'}}>
                    {sites.map(s => <ProductSummary key={`prod-${s.id}`} product={s} />)}
                </Flex>
            </>
        );
    }

    return (
        <TransactionMessage
            heading="Thank you"
            isFetching={isFetching}
        >{body}</TransactionMessage>
    );
};

export default TransactionComplete;
