import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { useGetTransactionsQuery } from 'store/features/user-api';
import { Loader, BoxedItem, Stats, Stat, Button } from 'ui';
import { formatCurrency } from 'utils/formatNumber';
import DashboardFrame from './DashboardFrame';
import axios from 'axios';
import { getAccessToken } from 'store/add-auth-header';
import { toast } from 'react-toastify';

const TransactionReceipts = ({ companyId, breadcrumbs = [['Home', '/'], ['Subscriptions', '/subscriptions'], ['Transactions', null]], heading = 'Content Transactions' }) => {
    const { data, isFetching } = useGetTransactionsQuery({ companyId });
    const [recurlyToken, setRecurlyToken] = useState();

    const fetchRecurlyToken = useCallback(async () => {
        const config = {
            headers: {}
        };

        const userToken = await getAccessToken();
        if (userToken){
            config.headers.authorization = `Bearer ${userToken}`;
        }

        let url = '/api/user/recurly-token';

        if (companyId) {
            url += `?companyId=${companyId}`;
        }

        const resp = await axios.get(url, config);
        const data = resp.data || {};
        
        setRecurlyToken(data.token);
        return data.token;
    }, [setRecurlyToken, companyId]);

    const openReceipt = useCallback(async id => {
        let token = recurlyToken;

        if (!token) {
            try {
                token = await fetchRecurlyToken();
            } catch {
                return toast.error('Error loading receipt. Please try again.')
            }
        }

        window.open(`https://skift.recurly.com/account/invoices/${id}?ht=${token}`);
    }, [recurlyToken, fetchRecurlyToken]);

    if (isFetching) {
        return <DashboardFrame><Loader small>Loading transactions</Loader></DashboardFrame>;
    }

    return (
        <DashboardFrame breadcrumbs={breadcrumbs}>
            <h2>{heading}</h2>
            {!companyId && <p><em>Please <a href="mailto:support@skift.com">contact us</a> for event ticket receipts.</em></p>}
            {!(data.transactions || []).length && <p>You haven't created any transactions yet</p>} 
            {(data.transactions || []).map(c => {
                return (
                    <BoxedItem
                        key={c?.id}
                        RightChildren={<Button size="small" onClick={() => openReceipt(c.invoice.number)} black>View Receipt</Button>}
                    >
                        <Stats>
                            <Stat label="Transaction Date">{moment(c?.createdAt).format('MM/DD/Y h:mm a')}</Stat>
                            <Stat label="Amount">{formatCurrency(c?.amount, c?.currency)}</Stat>
                        </Stats>
                    </BoxedItem>
                );
            })}
        </DashboardFrame>
    )
};

export default TransactionReceipts;
