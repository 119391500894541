import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRemoveUserFromCompanyMutation } from 'store/features/user-api';
import { Button } from 'ui';

const RemoveUserBtn = ({ user }) => {
    const { id: companyId } = useParams();
    const [execute, res] = useRemoveUserFromCompanyMutation();

    const { isLoading: isRemoving, isError, isSuccess } = res;

    useEffect(() => {
        if (isError) {
            toast.error('Error removing user. Please try again.');
        }
    }, [isError]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('The user was successfully removed');
        }
    }, [isSuccess]);

    const remove = useCallback(() => {
        if (window.confirm(`Are you sure you want to remove this user from the team?\n\n${user.first_name} ${user.last_name}`)) {
            execute({ companyId, userId: user.id });
        }
    }, [user.id, user.last_name, user.first_name, companyId, execute]);

    return <Button black size="xsmall" onClick={remove} loading={isRemoving} disabled={isRemoving}>Remove</Button>;
};

export default RemoveUserBtn;
