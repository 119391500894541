import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setItemId, setTransactionType, setCurrency, setMultiSeatAmount, setItemName } from 'store/features/transaction';
import { useContentItem } from 'hooks';

import Main from './Main';
import { MYSK_EVENT_TYPES, TRANSACTION_TYPES } from 'utils/constants';
import useTriggerEvent from 'hooks/useTriggerEvent';
import PageTitle from 'components/PageTitle';
import ViewTransactionPageEvent from 'components/ViewTransactionPageEvent';

const GroupTicketPurchase = () => {
    const dispatch = useDispatch();
    const { eventid: eventId } = useParams();
    const triggerEvent = useTriggerEvent();
    const { data: item } = useContentItem();
    const { currency, name, group_ticket_prices } = item?.details || {};
    const startingPrice = (group_ticket_prices || []).find(p => p);
    
    useEffect(() => {
        dispatch(setTransactionType(TRANSACTION_TYPES.EVENT_GROUP));
        dispatch(setItemId(eventId));
        triggerEvent(MYSK_EVENT_TYPES.VIEW_TRANSACTION_PAGE, { type: TRANSACTION_TYPES.EVENT_GROUP, itemId: eventId, itemName: name });

    }, [dispatch, eventId, name, triggerEvent]);

    useEffect(() => {
        dispatch(setCurrency(currency));
        dispatch(setItemName(name));

        if (startingPrice) {
            dispatch(setMultiSeatAmount({ quantity: 2, amount: startingPrice }));
        }

    }, [dispatch, currency, name, startingPrice]);


    return (
        <>
            <PageTitle title={name && `Buy group tickets for ${name}`}><Main /></PageTitle>
            <ViewTransactionPageEvent type={TRANSACTION_TYPES.EVENT_GROUP} itemId={eventId} itemName={name} />
        </>
    );
};

export default GroupTicketPurchase;
