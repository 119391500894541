export const ucFirst = str => {
    if (!str) return str;

    const words = str.split(' ').map(word => {
        const letters = word.split('');
        letters[0] = letters[0].toUpperCase();
        return letters.join('');
    });

    return words.join(' ');
};
