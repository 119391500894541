import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { setBraintreeToken } from 'store/features/braintree';
import { getAccessToken } from 'store/add-auth-header';

export const useBraintreeToken = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const token = useSelector(state => state.braintree.token);
    
    const fetchToken = useCallback(() => {        
        (async () => {
            setIsLoading(true);
            try {

                const config = {
                    headers: {}
                };

                const userToken = await getAccessToken();
                if (userToken){
                    config.headers.authorization = `Bearer ${userToken}`;
                }

                const { data: { token: newToken } } = await axios.get(`${process.env.REACT_APP_SKIFT_API_DOMAIN}/api/public/braintree/token`, config);
                
                dispatch(setBraintreeToken(newToken));
            } catch {
                setError(true);
            }

            setIsLoading(false);

        })();
    }, [setIsLoading, dispatch, setError]);
    
    useEffect(() => {
        if (token) return;
        fetchToken();
    }, [token, fetchToken]);
    
    return { token, isLoading, error, refresh: fetchToken };
};
