export const paramsFromQuerystring = url => {
    url = url || window.location.href;

    const [, querystring] = url.split('?');

    if (!querystring) {
        return {};
    }

    const params = querystring.split('&');

    return params.reduce((a, p) => {
        p = p.split('=');
        a[p[0]] = decodeURIComponent(p[1]);
        return a;
    }, {});
};
