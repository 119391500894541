import styled from 'styled-components';

export const Table = styled.table`
    width: 100%;

    thead td {
        border-bottom: 2px solid #000;
        font-size: 13px;
        font-weight: 600;
    }

    tbody td {
        font-size: 15px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    tbody tr:nth-child(even) td {
        background-color: #eee;
    }

    td {
        padding-left: 5px;
        padding-right: 5px;
    }
`;
