import React from 'react';
import { useContentItem } from 'hooks';
import ReactMarkdown from 'react-markdown';
import { TransactionMessage } from 'ui';
import TrackingPixels from './TrackingPixels';
import EventShare from './EventShare';
import { isValidUrl } from 'utils/isValidUrl';
import AddToWalletBtns from './AddToWalletBtns';
import { useSelector } from 'react-redux';

const TransactionComplete = () => {
    const { data: item, isFetching } = useContentItem();
    const { confirmation_page_text, social_url, enable_tickets } = item?.details || {};
    const { token } = useSelector(state => state.transaction.transactionResponse);

    let body = <p><strong>Next Steps:</strong> You will receive an email with details about accessing the event and technical requirements. If you have further questions, please email <a href="mailto:support@skift.com">support@skift.com</a>.</p>;

    if (confirmation_page_text && confirmation_page_text.length > 0) {
        body = <ReactMarkdown>{confirmation_page_text}</ReactMarkdown>;
    }

    if (enable_tickets) {
        body = (
            <>
                {body}
                <AddToWalletBtns token={token} />
            </>
        )
    }

    return (
        <>
            <TransactionMessage
                heading="Thank you"
                isFetching={isFetching}
                loaderText="Loading event"
            >{body}{social_url && isValidUrl(social_url) && <EventShare socialUrl={social_url} />} </TransactionMessage>
            <TrackingPixels placement="confirmation" />
        </>
    );
};

export default TransactionComplete;
