import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { addAuthHeader } from 'store/add-auth-header';

const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_SKIFT_API_DOMAIN}/api/`,
        prepareHeaders: addAuthHeader
    }),
    endpoints: builder => ({
        // user/owned-companies related
        getLoggedInUser: builder.query({
            query: () => 'user/my-profile',
            transformResponse: response => response?.profile,
            providesTags: () => ['Profile']
        }),
        changeEmail: builder.mutation({
            query: email => ({
                url: 'user/change-email',
                method: 'POST',
                body: { email }
            }),
            invalidatesTags: (r, error) => !error && ['Profile']
        }),
        changePassword: builder.mutation({
            query: password => ({
                url: 'user/change-password',
                method: 'POST',
                body: { password }
            })
        }),
        validateEmail: builder.query({
            query: email =>
                `public/account-exists?email=${encodeURIComponent(email)}`
        }),
        createAccount: builder.mutation({
            query: payload => ({
                url: 'public/create-account',
                method: 'POST',
                body: payload
            })
        }),
        resendEmailVerification: builder.mutation({
            query: () => ({
                url: 'user/resend-email-confirmation',
                method: 'POST'
            }),
            invalidatesTags: (r, error) => !error && ['Profile']
        }),
        updateProfile: builder.mutation({
            query: newProfile => ({
                url: 'user/my-profile',
                method: 'POST',
                body: newProfile
            }),
            invalidatesTags: (r, error) => !error && ['Profile']
        }),
        getBillingInfo: builder.query({
            query: () => 'user/billing-info',
            providesTags: () => ['Billing_Info']
        }),
        updateRecurlyBilling: builder.mutation({
            query: token => ({
                url: 'user/billing-info',
                body: { token },
                method: 'POST'
            }),
            invalidatesTags: (r, error) => !error && ['Billing_Info']
        }),
        getTransactions: builder.query({
            query: ({ companyId }) => {
                if (companyId) {
                    return `user/owned-companies/${companyId}/transactions`;
                }
                return 'user/transactions';
            },
            providesTags: () => ['Transactions']
        }),
        getCompany: builder.query({
            query: companyId => `user/owned-companies/${companyId}`
        }),
        getCompanyUsers: builder.query({
            query: ({ page = 1, companyId }) =>
                `user/owned-companies/${companyId}/users?page=${page}`,
            providesTags: () => ['Company_Users']
        }),
        removeUserFromCompany: builder.mutation({
            query: ({ companyId, userId }) => ({
                url: `user/owned-companies/${companyId}/users`,
                method: 'DELETE',
                body: { userId }
            }),
            invalidatesTags: (r, error) => !error && ['Company_Users']
        }),
        getCompanyPurchases: builder.query({
            query: companyId => `user/owned-companies/${companyId}/purchases`
        }),

        // event related
        getPurchases: builder.query({
            query: () => 'user/purchases',
            providesTags: () => ['Purchases']
        }),
        getRegistrationsWithActiveCheckIn: builder.query({
            query: () => 'user/events/regs-with-active-check-ins'
        }),
        getRegistrationForTicket: builder.query({
            query: ({ eventCode, token }) => {
                const params = [];
                if (eventCode) {
                    params.push(`event_code=${eventCode}`);
                }
                if (token) {
                    params.push(`rt=${token}`);
                }
                return `public/event/registration-for-ticket?${params.join('&')}`;
            }
        }),

        // subscription related
        cancelSubscription: builder.mutation({
            query: subscriptionId => ({
                url: `user/cancel-subscription/${subscriptionId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (r, error) => !error && ['Purchases']
        }),
        upgradeSubscription: builder.mutation({
            query: subscriptionId => ({
                url: `user/upgrade-subscription/${subscriptionId}`,
                method: 'PUT'
            }),
            invalidatesTags: (r, error) => !error && ['Purchases']
        }),
        enableSubscriptionAutoRenew: builder.mutation({
            query: subscriptionId => ({
                url: `user/enable-subscription-auto-renew/${subscriptionId}`,
                method: 'PUT'
            }),
            invalidatesTags: (r, error) => !error && ['Purchases']
        }),

        // newsletter related
        getNewsletterPreferences: builder.query({
            query: () => 'user/newsletter',
            transformResponse: response => response?.newsletters,
            providesTags: () => ['Newsletter_Preferences']
        }),
        updateNewsletterPreferences: builder.mutation({
            query: payload => ({
                url: 'user/newsletter',
                method: 'POST',
                body: payload
            }),
            invalidatesTags: (r, error) => !error && ['Newsletter_Preferences']
        }),

        // enterprise search related
        searchCompaniesWithEmail: builder.query({
            query: email => `public/enterprise-search?email=${email}`,
            providesTags: () => ['Enterprise_Search_Companies']
        }),

        // login related
        preLogin: builder.query({
            query: () => 'public/pre-login'
        }),
        ipGateway: builder.query({
            query: () => ({ url: 'public/ip-gateway', credentials: 'include' })
        })
    })
});

export const {
    useGetLoggedInUserQuery,
    useGetBillingInfoQuery,
    useGetTransactionsQuery,
    useUpdateProfileMutation,
    useUpdateRecurlyBillingMutation,
    useRemoveUserFromCompanyMutation,
    useGetCompanyPurchasesQuery,
    useGetPurchasesQuery,
    useUpgradeSubscriptionMutation,
    useCancelSubscriptionMutation,
    useSearchCompaniesWithEmailQuery,
    useGetRegistrationsWithActiveCheckInQuery,
    useGetRegistrationForTicketQuery,
    useGetCompanyQuery,
    useValidateEmailQuery,
    useGetCompanyUsersQuery,
    useResendEmailVerificationMutation,
    useChangeEmailMutation,
    useChangePasswordMutation,
    useCreateAccountMutation,
    useEnableSubscriptionAutoRenewMutation,
    useGetNewsletterPreferencesQuery,
    useUpdateNewsletterPreferencesMutation,
    usePreLoginQuery,
    useIpGatewayQuery
} = userApi;
export default userApi;
