import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setPromoCode } from 'store/features/transaction';
import { Button } from './Button';
import { TextField } from './Field';
import { Flex } from './Flex';
import { LinkButton } from './LinkButton';
import { Message } from './Message';
import useTriggerEvent from 'hooks/useTriggerEvent';
import { MYSK_EVENT_TYPES } from 'utils/constants';

const Wrap = styled.div`
    margin: 30px 0 0 0;
`;

const PlusIcon = styled.div`
    position: relative;
    width: 12px;
    height: 12px;
    cursor: pointer;

    transition: transform 0.2s;
    
    &::before {
        content: '';
        height: 100%;
        border-left: 2px solid #000;
        position: absolute;
        top: 0;
        left: calc(50% - 1px);
    }
    &::after {
        content: '';
        width: 100%;
        border-top: 2px solid #000;
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
    }

    ${props => {
        if (props.close) {
            return `
                transform: rotate(45deg);
            `;
        }
    }}
`;

const PromoForm = ({ addCode, loading, showError }) => {
    const [code, setCode] = useState('');
    const [submitted, setSubmitted] = useState();

    const handleSubmit = e => {
        e.preventDefault();
        if (code) {
            addCode(code);
            setSubmitted(true);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {submitted && !loading && showError && <Message error small style={{ marginBottom: 0, marginTop: '15px' }}>Invalid promo code</Message>}
            <Flex alignItems="center">
                <TextField disabled={loading} onBlur={handleSubmit} label="Enter a code" value={code} onChange={e => setCode(e.target.value)} />
                <Button disabled={loading} loading={submitted && loading} size="small" style={{ height: '50.5px', marginLeft: '5px' }}>Apply</Button>
            </Flex>
        </form>
    );
};

export const PromoCode = ({ loading, total }) => {
    const dispatch = useDispatch();
    const [addingMode, setAddingMode] = useState(false);
    const itemId = useSelector(state => state.transaction.itemId);
    const appliedCode = useSelector(state => state.transaction.promo);
    const appliedValidCode = useMemo(() => appliedCode  && total?.promo_is_valid, [appliedCode, total]);
    const triggerEvent = useTriggerEvent();

    useEffect(() => {
        if (appliedValidCode) {
            triggerEvent(MYSK_EVENT_TYPES.PROMO_CODE_APPLIED, { itemId, code: appliedCode, ...total });
        }
    }, [appliedValidCode, appliedCode, itemId, total, triggerEvent]);

    const addCode = c => {
        dispatch(setPromoCode(c));
    };

    if (appliedValidCode) {
        return (
            <Flex justifyContent="space-between" alignItems="center" style={{ margin: '30px 0', fontSize: '14px'}}>
                <div>Applied promo code <strong>{appliedCode}</strong></div>
                <PlusIcon close={true} onClick={() => addCode(null)} />
            </Flex>
        );
    }

    return (
        <Wrap>
            <Flex alignItems="center">
                <PlusIcon close={addingMode} style={{ marginRight: '10px' }} />
                <LinkButton onClick={() => setAddingMode(!addingMode)}>Add a promo code</LinkButton>
            </Flex>
            {addingMode && <PromoForm showError={appliedCode && !total.promo_is_valid} loading={loading} addCode={addCode} />}
        </Wrap>
    );
};
