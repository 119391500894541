import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetCompanyQuery, useGetCompanyUsersQuery } from 'store/features/user-api';
import { Loader, Table } from 'ui';
import Pagination from 'ui/Pagination';
import DashboardFrame from '../DashboardFrame';
import RemoveUserBtn from './RemoveUserBtn';

const MultiUserUsers = () => {
    const { id, page = 1 } = useParams();
    const { data: userData, isFetching: isFetchingUsers } = useGetCompanyUsersQuery({ companyId: id, page });
    const { data: companyData, isFetching: isFetchingCompany } = useGetCompanyQuery(id);

    const items = userData?.docs || [];
    if (isFetchingCompany) {
        return <DashboardFrame><Loader small text="Loading company" /></DashboardFrame>;
    }

    return (
        <DashboardFrame breadcrumbs={[['Home', '/'], ['Multi-User', '/multi-user'], ['Users', null]]}>
            <h2>{companyData?.company?.properties?.name} Users</h2>
            <h4>{userData?.totalDocs}{companyData?.company?.properties?.seats && ` / ${companyData?.company?.properties?.seats}`} Seats Used</h4>
            {isFetchingUsers ? <Loader small text="Loading users" /> : <Table cellSpacing={0}>
                <thead>
                    <tr>
                        <td>Name</td>
                        <td>Email</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {items.length === 0 ? <tr><td colspan={3}><p><em>Nobody has joined this company yet</em></p></td></tr> : items.map(user => (
                        <tr key={`user-${user.id}`}>
                            <td>{user.first_name} {user.last_name}</td>
                            <td>{user.email}</td>
                            <td align="right"><RemoveUserBtn user={user} /></td>
                        </tr>
                    ))}
                </tbody>
            </Table>}
            <Pagination {...userData} urlFormatter={p => `/multi-user/${id}/users${p > 1 ? `/page/${p}` : ''}`} />
        </DashboardFrame>
    )
};

export default MultiUserUsers;
