import React from 'react';
import { useGetCompanyUsersQuery } from 'store/features/user-api';
import { Loader } from 'ui';

const UsedSeats = ({ companyId, seats }) => {
    const { data, isFetching, error } = useGetCompanyUsersQuery({ companyId });

    if (isFetching) {
        return <Loader small />;
    }

    if (error) {
        return "Error";
    }

    return (
        <>
            {data.totalDocs}{seats && ` / ${seats}`}
        </>
    );
};

export default UsedSeats;
