import { auth0HookBridge } from 'components/auth0/Auth0Tokens';

export const getAccessToken = async () => {
    const { isAuthenticated, getAccessTokenSilently } = auth0HookBridge;

    if (isAuthenticated) {
        return await getAccessTokenSilently()();
    }

    return;
};

export const addAuthHeader = async headers => {
    const token = await getAccessToken();

    if (token) {
        headers.set('authorization', `Bearer ${token}`)
    }

    return headers;
};