import React from 'react';
import { Helmet } from 'react-helmet';

const PageTitle = ({ title, children }) => (
    <>
        <Helmet>
            {title && <title>{title}</title>}
        </Helmet>
        {children}
    </>
);

export default PageTitle;