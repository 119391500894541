import { Button } from './Button';

const { default: styled } = require('styled-components');

const Wrap = styled.div`
    margin: 10px;

    padding: 40px 30px;

    width: 300px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;

    background-color: white;

    border: 1px solid black;

    h3 {
        font-size: 33px;
        margin: 0;
    }

    div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 17px 0px;
    }

    p {
        margin: 0px;
        font-size: 16px;
    }

    @media screen and (max-width: 860px) and (min-width: 751px) {
        margin: 8px;
        padding: 30px 15px;
    }
`;

const CompanyCard = ({ company }) => {
    if (!company) {
        return null;
    }

    const insertSubscriptions = subscriptions => {
        if (!subscriptions || subscriptions.length === 0)
            return <p>No subscription found</p>;

        return subscriptions.map((subscription, idx) => (
            <p key={idx}>{subscription.name}</p>
        ));
    };

    return (
        <Wrap>
            <h3>{company.name}</h3>
            <div>{insertSubscriptions(company.subscriptions)}</div>
            <Button
                as='a'
                color='black'
                href={`${process.env.REACT_APP_DOMAIN}/enterprise-welcome/${company.invite_code}`}
            >
                Join Now
            </Button>
        </Wrap>
    );
};

export default CompanyCard;
