import React, { useMemo } from 'react';
import { useGetPurchasesQuery } from 'store/features/user-api';
import SummaryPane from '../SummaryPane';

const GroupTicketsSummary = () => {
    const { data: transactions } = useGetPurchasesQuery();
    const registrations = transactions?.event_group_registrations;

    const text = useMemo(() => {
        let text = '';

        const upcoming = (registrations || []).filter(s => {
            const event = s.event || s.webinar;

            if (!event) {
                return false;
            }

            return new Date(event.event_date).toTimeString() >= new Date().toTimeString();
        });

        const past = (registrations || []).filter(s => {
            const event = s.event || s.webinar;

            if (!event) {
                return true;
            }

            return new Date(event.event_date).toTimeString() < new Date().toTimeString();
        });
        
        if (upcoming.length) {
            text += `You purchased group tickets for ${upcoming.length} upcoming event${upcoming.length > 1 ? 's' : ''}`;
        }

        if (past.length) {
            if (upcoming.length) {
                text += ' and you ';
            } else {
                text += 'You ';
            }

            text += `have purchased group tickets for ${past.length} past event${past.length > 1 ? 's' : ''}`;
        }

        text += '.';

        return text;
    }, [registrations]);


    if (!registrations.length) {
        return;
    }

    return (
        <SummaryPane heading="Group Tickets" to="/group-tickets">
            <p>{text}</p>
        </SummaryPane>
    );
};

export default GroupTicketsSummary;
