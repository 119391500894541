import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, LinedHeading } from 'ui';

const Wrap = styled.div`
    margin: 40px 0;
    
    p {
        font-size: 15px;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const HeadingWrap = ({ to, children }) => to ? <Link to={to}>{children}</Link> : children;

const SummaryPane = ({ heading, to, onClick, buttonText = 'View Details', headingMargin, disabled, children, ...props }) => {
    const buttonAs = to ? 'Link' : 'button';

    return (
        <Wrap {...props}>
            <LinedHeading 
                left 
                largeHeading
                RightSide={disabled ? null : <Button as={buttonAs} to={to} onClick={onClick} outline size="xsmall">{buttonText}</Button>}
                margin={headingMargin}
            ><HeadingWrap>{heading}</HeadingWrap></LinedHeading>
            {children}
        </Wrap>
    );
};

export default SummaryPane;
