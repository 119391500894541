import React from 'react';
import { FormGroup, FormLabel, Input } from 'ui';

const EmailField = props => {
    return (
        <FormGroup>
            <Input type="email" name="email" {...props} />
            <FormLabel>Email Address</FormLabel>
        </FormGroup>
    );
};

export default EmailField;
