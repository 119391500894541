import React from 'react';
import { FormGroup } from './FormGroup';
import { Input } from './Input';
import { FormLabel } from './FormLabel';
import { Select } from './Select';

export const TextField = ({
    label,
    ...props
}) => (
    <FormGroup>
        <Input type="text" {...props} />
        <FormLabel>{label}</FormLabel>
    </FormGroup>
);

export const SelectField = ({
    label,
    ...props
}) => (
    <FormGroup>
        <Select {...props} />
        <FormLabel>{label}</FormLabel>
    </FormGroup>
);
