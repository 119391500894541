import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setGroupCode } from 'store/features/transaction';
import { FullScreenLoader } from 'ui';
import EventRegistration from '.';

const RegisterForGroup = () => {
    const dispatch = useDispatch();
    const { group } = useParams();
    const groupSet = useSelector(state => state.transaction.groupCode);

    useEffect(() => {
        dispatch(setGroupCode(group));
    }, [dispatch, group]);

    if (!groupSet) {
        return <FullScreenLoader text="Loading Event" />;
    }
    
    return <EventRegistration />;
};

export default RegisterForGroup;
