import React from 'react';
import styled from 'styled-components';
import loader from 'assets/loader.svg';


const LoaderImg = styled.img`
    width: 80px;
`;

const LoaderText = styled.p`
    font-size: 22px;
    margin: -10px 0 0 0;
    font-weight: 100;
`;

const Wrap = styled.div.attrs(props => {
    if (props.small) {
        delete props.small;
        props.$small = true;
    }

    return props;
})`
    text-align: center;

    ${props => {
        if (!props.stacked) {
            return `
                display: flex;
                justify-content: center;
                align-items: center;

                margin: 20px 0;

                ${LoaderText} {
                    margin: 0;
                }
            `;
        }
    }}

    ${props => {
        if (props.$small) {
            return `
                ${LoaderImg} {
                    width: 45px;
                }

                ${LoaderText} {
                    font-size: 15px;
                }
            `;
        }
    }}
`;

export const Loader = ({ children, ...props }) => (
    <Wrap {...props}>
        <LoaderImg src={loader} />
        <LoaderText>{children ? children : 'Loading'}</LoaderText>
    </Wrap>
);
