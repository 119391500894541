import React from 'react';
import styled from 'styled-components';
import appleBtn from '../../assets/apple-wallet.png';
import googleBtn from '../../assets/google-wallet.png';

const WalletWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 30px 0;

    img {
        margin: 0 10px;
        width: auto;
        height: 55px;
    }

    @media screen and (max-width: 500px) {
        flex-direction: column;

        img {
            margin: 5px 0;
        }
    }
`;


const AddToWalletBtns = ({ token }) => {
    if (!token) return null;

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    return (
        <WalletWrap>
            {isIOS && <a href={`${process.env.REACT_APP_SKIFT_API_DOMAIN}/wallet-passes/apple?rt=${token}`} target='_blank' rel='noopener noreferrer'>
                <img src={appleBtn} alt="Add to Apple Wallet" />
            </a>}
            <a href={`${process.env.REACT_APP_SKIFT_API_DOMAIN}/wallet-passes/google?rt=${token}`} target='_blank' rel='noopener noreferrer'>
                <img src={googleBtn} alt="Add to Google Wallet" />
            </a>
        </WalletWrap>
    );
};

export default AddToWalletBtns;
