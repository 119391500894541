import React, { useContext, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { TransactionContext } from './TransactionContext';

const Recaptcha = () => {
    const recaptchaRef = React.useRef();
    const { setRecaptcha, recaptcha } = useContext(TransactionContext);

    useEffect(() => {
        if (!recaptchaRef || recaptcha) {
            return;
        }

        setRecaptcha(recaptchaRef.current);
    }, [recaptchaRef, recaptcha, setRecaptcha]);
 
    return (
        <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        />
    );
 
};

export default Recaptcha;
