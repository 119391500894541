export const defaultTheme = {
    backgroundColor: '#f7f7f7',
    panelBackgroundColor: '#fff',
    borderColor: '#e9e9e9',
    grayText: '#999',
    headerFont: 'aria-text-g2,serif, serif',
    serifFont: 'aria-text-g2,serif, serif',
    bodyFont: 'acumin-pro, sans-serif',
    sansSerifFont: 'acumin-pro, sans-serif',
    gutterSize: '30px',
    errorColor: '#C23329',
    successColor: '#4DE6BE',
    yellowColor: '#FFF22F'
};
