import React from 'react';
import { useAuth0 } from 'components/auth0/Auth0Context';
import { FullScreenLoader, FullScreenMessage } from 'ui';

const Auth0Loader = ({ children }) => {
    const { isLoading, isRedirecting, isLoggingOut, error } = useAuth0();


    if (error) {
        return (
            <FullScreenMessage heading="An Error Occured" error>Please try again. If the issue persists, please contact <a href="mailto:support@skift.com">support@skift.com</a>.</FullScreenMessage>
        );
    }

    if (isRedirecting) {
        return <FullScreenLoader text="Redirecting" />;
    }

    if (isLoggingOut) {
        return <FullScreenLoader text="Signing Out" />;
    }

    if (isLoading) {
        return <FullScreenLoader text="Authenticating" />;
    }

    return children;
};

export default Auth0Loader;
