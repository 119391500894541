import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useBraintreeToken } from "hooks/useBraintreeToken";
import { useRecurly } from '@recurly/react-recurly';
import { Message } from "ui";
import UsingAltMethod from "./UsingAltMethod";
import applepayLogo from 'assets/payments/applepay.svg';
import { DEFAULT_ERROR_MESSAGE } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { useContentItem, useTransactionTotal } from "hooks";
import { TransactionContext } from "components/TransactionContext";
import { setPaymentToken } from "store/features/transaction";

const ApplePayButton = styled.button.attrs(props => {
    props.type = props.type === 'Subscribe' ? 'subscribe' : 'buy';
    return props;
})`
    display: block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: ${props => props.type};
    -apple-pay-button-style: black;
    margin-top: 30px;
    cursor: pointer;
    width: 100%;
    height: 45px;
    border-radius: 0;
`;

const RecurlyApplePay = ({ clear }) => {
    const dispatch = useDispatch();
    const { token: btToken } = useBraintreeToken();
    const [error, setError] = useState(false);
    const [applepay, setApplepay] = useState(false);
    const [ready, setReady] = useState(false);
    const recurly = useRecurly();
    const { onSubmit, submitBtnText, processing } = useContext(TransactionContext);

    const currency = useSelector(state => state.transaction.currency);
    const { data: { total } = {}, isFetching: isFetchingTotal } = useTransactionTotal();
    const { data: item } = useContentItem();

    useEffect(() => {
        if (!btToken || !recurly || error || applepay || isFetchingTotal) {
            return;
        }

        const ap = recurly.ApplePay({
            country: 'US',
            currency,
            label: item?.details?.name || 'Skift Subscription',
            total,
            braintree: {
                clientAuthorization: btToken
            }
        });

        ap.on('token', token => {
            if (token && token.id) {
                dispatch(setPaymentToken(token.id));
                
                onSubmit();
            } else {
                setError(true);
            }
        });

        ap.on('ready', () => setReady(true));
        ap.on('cancel', clear);
        ap.on('error', err => {
            console.error('ApplePay error', err);
            setError(err);
        });

        setApplepay(ap);
    }, [recurly, applepay, btToken, setReady, setApplepay, error, setError, dispatch, onSubmit, clear, currency, item, total, isFetchingTotal]);

    const handleSubmit = e => {
        e.preventDefault();
        applepay.begin();
    };

    return (
        <>
            {error && <Message error>{DEFAULT_ERROR_MESSAGE}</Message>}
            <UsingAltMethod logo={applepayLogo} alt="PayPal" clear={clear} />
            <ApplePayButton type={submitBtnText} fluid size="large" disabled={!ready || !applepay} loading={processing} black onClick={handleSubmit} />
        </>
    );
};

export default RecurlyApplePay;
