import React from 'react';

import { FullScreenWrapper } from 'ui';
import { Loader } from './Loader';

export const FullScreenLoader = ({ text = 'Loading' }) => (
    <FullScreenWrapper>
        <Loader stacked>{text}</Loader>
    </FullScreenWrapper>
);
