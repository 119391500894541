import { useState, useCallback } from "react";

export const useRecurlyCardValidation = () => {
    const [errors, setErrors] = useState([]);
    const [validationRan, setValidationRan] = useState();

    const validate = useCallback(({ billingAddress: values }) => {
        const requiredFields = [
            { key: 'billing_first_name', value: 'billing_first_name', text: 'First Name' },
            { key: 'billing_last_name', value: 'billing_last_name', text: 'Last Name' },
            { key: 'billing_region', value: 'billing_region', text: 'Region' },
            { key: 'billing_zip', value: 'billing_zip', text: 'Postal Code' },
            { key: 'billing_country', value: 'billing_country', text: 'Country' },
        ];
        
        const nextErrors = requiredFields.reduce((a, field) => {
            const { text, key } = field;
            const value = values[key] && values[key].trim();
    
            if (!value || value === '') {
                a.push({
                    field: key,
                    text: `${text} is required`
                });
            } else {
                if (value.length < 2) {
                    a.push({
                        field: key,
                        text: `${text} must be at least 2 characters long.`
                    });
                } else if (value.length > 50) {
                    a.push({
                        field: key,
                        text: `${text} must be less than 50 characters long.`
                    });
                }
            }

            return a;
        }, []);

        if (!values.card_valid) {
            nextErrors.push({
                field: 'card_number',
                text: 'Please enter a valid credit card'
            });
        }

        setErrors(nextErrors);
        setValidationRan(true);
        
        return nextErrors.length === 0;
    }, []);

    return [errors, validate, validationRan];
};
