import styled from 'styled-components';

export const CoreMessage = styled.div`
    color: #fff;
    font-size: 15px;
    padding: 20px 30px;
    width: 100%;
    margin: 20px 0;

    &:first-child {
        margin-top: 0;
    }

    * {
        margin: 0;
    }

    * + * {
        margin-top: 10px;
    }

    h2 {
        font-size: 20px;
        margin: 0 0 10px 0;
    }

    a {
        color: #fff;
        text-decoration: underline;
    }
    

    ${props => {
        if (props.jumbotron) {
            return `
                text-align: center;
                padding: 30px 20px;
                max-width: 350px;
            `;
        }
    }};

    ${props => {
        if (props.small) {
            return `
                padding: 12px 20px;
                font-size: 14px;
            `;
        }
    }};

    ${props => {
        if (props.error) {
            return `background-color: ${props.theme.errorColor}`;
        } else if (props.success) {
            return `background-color: ${props.theme.successColor}`;
        } else if (props.black) {
            return `background-color: #000`;
        } else if (props.light) {
            return `color: #000; background-color: #eee;`;
        } else {
            return `color: #000; background-color: ${props.theme.yellowColor}; a { color: #000; }`
        }
    }}
`;
