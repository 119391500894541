import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { addAuthHeader } from 'store/add-auth-header';
import { CALCULATE_TOTAL_ENDPOINTS, GET_ITEM_ENDPOINTS, TRANSACTION_TYPES } from 'utils/constants';
import { querystringFromArray } from 'utils/querystringFromArray';

const contentApi = createApi({
    reducerPath: 'contentApi',
    baseQuery: fetchBaseQuery({ 
        baseUrl: `${process.env.REACT_APP_SKIFT_API_DOMAIN}/api/`,
        prepareHeaders: addAuthHeader
    }),
    endpoints: builder => ({
        getItemDetails: builder.query({
            query: ({ itemId, transactionType = TRANSACTION_TYPES.SUBSCRIPTION, ...params }) => {
                // remove any non-alphanumeric characters or "-", "_" from the itemId 
                itemId = itemId.replace(/[^a-zA-Z0-9-_]/g, '');
                const qs = querystringFromArray(params);

                return `${GET_ITEM_ENDPOINTS[transactionType]}${itemId}&${qs}`;
            }
        }),
        calculateTotal: builder.query({
            query: ({ transactionType = TRANSACTION_TYPES.SUBSCRIPTION, ...params }) => {
                const qs = querystringFromArray(params);

                return `${CALCULATE_TOTAL_ENDPOINTS[transactionType]}?${qs}`;
            }
        })
    })
});

export const { 
    useGetItemDetailsQuery, 
    useCalculateTotalQuery, 
} = contentApi;
export default contentApi;
