import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    * {
        box-sizing: border-box;
    }
    
    body {
        margin: 0;
        padding: 0;
        background-color: ${props => props.theme.backgroundColor};
        font-size: 17px;
        font-family: ${props => props.theme.bodyFont};
    }

    a {
        color: #000;
    }

    h1,h2,h3,h4,h5,h6 {
        font-family: ${props => props.theme.headerFont};
        font-weight: normal;
        margin-top: 0;
    }

    .recurly-element-card {
        border-radius: 0;
        height: 3em;
        color: #b1b1b1;
        border: 1px solid #b1b1b1;
        transition: all 0.2s;
        font-family: ${props => props.theme.bodyFont};

        &.recurly-element-focus {
            border-color: #000;
            color: #000;
        }
    }

    .Toastify__toast {
        font-family: ${props => props.theme.bodyFont};
        font-size: 15px;
    }
`;
