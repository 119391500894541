import React, { useEffect } from 'react';
import { useAuth0 } from './auth0/Auth0Context';
import { FullScreenLoader } from 'ui';
import { useNavigate } from 'react-router-dom';

const RequireAuth = ({ children }) => {
    const { isLoading, isAuthenticated } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, isLoading, navigate]);

    return isLoading || !isAuthenticated ? <FullScreenLoader text="Authenticating" /> : children;
};

export default RequireAuth;
