import moment from 'moment';
import React, { useMemo } from 'react';
import { useGetPurchasesQuery } from 'store/features/user-api';
import { Loader, BoxedItem, CollaspableSection, Stats, Stat } from 'ui';
import { formatCurrency } from 'utils/formatNumber';
import { splitCollectionIntoCategories } from 'utils/splitCollectionIntoCategories';
import DashboardFrame from './DashboardFrame';

const EventRegistrationsScreen = () => {
    const { data: transactions, isFetching } = useGetPurchasesQuery();

    const categories = useMemo(() => {
        return splitCollectionIntoCategories(transactions?.event_registrations || [], reg => {
            const event = reg.event || reg.webinar;

            if (!event) {
                return false;
            }

            return moment(event.event_date).isBefore(moment()) ? 'past' : 'upcoming';
        }, [
            {
                heading: 'Upcoming Events',
                key: 'upcoming'
            },
            {
                heading: 'Past Events',
                key: 'past'
            }
        ]);
    }, [transactions]);

    if (isFetching) {
        return <DashboardFrame><Loader small>Loading registrations</Loader></DashboardFrame>;
    }

    return (
        <DashboardFrame breadcrumbs={[['Home', '/'], ['Registrations', null]]}>
            <h2>Event Registrations</h2>
            {!(categories || []).length && <p>You haven't registered for an event yet</p>} 

            {categories.map((cat, catI) => {
                let Wrap = React.Fragment;
                let wrapProps = {};
                
                if (categories.length > 1) {
                    Wrap = CollaspableSection;
                    wrapProps = { heading: cat.heading, initialState: catI === 0 };
                }
                
                return (
                    <Wrap key={`cat-${catI}`} {...wrapProps}>
                        {cat.items.map((reg, i) => {
                            const event = reg?.event || reg?.webinar || {};
                            return (
                                <BoxedItem
                                    key={`${cat.key}-${i}`}
                                    heading={event.name || 'Missing event'}
                                    category={moment(event.event_date).format('MMMM Do, YYYY')}
                                >
                                    <Stats>
                                        <Stat label="Registration Date">
                                            {moment(reg.reg_date).format('MM/DD/Y h:mm a')}
                                        </Stat>
                                        <Stat label="Payment amount">
                                            {formatCurrency(reg.payment_amount, reg.currency)}
                                        </Stat>
                                    </Stats>
                                </BoxedItem>
                            );
                        })}
                    </Wrap>
                );
            })}
        </DashboardFrame>
    )
};

export default EventRegistrationsScreen;
