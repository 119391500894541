import React from 'react';
import styled from 'styled-components';
import { LinkButton } from 'ui';

const Wrap = styled.div`
    border: 1px solid #1b1b1b;
    padding: 10px 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        height: 35px;
    }
`;

const UsingAltMethod = ({ logo, alt, clear }) => (
    <Wrap>
        <img src={logo} alt={alt} />
        <LinkButton onClick={clear}>Change payment method</LinkButton>
    </Wrap>
);

export default UsingAltMethod;
