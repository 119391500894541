import React from 'react';
import DashboardFrame from '../DashboardFrame';
import SubscriptionSummary from './SubscriptionSummary';
import ContentSummary from './ContentSummary';
import EventRegistrationSummary from './EventRegistrationSummary';
import GroupTicketsSummary from './GroupTicketsSummary';
import EduCreditSummary from './EduCreditSummary';
import NothingPurchasedYet from './NothingPurchasedYet';
import MultiUserSummary from './MultiUserSummary';

const Dashboard = () => (
    <DashboardFrame>
        <SubscriptionSummary />
        <ContentSummary />
        <EventRegistrationSummary />
        <GroupTicketsSummary />
        <EduCreditSummary />
        <MultiUserSummary />
        <NothingPurchasedYet />
    </DashboardFrame>
);

export default Dashboard;
