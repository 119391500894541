import React from 'react';
import { useGetBillingInfoQuery } from 'store/features/user-api';
import { Loader } from 'ui';

const BillingProperties = () => {
    const { data, isFetching, error } = useGetBillingInfoQuery();

    
    if (isFetching) {
        return <Loader small>Loading billing information</Loader>;
    }
    
    if (error) {
        return <p>No billing information stored</p>;
    }

    const billingInfo = data?.billingInfo;

    if (billingInfo.paymentMethod.cardType) {
        return <p>{billingInfo.firstName} {billingInfo.lastName}'s {billingInfo.paymentMethod.cardType} ending in {billingInfo.paymentMethod.lastFour}</p>;
    }

    return <p>{billingInfo.firstName} {billingInfo.lastName}'s previously used method</p>;


};

export default BillingProperties;
