import React, { useMemo } from 'react';
import { useGetPurchasesQuery } from 'store/features/user-api';
import SummaryPane from '../SummaryPane';

const MultiUserSummary = () => {
    const { data: transactions } = useGetPurchasesQuery();
    const companies = transactions?.multi_user_companies;

    const text = useMemo(() => {
        // generate an object of types with the total amount of credits as the value

        return `You manage ${companies.length} multi-user compan${companies.length > 1 ? 'ies' : 'y'}.`;
    }, [companies]);


    if (!companies.length) {
        return;
    }

    return (
        <SummaryPane heading="Multi-User Companies" to="/multi-user">
            <p>{text}</p>
        </SummaryPane>
    );
};

export default MultiUserSummary;
