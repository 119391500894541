import React from 'react';
import { useAuth0 } from 'components/auth0/Auth0Context';
import { useGetBillingInfoQuery } from 'store/features/user-api';
import { Panel, Loader } from 'ui';
import PreviousPaymentMethod from './PreviousPaymentMethod';
import RecurlyBillingRoot from './RecurlyBillingRoot';
import { useTransactionTotal } from 'hooks';

const RecurlyBillingModule = () => {
    const { isAuthenticated } = useAuth0();
    const { isLoading: isLoadingBillingInfo, data: billingInfoData, error: billingInfoError } = useGetBillingInfoQuery(null, { skip: !isAuthenticated });

    if (isAuthenticated && isLoadingBillingInfo) {
        return <Loader small>Loading saved payment method</Loader>;
    }

    if (!isAuthenticated || billingInfoError || !billingInfoData?.billingInfo?.id) {
        return (
            <>
                <h2>Enter your payment details</h2>
                <RecurlyBillingRoot />
            </>
        );
    }

    return (
        <>
            <h2>Choose your payment method</h2>
            <PreviousPaymentMethod />
        </>
    );
};

const RecurlyBilling = ({ inactive, ...props }) => {
    const { data } = useTransactionTotal();

    if (data && !data.require_payment) {
        return null;
    }

    return (
        <Panel id="billing-form" padded inactive={inactive}>
            <RecurlyBillingModule {...props} />
        </Panel>
    );
};

export default RecurlyBilling;
