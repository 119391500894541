import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Errors, Panel } from 'ui';
import { useContentItem } from 'hooks';
import VariableAmountPicker from 'ui/VariableAmountPicker';
import { setAmount } from 'store/features/transaction';
import { useVariableAmountValidation } from 'hooks/useVariableAmountValidation';
import { TransactionContext } from 'components/TransactionContext';

const PaymentAmount = ({ inactive }) => {
    const dispatch = useDispatch();
    const { data: item } = useContentItem();
    const value = useSelector(state => state.transaction.amount);
    const currency = useSelector(state => state.transaction.currency);
    const { variable_options: options, allow_no_payment, variable_min_amount } = item?.details || {};
    const { addValidator, removeValidator } = useContext(TransactionContext);
    const [errors, validate, validationRan] = useVariableAmountValidation({ allow_no_payment, variable_min_amount });

    const transactionAmountIsVariable = useMemo(() => item?.details?.payment_model === 'flex' && options, [item, options]);

    useEffect(() => {
        if (!options || !options.length) {
            return;
        }

        dispatch(setAmount(options[0]));
    }, [options, dispatch]);

    useEffect(() => {
        if (transactionAmountIsVariable) {
            addValidator('payment_amount', validate);
        }

        return function cleanup() {
            removeValidator('payment_amount');
        };
    }, [transactionAmountIsVariable, addValidator, validate, removeValidator]);

    if (!transactionAmountIsVariable) {
        return null;
    }

    const handleChange = val => {
        dispatch(setAmount(val));

        if (validationRan) {
            validate({ amount: val });
        }
    };
    
    return (
        <Panel padded inactive={inactive}>
            <h2>Payment Amount</h2>
            <p>We’re offering this event on a pay-what-you-want basis. Support Skift’s independent journalism in the world’s most consequential sector.</p>

            <Errors errors={errors} />
            <VariableAmountPicker
                onChange={handleChange}
                options={options}
                allowOther={item?.details?.variable_show_other}
                currency={currency}
                value={value}
            />
        </Panel>
    )
};

export default PaymentAmount;
