import React, { useContext, useEffect, useMemo } from 'react';
import { Button, Errors } from 'ui';
import { useProfileValidation } from 'hooks/useProfileValidation';
import Fields from './Fields';
import { useDispatch, useSelector } from 'react-redux';
import { setProfile } from 'store/features/transaction';
import { TransactionContext } from 'components/TransactionContext';

const Form = ({ fields, requiredFields, onProfileCompleted, showContinueBtn, onChange, passedValues }) => {
    const dispatch = useDispatch();
    const transactionValues = useSelector(state => state.transaction.profile);
    const [errors, validate, validationRan] = useProfileValidation(requiredFields || fields, fields);
    const { addValidator, removeValidator } = useContext(TransactionContext);
    const values = useMemo(() => {
        return passedValues || transactionValues;
    }, [passedValues, transactionValues]);

    useEffect(() => {
        addValidator('profile', validate);

        return function cleanup() {
            removeValidator('profile');
        };

    }, [addValidator, validate, removeValidator]);

    const handleFieldChange = (keyOrChanges, val) => {

        // the first param can be a string of a single property or an object of 
        // properties that can be updated all at once

        let updates = keyOrChanges;

        if (typeof keyOrChanges === 'string') {
            updates = { [keyOrChanges]: val };
        }

        onChange ? onChange(updates) : dispatch(setProfile(updates));

        const profile = {
            ...values,
            ...updates
        };

        if (!showContinueBtn && typeof onProfileCompleted === 'function') {
            if (fields.length === Object.keys(profile).length) {
                onProfileCompleted(profile);
            }
        }

        if (validationRan) {
            if (!validate({ profile })) {
                typeof onProfileCompleted === 'function' && onProfileCompleted(false);
            }
        }
    };

    const handleSubmit = e => {
        e && e.preventDefault();

        const valid = validate({ profile: values });

        if (valid) {
            typeof onProfileCompleted === 'function' && onProfileCompleted(values);
        }

        return valid;
    };

    const disabled = validationRan && errors.length;

    return (
        <form>
            <Errors errors={errors} />
            <Fields fields={fields} errors={errors} values={values} onFieldChange={handleFieldChange} />
            {
            showContinueBtn && <Button disabled={disabled} black onClick={handleSubmit}>Continue</Button>
            }
        </form>
    );
};

export default Form;
