import React from 'react';
import loader from 'assets/loader.svg';
import { createButton } from './createButton';
import { Link } from 'react-router-dom';

const CoreButton = createButton('button');
const AnchorButton = createButton('a');
const LinkButton = createButton(Link);

export const Button = ({ children, as = 'button', ...props }) => {
    let Tag = CoreButton;

    if (props.hasOwnProperty('loading')) {
        props.$loading = props.loading;
        delete props.loading;
    }

    if (props.hasOwnProperty('outline')) {
        props.$outline = props.outline;
        delete props.outline;
    }

    if (as === 'a') {
        Tag = AnchorButton;
    }

    if (as === 'Link') {
        Tag = LinkButton;
    }

    return <Tag {...props}>{props.$loading ? <img src={loader} alt="Loading" className="loader" /> : children}</Tag>;
};
