import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { DEFAULT_ACCOUNT_FIELDS, DEFAULT_TOS_LINK, PRIVACY_POLICY_LINK } from 'utils/constants';

import { useContentItem } from 'hooks';
import { 
    TransactionWrapper, 
    TransactionBody, 
    TransactionRail,
    TransactionSummary, 
    TransactionProcessing, 
    TransactionErrors,
    DisclaimerText,
    FullHeightContainer, 
    SubmitTransactionBtn
} from 'ui';

import { TransactionContextProvider } from 'components/TransactionContext';

import AccountForm from 'components/AccountForm';
import ProfileForm from 'components/ProfileForm';
import ScrollToActiveSection from 'components/ScrollToActiveSection';

import TransactionComplete from './TransactionComplete';
import SubscriberThankYou from './SubscriberThankYou';
import BraintreeBilling from 'components/BraintreeBilling';
import PaymentAmount from './PaymentAmount';
import OptIns from './OptIns';
import TrackingPixels from './TrackingPixels';
import SupportFooter from 'components/SupportFooter';
import Recaptcha from 'components/Recaptcha';
import AlreadyRegistered from './AlreadyRegistered';

const Main = () => {
    const { data: item } = useContentItem();
    const [accountCreated, setAccountCreated] = useState(false);
    const [profileCompleted, setProfileCompleted] = useState(false);
    const transactionComplete = useSelector(state => state.transaction.complete);
    
    const itemDetails = item?.details || {};

    if (transactionComplete) {
        return <TransactionComplete />;
    }

    if (itemDetails.already_registered) {
        return <AlreadyRegistered />;
    }

    const isBillingStep = accountCreated && profileCompleted;

    return (
        <TransactionContextProvider submitBtnText="Register">
            <TransactionWrapper>
                <TransactionRail>
                    <TransactionSummary />
                </TransactionRail>
                <TransactionBody>
                    <SubscriberThankYou />
                    <TransactionErrors />
                    <AccountForm onAccountCreated={() => setAccountCreated(true)} />
                    <ProfileForm
                        fields={itemDetails.override_account_fields ? itemDetails.account_fields : DEFAULT_ACCOUNT_FIELDS} 
                        inactive={!accountCreated || profileCompleted} 
                        onProfileCompleted={profile => setProfileCompleted(profile)} 
                        onClick={() => profileCompleted && setProfileCompleted(false)} 
                    />
                    <FullHeightContainer id="step-two">
                        <PaymentAmount inactive={!isBillingStep} />
                        <BraintreeBilling inactive={!isBillingStep} />
                        <OptIns inactive={!isBillingStep} />
                        <SubmitTransactionBtn disabled={!isBillingStep} />
                        <Recaptcha />
                        <DisclaimerText>
                            <p>By purchasing, you agree to our <a href={DEFAULT_TOS_LINK} target="_blank" rel="noopener noreferrer">Terms of Service</a> and our <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                            <p>Skift, Inc. takes credit card data security very seriously. For that reason, we use <a href="https://www.braintreepayments.com/" target="_blank" rel="noopener noreferrer">Braintree</a> to securely handle credit card information. Braintree is a Validated Level 1 PCI DSS Compliant Service Provider.</p>
                        </DisclaimerText>
                        <SupportFooter />
                    </FullHeightContainer>
                    <TrackingPixels placement="registration" />
                </TransactionBody>
                <ScrollToActiveSection accountCreated={accountCreated} profileCompleted={profileCompleted} />
            </TransactionWrapper>
            <TransactionProcessing />
        </TransactionContextProvider>
    );
};

export default Main;
