import React from 'react';
import { Link } from 'react-router-dom';
import { useGetPurchasesQuery } from 'store/features/user-api';
import styled from 'styled-components';
import { Loader, BoxedItem, Stats, Stat, Flex, Button } from 'ui';
import DashboardFrame from '../DashboardFrame';
import UsedSeats from './UsedSeats';

const RightLink = styled(Link)`
    font-size: 15px;
    text-decoration: none;
    margin-top: 8px;

    &:hover {
        text-decoration: underline;
    }
`;

const MultiUserScreen = () => {
    const { data: transactions, isFetching } = useGetPurchasesQuery();

    if (isFetching) {
        return <DashboardFrame><Loader small>Loading multi-user companies</Loader></DashboardFrame>;
    }

    return (
        <DashboardFrame breadcrumbs={[['Home', '/'], ['Multi-User', null]]}>
            <h2>Multi-User Companies</h2>
            {!(transactions?.multi_user_companies || []).length && <p>You aren't the owner on any multi-user companies</p>} 
            {(transactions?.multi_user_companies || []).map((c, idx) => {
                 const link = `${process.env.REACT_APP_DOMAIN}/enterprise-welcome/${c?.invite_code}`;

                 return (
                     <BoxedItem
                         key={`${c?.id}-${idx}`}
                         heading={c?.name}
                         RightChildren={<Flex flexDirection="column" alignItems="center"><Button as="Link" size="small" to={`/multi-user/${c?.id}/purchases`} black>Subscriptions</Button><RightLink to={`/multi-user/${c?.id}/users`}>View Users</RightLink></Flex>}
                     >
                         <Stats>
                            <Stat label="Used Seats">
                                <UsedSeats seats={c?.seats} companyId={c?.id} />
                            </Stat>
                        </Stats>
                        <Stats>
                            {c?.invite_code && c?.active && <Stat label="Onboarding Link">
                                <a href={link}>{link}</a>
                            </Stat>}
                        </Stats>
                         
                     </BoxedItem>
                 );
            })}
        </DashboardFrame>
    )
};

export default MultiUserScreen;
