import React, { useState, useCallback, useEffect } from 'react';
import { useContentItem } from 'hooks';
import { Button, Flex, Message, TextField, TransactionMessage } from 'ui';
import { useDispatch } from 'react-redux';
import { setAccessCode as setAccessCodeInState } from 'store/features/transaction';
import axios from 'axios';
import { DEFAULT_ERROR_MESSAGE } from 'utils/constants';

const AccessCodeInput = ({ accessCodeQS }) => {
    const { data: item, isFetching } = useContentItem();
    const dispatch = useDispatch();
    const [accessCode, setAccessCode] = useState('');
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    const eventId = item?.details?.id;

    const validate = useCallback(code => {
        if (!code) {
            setError('Please enter an access code');
            return;
        }

        (async () => {
            setLoading(true);

            try {
                const resp = await axios.get(`${process.env.REACT_APP_SKIFT_API_DOMAIN}/api/public/event/validate-access-code?access_code=${code}&event_id=${eventId}`);
                if (resp.data?.access_code) {
                    dispatch(setAccessCodeInState(resp.data?.access_code));
                }
            } catch(err) {
                setError(err.response.data?.error?.message || DEFAULT_ERROR_MESSAGE);
            }

            setLoading(false);
        })();
    }, [setLoading, eventId, setError, dispatch]);

    useEffect(() => {
        if (accessCodeQS && !isFetching) {
            validate(accessCodeQS);
        }
    }, [accessCodeQS, isFetching, validate]);

    const handleSubmit = e => {
        e.preventDefault();
        validate(accessCode);
    };


    const body = (
        <>
            <p>Registering for this event requires an access code. Don't have a code? <a href="mailto:support@skift.com">Email support</a></p>
            {error && <Message error heading="Something didn't work">{error}</Message>}
            <form onSubmit={handleSubmit}>
                <Flex alignItems="center" justifyContent="center">
                    <TextField disabled={loading} label="Enter an access code" value={accessCode} onChange={e => setAccessCode(e.target.value)} />
                    <Button disabled={loading} loading={loading} size="small" color="black" style={{ height: '50.5px', marginLeft: '5px' }}>Enter</Button>
                </Flex>
            </form>
        </>
    );
  
    return (
        <TransactionMessage
            heading="Access Code Required"
            isFetching={isFetching}
            loaderText="Loading event"
            showDashboardLink={false}
        >{body}</TransactionMessage>
    );
};

export default AccessCodeInput;
