import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setItemId, setTransactionType, setCurrency, setItemName } from 'store/features/transaction';
import { useContentItem } from 'hooks';

import Main from './Main';
import { TRANSACTION_TYPES } from 'utils/constants';
import PageTitle from 'components/PageTitle';
import ViewTransactionPageEvent from 'components/ViewTransactionPageEvent';
import EventFetcher from './EventFetcher';
import AccessCheck from './AccessCheck';

const EventRegistration = () => {
    const dispatch = useDispatch();
    const { eventid: eventId } = useParams();
    const { data: item } = useContentItem();
    const { currency, name } = item?.details || {};
    
    useEffect(() => {
        dispatch(setTransactionType(TRANSACTION_TYPES.EVENT));
        dispatch(setItemId(eventId));
    }, [dispatch, eventId]);

    useEffect(() => {
        dispatch(setCurrency(currency));
        dispatch(setItemName(name));
    }, [dispatch, currency, name]);

    return (
        <>
            <PageTitle title={name && `Register for ${name}`}>
                <EventFetcher>
                    <AccessCheck>
                        <Main />
                    </AccessCheck>
                </EventFetcher>
            </PageTitle>;
            <ViewTransactionPageEvent type={TRANSACTION_TYPES.EVENT} itemId={eventId} itemName={name} />
        </>
    );
};

export default EventRegistration;
