import styled from "styled-components";

const Wrap = styled.div`
    text-align: center;
    margin: 30px 0;
    font-size: 15px;
`;

const SupportFooter = () => (
    <Wrap>Need help? <a href="https://info.skift.com/knowledge" target="_blank" rel="noopener noreferrer">Search our help center</a>.</Wrap>
);

export default SupportFooter;
