import React from 'react';
import { useContentItem } from 'hooks';
import { TransactionMessage, Button, LinkButton } from 'ui';
import { Link } from 'react-router-dom';
import LoginLink from 'components/LoginLink';
import { useAuth0 } from 'components/auth0/Auth0Context';


const ExclusiveEventMessage = ({ enterAccessCode }) => {
    const { data: item, isFetching } = useContentItem();
    const { exclusive_for_plans = [], require_access_code, exclusive_for_plan_is_any: anyPlan } = item?.details || {};
    const { isAuthenticated } = useAuth0();

    const planList = () => {
        return exclusive_for_plans.map(p => {
            if (p.enterprise_eligible) {
                return <div key={`plan-${p.plan_code}`}>{p.name}</div>
            }

            return <div key={`plan-${p.plan_code}`}><Link to={`/subscribe/${p.plan_code}`}>{p.name}</Link></div>
        });
    };

    const content = () => {
        if (anyPlan) {
            return (
                <>
                    <p>This event is available to subscribers of any Skift subscription.</p>
                    <Button as="a" black href="https://skift.com/get-access/">Learn More</Button>
                </>
            );
        }

        if (exclusive_for_plans.length === 1) {
            return (
                <>
                    <p>This event is available exclusively to subscribers of {exclusive_for_plans[0].name}.</p>
                    {!exclusive_for_plans[0].enterprise_eligible && <Button black as={Link} to={`/subscribe/${exclusive_for_plans[0].plan_code}`}>Subscribe Now</Button>}
                </>
            );
        }

        return (
            <>
                <p>This event is available exclusively to subscribers to the following plans:</p>
                <div style={{ margin: '20px 0'}}>{planList()}</div>
                <Button as="a" black href="https://skift.com/get-access/">Learn More</Button>
            </>
        );
    }

    const body = (
        <>
            {content()}
            {!isAuthenticated && <p><LoginLink text="Have an account? Sign in" /></p>}
            {require_access_code && <p><LinkButton onClick={enterAccessCode}>Do you have an access code?</LinkButton></p>}
        </>
    );
  
    return (
        <TransactionMessage
            heading="Exclusive Event"
            isFetching={isFetching}
            loaderText="Loading event"
            showDashboardLink={false}
        >{body}</TransactionMessage>
    );
};

export default ExclusiveEventMessage;
