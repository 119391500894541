import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import auth0 from 'auth0-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
export const Auth0Context = createContext();

export class AuthError extends Error {
    constructor(message, code) {
        super(message);
        this.code = code;
        this.message = message;
    }
}

export const Auth0ContextProvider = ({ children }) => {
    const [webAuth] = useState(() => {
        return new auth0.WebAuth({
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
            redirectUri: window.location.origin + '/callback',
            responseType: 'token id_token',
            audience: process.env.REACT_APP_AUTH0_MANAGEMENT_API
        });
    });

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const [accessTokenExpiry, setAccessTokenExpiry] = useState(null);

    const navigate = useNavigate();

    const didInitialise = useRef(false);
    const pollingInterval = useRef(null);

    const cacheAccessToken = useCallback(authResult => {
        const token = authResult.accessToken;
        const expiry = authResult.expiresIn * 1000 + Date.now();

        setAccessToken(token);
        setAccessTokenExpiry(expiry);
    }, []);

    const parseHash = useCallback(() => {
        return new Promise((resolve, reject) => {
            webAuth.parseHash({}, (err, authResult) => {
                console.log('parseHash', { err, authResult });
                if (err) {                    
                    setIsAuthenticated(false);
                    return reject(err);
                }

                if (!authResult || !authResult.accessToken) {
                    setIsAuthenticated(false);
                    return resolve(null);
                }

                cacheAccessToken(authResult);
                resolve(authResult);
            });
        });
    }, [webAuth, cacheAccessToken]);

    const checkSession = useCallback(() => {
        return new Promise((resolve, reject) => {
            webAuth.checkSession({}, (err, authResult) => {
                if (err) {
                    setIsAuthenticated(false);
                    return reject(err);
                }

                setIsAuthenticated(true);
                cacheAccessToken(authResult);
                
                resolve(authResult);
            });
        });
    }, [webAuth, cacheAccessToken]);

    const getAccessTokenSilently = useCallback(async () => {
        if (accessToken && accessTokenExpiry && accessTokenExpiry > Date.now()) {
            return accessToken;
        }

        const authResult = await checkSession();

        return authResult.accessToken;
    }, [accessToken, accessTokenExpiry, checkSession]);  

    const setRedirect = useCallback(passedRedirect => {
        // check if ?redirect= is in query string
        let redirect = passedRedirect;

        if (!redirect) {
            const searchParams = new URLSearchParams(window.location.search);
            redirect = searchParams.get('redirect');
            if (!redirect) {
                localStorage.removeItem('myskAuthRedirect');
                return;
            }
        }
        
        const banned_redirects = ['/login', '/loggedout'];
        if (banned_redirects.includes(redirect) || redirect.includes('/auth/log')) {
            localStorage.removeItem('myskAuthRedirect');
            return;
        }
        
        localStorage.setItem('myskAuthRedirect', redirect);
    }, []);

    const setAMPReaderId = useCallback(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const ampReaderId = searchParams.get('amp_reader_id');
        if (ampReaderId) {
            localStorage.setItem('ampReaderId', ampReaderId);
        }
    }, []);

    const startPasswordless = useCallback(async email => {
        return new Promise((resolve, reject) => {
            const payload = {
                email,
                connection: 'email',
                send: 'code',
            };

            webAuth.passwordlessStart(payload, (err, passwordlessRes) => {
                if (err) {
                    return reject(err);
                }
    
                resolve(passwordlessRes);
            });
        });
    }, [webAuth]);

    const verifyCode = useCallback(async (email, verificationCode) => {
        // store redirectUri in localStorage
        return new Promise((resolve, reject) => {
            webAuth.passwordlessLogin({
                connection: 'email',
                email,
                verificationCode
            }, (err, res) => {
                if (err) {
                    return reject(new AuthError(err.error_description, err.code));
                }

                resolve(res);
            });
        });
    }, [webAuth]);

    const login = useCallback(async (email, password) => {
        return new Promise((resolve, reject) => {
            webAuth.login({
                realm: 'Username-Password-Authentication',
                email,
                password
            }, (err, res) => {
                if (err) {
                    return reject(new AuthError(err.error_description, err.code));
                }

                resolve(res);
            });
        });
    }, [webAuth]);

    const forgotPassword = useCallback(async email => {
        return new Promise((resolve, reject) => {
            webAuth.changePassword({
                connection: 'Username-Password-Authentication',
                email
            }, (err, res) => {
                if (err) {
                    return reject(new AuthError(err.error_description, err.code));
                }

                resolve(res);
            });
        });
    }, [webAuth]);

    const preLogout = useCallback(async () => {
        // hit backend to logout reader
        return await axios.post(`${process.env.REACT_APP_SKIFT_API_DOMAIN}/api/public/pre-logout`, {}, { 
            withCredentials: true
        });
    }, []);

    const logout = useCallback(async () => {
        setIsLoggingOut(true);
        setRedirect();

        await preLogout();

        webAuth.logout({
            returnTo: window.location.origin + '/loggedout',
        });
    }, [setRedirect, preLogout, webAuth]);

    const doRedirect = useCallback(defaultRedirect => {
        const storedRedirect = localStorage.getItem('myskAuthRedirect');
        let redirect = storedRedirect || defaultRedirect;

        if (!redirect) {
            const searchParams = new URLSearchParams(window.location.search);
            redirect = searchParams.get('redirect');
        }
        
        if (redirect) {
            localStorage.removeItem('myskAuthRedirect');

            if (redirect.startsWith('http')) {
                // redirect away from React app
                setIsRedirecting(true);
                window.location.href = redirect;
                return true;
            } else {
                navigate(redirect);
            }
        }

        return false;
    }, [navigate]);

    const postLogin = useCallback(async authResult => {
        const token = authResult.accessToken;
        const payload = {};
        const ampReaderId = localStorage.getItem('ampReaderId');
        localStorage.removeItem('ampReaderId');
        if (ampReaderId) {
            payload.amp_reader_id = ampReaderId;
        }

        await axios.post(`${process.env.REACT_APP_SKIFT_API_DOMAIN}/api/user/post-login`, payload, { 
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    }, []);

    useEffect(() => {
        // poll checkSession every 30 minutes

        if (!isAuthenticated) {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
                pollingInterval.current = null;
            }

            return;
        }

        if (pollingInterval.current) {
            return;
        }

        pollingInterval.current = setInterval(() => {
            checkSession().catch(err => {
                clearInterval(pollingInterval.current);
            });
        }, 30 * 60 * 1000);

        
    }, [isAuthenticated, checkSession]);

    useEffect(() => {
        // initialize auth
        if (didInitialise.current) {
            return;
        }

        didInitialise.current = true;

        (async () => {
            const isOnCallback = window.location.pathname === '/callback';
            const isOnLogin = window.location.pathname === '/login';
            const isOnLoggedOut = window.location.pathname === '/loggedout';

            if (isOnLogin) {
                setRedirect();
                setAMPReaderId();
            }

            (async () => {
                let authResult;
                try {
                    authResult = await parseHash();
                } catch(err) {
                    setError(true);
                    setIsLoading(false);
                    return;
                }
                
                try {
                    if (!authResult) {
                        authResult = await checkSession();
                    }

                    setIsAuthenticated(true);

                    if (isOnCallback) {
                        await postLogin(authResult);
                        const isRedirecting = doRedirect('/');

                        if (isRedirecting) {
                            return;
                        }
                    }
                    
                    if (isOnLoggedOut) {
                        // if we're here, it means we're on the loggedout page and the user is authenticated - so we need to logout
                        await logout();
                    }

                    setIsLoading(false);
                    
                } catch (err) {
                    console.log('checkSession error', err);

                    setIsAuthenticated(false);
                    if (isOnLoggedOut) {
                        await preLogout();

                        // if we're here, it means we're on the loggedout page and the user is not authenticated - check if we need to redirect
                        const isRedirecting = doRedirect();
                        if (isRedirecting) {
                            return;
                        }
                    }

                    setIsLoading(false);
                }
            })();

        })();
    }, [didInitialise, webAuth, setRedirect, setAMPReaderId, parseHash, checkSession, postLogin, logout, preLogout, doRedirect]);
    
    return (
        <Auth0Context.Provider value={{ isLoading, isLoggingOut, isRedirecting, isAuthenticated, error, getAccessTokenSilently, startPasswordless, verifyCode, login, logout, forgotPassword, doRedirect, setRedirect }}>
            {children}
        </Auth0Context.Provider>
    );
};

export const useAuth0 = () => {
    return useContext(Auth0Context);
};
