import React from 'react';
import { Button, Flex, LinkButton } from 'ui';
import CompanyCard from 'ui/CompanyCard';

const EnterpriseSearchResult = ({ companies, reset }) => {
    const insertCompanyCards = companies => {
        if (!companies) return;

        if (companies.length === 0)
            return (
                <Flex
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    style={{ marginBottom: '17px' }}
                >
                    <p>
                        Your company doesn’t currently have a plan for you to
                        join. Connect with a subscription team member at Skift
                        for more information about enterprise plans.
                    </p>
                    <Button
                        as='a'
                        href='https://skift.com/pro/#team'
                        target='_blank'
                    >
                        Tell Me More
                    </Button>
                    <p style={{ marginBottom: '0px', fontSize: '0.9rem' }}>
                        Join Skift pro on your own today and save 25% with promo
                        code{' '}
                        <a
                            href={`${process.env.REACT_APP_DOMAIN}/subscribe/skift-membership-individual?promo=SKIFTPROTEAM`}
                            target='_blank'
                            rel='noreferrer'
                        >
                            SKIFTPROTEAM
                        </a>
                    </p>
                </Flex>
            );

        return companies?.map((company, idx) => {
            return <CompanyCard key={`company-${idx}`} company={company} />;
        });
    };

    return (
        <>
            <Flex wrap='wrap' justifyContent='center' alignItems='stretch'>
                {insertCompanyCards(companies)}
            </Flex>
            <Flex
                wrap='wrap'
                justifyContent='center'
                alignItems='stretch'
                style={{ margin: '20px 0' }}
            >
                <LinkButton onClick={reset} color='black'>Go Back</LinkButton>
            </Flex>
        </>
    );
};

export default EnterpriseSearchResult;
