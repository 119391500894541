import { useTransactionTotal } from 'hooks';
import React from 'react';
import { Panel } from 'ui';
import BraintreeDropin from './BraintreeDropin';
import styled from 'styled-components';

const BraintreePanel = styled(Panel)`
    .braintree-dropin {
        font-family: ${props => props.theme.bodyFont};
    }

    .braintree-heading {
        display: none;
        // font-family: ${props => props.theme.headerFont};
        // font-weight: normal;
        // margin-top: 0;
        // margin-bottom: 10px;
        // font-size: 1.5em;
    }
`;

const BraintreeBilling = ({ inactive, ...props }) => {
    const { data } = useTransactionTotal();

    if (data && !data.require_payment) {
        return null;
    }

    return (
        <BraintreePanel padded inactive={inactive}>
            <h2>Choose your payment method</h2>
            <BraintreeDropin {...props} />
        </BraintreePanel>
    );
};

export default BraintreeBilling;
