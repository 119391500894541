import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { formatCurrency } from 'utils/formatNumber';
import { Select } from 'ui';
import { useContentItem } from 'hooks';
import { setCurrency } from 'store/features/transaction';

const PlanOptionPicker = ({ siteId }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { data: item, isLoading, error } = useContentItem();
    const itemDetails = item?.details;

    const currency = useSelector(state => state.transaction.currency);

    useEffect(() => {
        if (itemDetails && !itemDetails.currencies[currency]) {
            dispatch(setCurrency('USD'));
        }
    }, [itemDetails, currency, dispatch]);

    const plans = useMemo(() => {
        if (!itemDetails) return [];

        const opts = [
            {
                planCode: itemDetails.plan_code,
                unitAmount: itemDetails.currencies[currency],
                billingFrequency: itemDetails.billing_frequency
            }
        ];

        const priceForCurrency = currencies => currencies.find(c => c.currency === currency)?.unitAmount || 0;

        const associatedPlans = (itemDetails.associated_plans || []).map(plan => ({
            planCode: plan.plan_code,
            unitAmount: priceForCurrency(plan.currencies),
            billingFrequency: plan.billing_frequency
        }));

        opts.push(...associatedPlans);

        return opts.sort((a, b) => a.unitAmount - b.unitAmount);
    }, [itemDetails, currency]);

    if (isLoading) {
        return <p>Loading options...</p>;
    }
    
    if (error || !plans) {
        return null;
    }

    if (plans.length === 1) {
        const { unitAmount, billingFrequency } = plans[0];

        return <p>{formatCurrency(unitAmount, currency)} {billingFrequency}</p>;
    }

    return (
        <Select focus defaultValue={itemDetails.plan_code} onChange={e => navigate(`/subscribe/${e.target.value}${window.location.search}`)}>
            {plans.map(plan => {
                const { planCode, unitAmount, billingFrequency } = plan;
                return <option key={`plan-picker-opt-${planCode}`} value={planCode}>{formatCurrency(unitAmount, currency)} {billingFrequency}</option>;
            })}
        </Select>
    );
};

export default PlanOptionPicker;
