import React from 'react';
import { useContentItem } from 'hooks';
import { TransactionMessage } from 'ui';
import { Link } from 'react-router-dom';

const AlreadyRegistered = () => {
    const { data: item, isFetching } = useContentItem();
    const { name } = item?.details || {};
    
    let body = <p>You are already registered for {name}. If you have further questions, please email <a href="mailto:support@skift.com">support@skift.com</a>.</p>;

    return (
            <TransactionMessage
                heading="You are already registered"
                isFetching={isFetching}
                loaderText="Loading event"
                showDashboardLink={false}
            >
                {body}
                <Link to="/event-registrations">View your registrations</Link>
            </TransactionMessage>
    );
}

export default AlreadyRegistered;