import React, { useMemo, useState } from 'react';
import { Button, Flex, Message, TextField } from 'ui';
import { validateEmail } from 'utils/validateEmail';
import { PERSONAL_EMAIL_DOMAINS } from 'utils/constants';
import { getEmailDomain } from 'utils/getEmailDomain';

const EnterpriseSearchBar = ({ setEmail, serverError }) => {
    const [clientError, setClientError] = useState('');
    const [initialEmailHolder, setInitialEmailHolder] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        if (!validateEmail(initialEmailHolder)) {
            setClientError('Please enter a valid email address');
        } else {
            let emailDomain = getEmailDomain(initialEmailHolder);

            if (PERSONAL_EMAIL_DOMAINS.indexOf(emailDomain) !== -1) {
                setClientError('Please use your work email');
            } else {
                setClientError(''); // reset the error message;
                setEmail(initialEmailHolder);
            }
        }
    };

    const errorMessage = useMemo(() => {
        return clientError || serverError;
    }, [serverError, clientError]);

    return (
        <>
            <p style={{ marginBottom: '0px' }}>
                Enter your work email address to unlock access to Skift through
                your company.
            </p>
            {errorMessage && (
                <Message error={true} style={{ marginBottom: '0px' }}>
                    {errorMessage}
                </Message>
            )}
            <Flex wrap='wrap' justifyContent='center' alignItems='stretch'>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label='Email'
                        value={initialEmailHolder}
                        onChange={e => setInitialEmailHolder(e.target.value)}
                    />
                    <Button black disabled={!initialEmailHolder}>
                        Search
                    </Button>
                </form>
            </Flex>
        </>
    );
};

export default EnterpriseSearchBar;
