import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetCompanyQuery } from 'store/features/user-api';
import TransactionReceipts from '../TransactionReceipts';

const MultiUserTransactions = () => {
    const { id } = useParams();
    const { data: companyData } = useGetCompanyQuery(id);

    const crumbs = [['Home', '/'], ['Multi-User', '/multi-user'], ['Subscriptions', `/multi-user/${id}/purchases`], ['Transactions', null]];

    return <TransactionReceipts breadcrumbs={crumbs} companyId={id} heading={`${companyData?.company?.name || 'Company'} Transactions`} />;
};

export default MultiUserTransactions;
