import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setItemId, setTransactionType } from 'store/features/transaction';

import Main from './Main';
import { TRANSACTION_TYPES } from 'utils/constants';
import PageTitle from 'components/PageTitle';

const EnterpriseOnboarding = () => {
    const dispatch = useDispatch();
    const { invitecode: inviteCode } = useParams();
    
    useEffect(() => {
        dispatch(setTransactionType(TRANSACTION_TYPES.ENTERPRISE_COMPANY));
        dispatch(setItemId(inviteCode));
    }, [dispatch, inviteCode]);


    return <PageTitle title="Welcome to Skift"><Main /></PageTitle>;
};

export default EnterpriseOnboarding;
