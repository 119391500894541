import React, { useMemo } from 'react';
import { useGetPurchasesQuery } from 'store/features/user-api';
import SummaryPane from '../SummaryPane';

const EduCreditSummary = () => {
    const { data: transactions } = useGetPurchasesQuery();
    const credits = transactions?.edu_credits;

    const text = useMemo(() => {
        // generate an object of types with the total amount of credits as the value
        const typesByKey = (credits || []).reduce((a, c) => {
            const item = c?.item || { credit_type: 'cmp', credits: 1 };
            a[item.credit_type] = a[item.credit_type] || 0;
            a[item.credit_type] += item.credits;
            return a;
        }, {});

        // 
        const types = Object.keys(typesByKey).map(key => `${typesByKey[key]} ${key} credit${typesByKey[key] > 1 ? 's' : ''}`);

        if (types.length > 1) {
            types[types.length - 1] = `and ${types[types.length - 1]}`;
        }

        return `You have redeemed ${types.join(types.length > 2 ? ', ' : ' ')}.`;
    }, [credits]);


    if (!credits.length) {
        return;
    }

    return (
        <SummaryPane heading="Edu Credits" to="/edu-credits">
            <p>{text}</p>
        </SummaryPane>
    );
};

export default EduCreditSummary;
