import React from 'react';
import { LinkButton } from 'ui';

const LoginLink = ({ 
    text = 'Already have an account? Sign in',
}) => {
    localStorage.setItem('myskAuthRedirect', window.location.href);
    return <LinkButton to="/login">{text}</LinkButton>;
};

export default LoginLink;
