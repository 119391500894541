import styled from "styled-components";

export const Flex = styled.div.attrs(props => {
    props.justifyContent = props.justifyContent || 'flex-start'
    props.alignItems = props.alignItems || 'flex-start'
    props.flexDirection = props.flexDirection || 'row'
    props.wrap = props.wrap || 'no-wrap';

    return props;
})`
    display: flex;
    justify-content: ${props => props.justifyContent};
    align-items: ${props => props.alignItems};
    flex-direction: ${props => props.flexDirection};
    flex-wrap: ${props => props.wrap};
`;
