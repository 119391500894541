import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useEnableSubscriptionAutoRenewMutation } from 'store/features/user-api';
import { Button, Message } from 'ui';

const AutoRenewIncentive = ({ subscription }) => {
    const heading = subscription?.plan?.auto_renew_incentive_heading;
    const text = subscription?.plan?.auto_renew_incentive_text;

    const [go, { isLoading, error }] = useEnableSubscriptionAutoRenewMutation();

    useEffect(() => {
        if (error) {
            toast.error('Error enabling auto-renew. Please contact Support.');
        }
    }, [error]);

    const handleClick = useCallback(() => {
        if (window.confirm(`Are you sure you want to enable auto-renew for this subscription?`)) {
            go(subscription.id);
        }
    }, [go, subscription]);

    return (
        <Message light jumbotron heading={heading}>
            <p>{text}</p>
            <Button loading={isLoading} fluid onClick={handleClick}>Enable Auto-Renew</Button>
        </Message>
    );
};

export default AutoRenewIncentive;
