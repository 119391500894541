import React from 'react';
import { useSelector } from 'react-redux';
import { Flex } from 'ui';

import visaLogo from 'assets/payments/visa.svg';
import mastercardLogo from 'assets/payments/mastercard.svg';
import discoverLogo from 'assets/payments/discover.svg';
import amexLogo from 'assets/payments/amex.svg';
import styled from 'styled-components';

const Card = styled.img`
    margin: 0 3px;
`;

const AcceptedCardBrands = () => {
    const currency = useSelector(state => state.transaction.currency);

    return (
        <Flex justifyContent="center" alignItems="center">
            <Card src={visaLogo} alt="Visa" />
            <Card src={mastercardLogo} alt="MasterCard" />
            <Card src={discoverLogo} alt="Discover" />
            {currency === 'USD' && <Card src={amexLogo} alt="Discover" />}
        </Flex>
    )
};

export default AcceptedCardBrands;
