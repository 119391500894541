import React, { useMemo, useState } from 'react';
import { NavLink as NavLinkBase } from 'react-router-dom';
import { useGetPurchasesQuery } from 'store/features/user-api';
import styled from 'styled-components';

import calendarIcon from 'assets/icons/calendar.png';
import exitIcon from 'assets/icons/exit.png';
import fileIcon from 'assets/icons/file.png';
import houseIcon from 'assets/icons/home.png';
import newspaperIcon from 'assets/icons/news.png';
import userIcon from 'assets/icons/user.png';
import usersIcon from 'assets/icons/users.png';
import diplomaIcon from 'assets/icons/diploma.png';

const NavIcon = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;

    padding: 10px;
    transition: background-color 0.5s;

    img {
        width: 100%;
    }
`;

const NavLink = styled(NavLinkBase)`
    margin: 10px 0;
    font-size: 18px;
    font-family: ${props => props.theme.serifFont};

    text-decoration: none;
    display: flex;
    align-items: center;
    opacity: 0.6;

    transition: opacity 0.5s;

    &:hover, &.active {
        opacity: 1;
    }

    &.active ${NavIcon} {
        background-color: rgb(225, 225, 225);
    } 

    @media screen and (max-width: 800px) {
        display: none;

        &.active {
            display: flex;
        }
    }
`;

const ToggleMenuBtn = styled.button`
    display: none;

    @media screen and (max-width: 800px) {
        appearance: none;
        padding: 0;
        border: none;
        background: none;
        display: block;

        position: absolute;
        top: 50%;
        right: -30px;
        margin-top: -10px;
        cursor: pointer;

        width: 18px;
        height: 15px;
        
        &::after {
            content: '';
            width: 10px;
            height: 10px;
            display: block;
            border-right: 1px solid #000;
            border-bottom: 1px solid #000;
            transform: rotate(45deg);
            margin-left: 2px;
        }
    }
`;

const NavList = styled.div`
    position: sticky;
    top: 75px;

    @media screen and (min-width: 801px) {
        flex: 220px 0 0;
    }

    @media screen and (max-width: 800px) {
        background-color: #f7f7f7;
        width: 100%;
        position: fixed;
        top: 65px;
        left: 0;
        padding: 10px 15px;
        z-index: 4;

        display: flex;
        align-items: center;

        ${ToggleMenuBtn} {
            position: static;
            margin-left: 10px;
        }
    }
    
    ${props => {
        if (props.open) {
            return `
                @media screen and (max-width: 800px) {
                    position: fixed;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    background-color: #fff;
                    padding: 30px;
                    z-index: 4;
                    display: block;

                    ${NavLink} {
                        display: flex;
                    }

                    ${ToggleMenuBtn} {
                        position: absolute;
                        top: 40px;
                        right: 30px;

                        width: 30px;
                        height: 30px;

                        &::before, &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            margin-left: -21px;
                            width: 42px;
                            height: 0;
                            border-bottom: 1px solid #000;
                            transform: rotate(45deg);
                        }
                        
                        &::after {
                            border-right: none;
                            transform: rotate(-45deg);
                        }
                    }
                }
            `;
        }
    }}
`;

const MobilePad = styled.div`
    @media screen and (max-width: 800px) {
        height: 45px;
    }
`;


const Nav = () => {
    const { data: transactions } = useGetPurchasesQuery();
    const [open, setOpen] = useState();
    
    const navItems = useMemo(() => {
        const items = [
            {
                label: 'Dashboard',
                icon: houseIcon,
                route: '/'
            },
            {
                label: 'Profile Settings',
                icon: userIcon,
                route: '/profile'
            }
        ];

        if (transactions?.subscriptions && transactions.subscriptions.length) {
            items.push({
                label: 'Subscriptions',
                icon: newspaperIcon,
                route: '/subscriptions'
            });
        }

        if (transactions?.content && transactions.content.length) {
            items.push({
                label: 'Content Purchases',
                icon: fileIcon,
                route: '/content'
            });
        }

        if (transactions?.event_registrations && transactions.event_registrations.length) {
            items.push({
                label: 'Event Registrations',
                icon: calendarIcon,
                route: '/event-registrations'
            });
        }

        if (transactions?.event_group_registrations && transactions.event_group_registrations.length) {
            items.push({
                label: 'Group Tickets',
                icon: usersIcon,
                route: '/group-tickets'
            });
        }

        if (transactions?.edu_credits && transactions.edu_credits.length) {
            items.push({
                label: 'Edu Credits',
                icon: diplomaIcon,
                route: '/edu-credits'
            });
        }

        if (transactions?.multi_user_companies && transactions.multi_user_companies.length) {
            items.push({
                label: 'Multi-User',
                icon: usersIcon,
                route: '/multi-user'
            });
        }

        items.push({
            label: 'Sign Out',
            icon: exitIcon,
            route: '/loggedout'
        });

        return items;
    }, [transactions]);

    return (
        <>
            <NavList open={open}>
                {(navItems || []).map((item, i) => (
                    <NavLink key={`nav-item-${i}`} to={item.route} onClick={() => setOpen(true)}>
                        <NavIcon><img src={item.icon} alt={item.label} /></NavIcon>{item.label}
                    </NavLink>
                ))}
                <ToggleMenuBtn onClick={() => setOpen(!open)} />
            </NavList>
            <MobilePad />
        </>
    );
};

export default Nav;
