import styled from "styled-components";

const Wrap = styled.div.attrs(props => {
    props.margin = props.margin || '20px 0';
    return props;
})`
    margin: ${props => props.margin};
    display: flex;
    align-items: center;

    > a, > button {
        margin-left: 10px;
    }
`;

const Line = styled.div`
    height: 1px;
    flex-grow: 1;
    background-color: #b1b1b1;
`;

const Heading = styled.h3`
    margin: 0 10px;
    font-size: 16px;

    ${props => {
        if (props.largeHeading) {
            return `
                font-size: 20px;
            `;
        }
    }}

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    ${props => {
        if (props.left) {
            return `
                margin-left: 0;
            `;
        }
    }}
`;

export const LinedHeading = ({ children, RightSide, ...props }) => (
    <Wrap {...props}>
        {!props.left && <Line />}
        <Heading {...props}>{children}</Heading>
        <Line />
        {RightSide}
    </Wrap>
);
