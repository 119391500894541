import { TRANSACTION_TYPES } from "./constants";

export const generateTransactionPayload = state => {
    const transactionType = state.transaction.type;

    let payload = {}

    // Recurly Transactions
    if (transactionType === TRANSACTION_TYPES.SUBSCRIPTION || transactionType === TRANSACTION_TYPES.ITEM) {
        const usePrevMethod = state.transaction.paymentToken === 'usePreviousMethod';
        const token = !usePrevMethod ? state.transaction.paymentToken : null;

        payload = {
            item_id: state.transaction.itemId,
            promo_code: state.transaction.promo,
            currency: state.transaction.currency,
            account: state.transaction.profile,
            billing_info: {
                token,
                usePrevMethod
            }
        };
    }

    if (transactionType === TRANSACTION_TYPES.MULTI_USER_SUBSCRIPTION) {
        const usePrevMethod = state.transaction.paymentToken === 'usePreviousMethod';
        const token = !usePrevMethod ? state.transaction.paymentToken : null;

        payload = {
            item_id: state.transaction.itemId,
            account: state.transaction.profile,
            quantity: state.transaction.quantity,
            amount: state.transaction.amount,
            billing_info: {
                token,
                usePrevMethod
            }
        };
    }

    // event registrations
    if (transactionType === TRANSACTION_TYPES.EVENT) {
        const { nonce, deviceData } = state.transaction.paymentToken || {};
        
        payload = {
            event_code: state.transaction.itemId,
            contactInfo: state.transaction.profile,
            nonce,
            deviceData,
            amount: state.transaction.amount,
            gdpr: state.transaction.optIns,
            coupon_code: state.transaction.promo,
            badge_type: state.transaction.badgeType,
            group_code: state.transaction.groupCode,
            access_code: state.transaction.accessCode
        };
    }

    if (transactionType === TRANSACTION_TYPES.EVENT_GROUP) {
        const { nonce, deviceData } = state.transaction.paymentToken || {};
        
        payload = {
            event_code: state.transaction.itemId,
            contactInfo: state.transaction.profile,
            nonce,
            deviceData,
            amount: state.transaction.amount,
            quantity: state.transaction.quantity
        };
    }

    if (transactionType === TRANSACTION_TYPES.EDU_CREDIT) {        
        payload = {
            item_code: state.transaction.itemId,
            contactInfo: state.transaction.profile,
        };
    }

    if (transactionType === TRANSACTION_TYPES.ENTERPRISE_COMPANY) {        
        payload = {
            invite_code: state.transaction.itemId,
            contactInfo: state.transaction.profile,
        };
    }

    payload.recaptcha = state.transaction.recaptchaToken;

    return payload;
};
