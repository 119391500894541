import { configureStore } from '@reduxjs/toolkit';

// features
import contentApi from './features/content-api';
import transactionReducer from './features/transaction';
import braintreeReducer from './features/braintree';
import mutationCacheKeysReducer from './features/mutation-cache-keys';
import trackingReducer from './features/tracking';

// middleware
import scrollToTopOnErrorMiddleware from './middleware/scrollToTopOnError';
import appendToQuerystringMiddleware from './middleware/appendToQuerystring';
import eventTrackingMiddleware from './middleware/eventTracking';
import userApi from './features/user-api';

const constructMiddleware = getDefaultMiddleware => {
    const additionalMiddleware = [
        contentApi.middleware,
        userApi.middleware,
        scrollToTopOnErrorMiddleware,
        appendToQuerystringMiddleware,
        eventTrackingMiddleware
    ];

    return getDefaultMiddleware().concat(additionalMiddleware);
};

const configureAppStore = preloadedState => {
    const store = configureStore({
        reducer: {
            [contentApi.reducerPath]: contentApi.reducer,
            [userApi.reducerPath]: userApi.reducer,
            transaction: transactionReducer,
            braintree: braintreeReducer,
            mutationCacheKeys: mutationCacheKeysReducer,
            tracking: trackingReducer
        },
        middleware: constructMiddleware,
        preloadedState
    });

    return store;
};

export default configureAppStore;
