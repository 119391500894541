import { FormGroup } from "ui/FormGroup";
import { FormLabel } from "ui/FormLabel";
import { CoreRadio } from "./CoreRadio";

export const Radio = ({ label, id, ...props }) => (
    <FormGroup>
        <CoreRadio id={id} type="radio" {...props} />
        <FormLabel htmlFor={id}><div className="indicator" />{label}</FormLabel>
    </FormGroup>
);
