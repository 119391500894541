import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_TOS_LINK, ENTERPRISE_ONBOARDING_FIELDS, PRIVACY_POLICY_LINK } from 'utils/constants';

import { useContentItem } from 'hooks';
import { 
    TransactionWrapper, 
    TransactionBody, 
    TransactionRail,
    TransactionProcessing, 
    TransactionErrors,
    DisclaimerText,
    FullScreenLoader, 
    FullHeightContainer, 
    SubmitTransactionBtn
} from 'ui';

import { TransactionContextProvider } from 'components/TransactionContext';

import AccountForm from 'components/AccountForm';
import ProfileForm from 'components/ProfileForm';

import TransactionComplete from './TransactionComplete';
import EnterpriseOnboardingTransactionSummary from './EnterpriseOnboardingTransactionSummary';
import EnterInviteCode from './EnterInviteCode';
import SupportFooter from 'components/SupportFooter';
import Recaptcha from 'components/Recaptcha';

const Main = () => {
    const { data: item, isLoading, error: contentError } = useContentItem();
    const [accountCreated, setAccountCreated] = useState(false);
    const [profileCompleted, setProfileCompleted] = useState(false);
    const transactionComplete = useSelector(state => state.transaction.complete);

    if (isLoading) {
        return <FullScreenLoader text="Loading Company" />
    }
    
    if (!item || contentError) {
        return <EnterInviteCode error={contentError ? (contentError?.data?.error?.message || DEFAULT_ERROR_MESSAGE) : 'Invalid invite code'} />;
    }


    if (transactionComplete) {
        return <TransactionComplete />;
    }
    
    return (
        <TransactionContextProvider submitBtnText="Join Company" promoCodesEnabled={false} showPricingSection={false}>
            <TransactionWrapper>
                <TransactionRail>
                    <EnterpriseOnboardingTransactionSummary />
                </TransactionRail>
                <TransactionBody>
                    <TransactionErrors />
                    <AccountForm onAccountCreated={() => setAccountCreated(true)} passwordOnly={true} />
                    <ProfileForm
                        fields={ENTERPRISE_ONBOARDING_FIELDS}
                        inactive={!accountCreated || profileCompleted} 
                        onProfileCompleted={profile => setProfileCompleted(profile)} 
                        onClick={() => profileCompleted && setProfileCompleted(false)} 
                        showContinueBtn={false}
                    />
                    <FullHeightContainer id="step-two">
                        <SubmitTransactionBtn disabled={!accountCreated} />
                        <Recaptcha />
                        <DisclaimerText>
                            <p>By submitting, you agree to our <a href={DEFAULT_TOS_LINK} target="_blank" rel="noopener noreferrer">Terms of Service</a> and our <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                        </DisclaimerText>
                        <SupportFooter />
                    </FullHeightContainer>
                </TransactionBody>
            </TransactionWrapper>
            <TransactionProcessing />
        </TransactionContextProvider>
    );
};

export default Main;
