import React, { useContext, useEffect, useState } from "react";
import { useBraintreeToken } from "hooks/useBraintreeToken";
import { useRecurly } from '@recurly/react-recurly';
import { Button, Message } from "ui";
import UsingAltMethod from "./UsingAltMethod";
import venmoLogo from 'assets/payments/venmo.svg';
import { DEFAULT_ERROR_MESSAGE } from "utils/constants";
import { TransactionContext } from "components/TransactionContext";
import { useDispatch } from "react-redux";
import { setPaymentToken } from "store/features/transaction";

const RecurlyVenmo = ({ clear }) => {
    const dispatch = useDispatch();
    const { token: btToken } = useBraintreeToken();
    const [error, setError] = useState(false);
    const [venmo, setVenmo] = useState(false);
    const [ready, setReady] = useState(false);
    const recurly = useRecurly();
    const { onSubmit, submitBtnText, processing } = useContext(TransactionContext);

    useEffect(() => {
        if (!btToken || !recurly || venmo) {
            return;
        }

        const v = recurly.Venmo({
            braintree: { clientAuthorization: btToken }
        });

        v.on('token', token => {
            if (token && token.id) {
                dispatch(setPaymentToken(token.id));
                onSubmit();
            } else {
                setError(true);
            }
        });

        v.on('ready', () => setReady(true));
        v.on('cancel', clear);

        setVenmo(v);
    }, [recurly, venmo, btToken, setReady, setVenmo, setError, dispatch, onSubmit, clear]);

    const handleSubmit = e => {
        e.preventDefault();
        venmo.start();
    };

    return (
        <>
            {error && <Message error>{DEFAULT_ERROR_MESSAGE}</Message>}
            <UsingAltMethod logo={venmoLogo} alt="Venmo" clear={clear} />
            <Button fluid size="large" disabled={!ready || !venmo} loading={processing} black onClick={handleSubmit} style={{ marginTop: '30px' }}>{submitBtnText} with Venmo</Button>
        </>
    );
};

export default RecurlyVenmo;
