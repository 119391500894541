import React from 'react';
import { PricingLine, PricingLabel, PricingValue, ProductHeading, TransactionSummary } from 'ui';
import { useContentItem } from 'hooks';
import { useAuth0 } from 'components/auth0/Auth0Context';

const EnterpriseOnboardingTransactionSummary = () => {
    const { isAuthenticated } = useAuth0();
    const { data: item } = useContentItem();
    const details = item?.company || {};

    return (
        <TransactionSummary logoUrl={details?.logo_url}>
            <ProductHeading>Welcome, {details.name}!</ProductHeading>
            <p>{isAuthenticated ? 'Submit this form' : 'Create an account'} to join this enterprise subscription.</p>
            <PricingLine large>
                <PricingLabel>Invite Code</PricingLabel>
                <PricingValue>{details.invite_code}</PricingValue>
            </PricingLine>

        </TransactionSummary>
    );
};

export default EnterpriseOnboardingTransactionSummary;
