import React from 'react';
import { Provider } from 'react-redux'
import configureAppStore from 'store';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth0Loader from './auth0/Auth0Loader';
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from './themes';
import Main from './Main';
import GlobalCSS from './GlobalCSS'
import { Auth0Tokens } from './auth0/Auth0Tokens';
import { ToastContainer } from 'react-toastify';
import { Auth0ContextProvider } from './auth0/Auth0Context';

const App = () => {
    return (
        <Provider store={configureAppStore()}>
            <Router>
                <ThemeProvider theme={defaultTheme}>
                    <Auth0ContextProvider>
                        <Auth0Loader>
                            <Main />
                            <Auth0Tokens />
                            <ToastContainer position="top-center" />
                        </Auth0Loader>
                        <GlobalCSS />
                    </Auth0ContextProvider>
                </ThemeProvider>
            </Router>
        </Provider>
    );
};

export default App;
