import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
    border: 1px solid #E9E9E9;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: 30px;

    p {
        font-size: 16px;
    }

    &:last-child {
        margin: 0;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const Category = styled.h4`
    color: #F34033;
    font-size: 15px;
    font-family: ${props => props.theme.sansSerifFont};
    margin: 0 0 10px 0;
`;

const Heading = styled.h3`
    font-size: 24px;
    margin: 0 0 20px 0;
`;

const Left = styled.div``;
const Right = styled.div`
    margin-left: 20px;
    margin-top: 30px;

    @media screen and (max-width: 600px) {
        margin: 20px 0 0 0;
    }
`;

export const BoxedItem = ({ heading, category, RightChildren, children }) => (
    <Wrap>
        <Left>
            {category && <Category>{category}</Category>}
            {heading && <Heading>{heading}</Heading>}
            {children}
        </Left>
        {RightChildren && <Right>{RightChildren}</Right>}
    </Wrap>
);

