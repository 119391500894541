import React from 'react';
import { SelectField, TextField, PostalCodeField, PhoneField } from 'ui';

import { DEPARTMENTS, INDUSTRIES, JOB_LEVELS } from 'shared/account-constants';
import { countryData, regionData } from 'shared/country-region-data';
import { useLoggedInUser } from 'hooks/useLoggedInUser';

const Fields = ({ fields, onFieldChange, values = [], errors = [], disabled }) => {
    const showField = key => fields.includes(key);
    const getValue = key => values[key] || '';
    const handleFieldChange = e => onFieldChange(e.target.name, e.target.value);
    errors = errors.map(e => e.field);
    const hasError = key => errors.includes(key);

    const { data: profile } = useLoggedInUser();

    const countryForRegion = (() => {
        if (showField('country')) {
            return values['country'];
        }

        return profile.country;
    })();

    return (
        <>
            {
            showField('first_name') && 
                <TextField 
                    required
                    label="First Name" 
                    name="first_name" 
                    value={getValue('first_name')} 
                    onChange={handleFieldChange}
                    error={hasError('first_name')}
                    disabled={disabled}
                />
            }
            {
            showField('last_name') && 
                <TextField 
                    required
                    label="Last Name" 
                    name="last_name" 
                    value={getValue('last_name')} 
                    onChange={handleFieldChange}
                    error={hasError('last_name')}
                    disabled={disabled}
                />
            }
            {
            showField('company') && 
                <TextField 
                    required
                    label="Company" 
                    name="company" 
                    value={getValue('company')} 
                    onChange={handleFieldChange}
                    error={hasError('company')}
                    disabled={disabled}
                />
            }
            {
            showField('job') && 
                <TextField 
                    required
                    label="Job Title" 
                    name="job" 
                    value={getValue('job')} 
                    onChange={handleFieldChange}
                    error={hasError('job')}
                    disabled={disabled}
                />
            }
            {
            showField('job_level') && 
                <SelectField 
                    required
                    label="Job Level" 
                    name="job_level" 
                    value={getValue('job_level')} 
                    onChange={handleFieldChange}
                    error={hasError('job_level')}
                    disabled={disabled}
                    options={JOB_LEVELS}
                />
            }
            {
            showField('industry') && 
                <SelectField 
                    required
                    label="Industry" 
                    name="industry" 
                    value={getValue('industry')} 
                    onChange={handleFieldChange}
                    error={hasError('industry')}
                    disabled={disabled}
                    options={INDUSTRIES}
                />
            }
            {
            showField('department') && 
                <SelectField 
                    required
                    label="Department" 
                    name="department" 
                    value={getValue('department')} 
                    onChange={handleFieldChange}
                    error={hasError('department')}
                    disabled={disabled}
                    options={DEPARTMENTS}
                />
            }
            {
            showField('address') && 
                <TextField 
                    required
                    label="Address" 
                    name="address" 
                    value={getValue('address')} 
                    onChange={handleFieldChange}
                    error={hasError('address')}
                    disabled={disabled}
                />
            }
            { 
            showField('zip') && 
                <PostalCodeField 
                    required
                    label="Postal Code" 
                    name="zip" 
                    value={getValue('zip')} 
                    onChange={changes => onFieldChange(changes)}
                    error={hasError('zip')}
                    disabled={disabled}
                />
            }
            {
            showField('city') && 
                <TextField 
                    required
                    label="City" 
                    name="city" 
                    value={getValue('city')} 
                    onChange={handleFieldChange}
                    error={hasError('city')}
                    disabled={disabled}
                />
            }
            {
            showField('country') && 
                <SelectField 
                    required
                    label="Country" 
                    name="country" 
                    value={getValue('country')} 
                    onChange={handleFieldChange}
                    error={hasError('country')}
                    disabled={disabled}
                    options={countryData}
                />
            }
            {
            showField('region') && 
                <SelectField 
                    required
                    label="Region" 
                    name="region" 
                    value={getValue('region')} 
                    onChange={handleFieldChange}
                    error={hasError('region')}
                    disabled={disabled || !countryForRegion}
                    options={countryForRegion ? regionData[countryForRegion] : null}
                />
            }
            {
            showField('nationality') && 
                <SelectField 
                    required
                    label="Nationality" 
                    name="nationality" 
                    value={getValue('nationality')} 
                    onChange={handleFieldChange}
                    error={hasError('nationality')}
                    disabled={disabled}
                    options={countryData}
                />
            }
            {
            showField('phone') && 
                <PhoneField 
                    required
                    label="Phone" 
                    name="phone" 
                    value={getValue('phone')} 
                    onChange={val => onFieldChange('phone', val)}
                    error={hasError('phone')}
                    disabled={disabled}
                    country={countryForRegion}
                />
            }
        </>
    );
};

export default Fields;
