import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setAmount, setItemId, setItemName, setTransactionType } from 'store/features/transaction';

import Main from './Main';
import { TRANSACTION_TYPES } from 'utils/constants';
import { useContentItem } from 'hooks';
import PageTitle from 'components/PageTitle';
import ViewTransactionPageEvent from 'components/ViewTransactionPageEvent';

const EduCreditRedemption = () => {
    const dispatch = useDispatch();
    const { itemid: itemId } = useParams();
    const { data: item } = useContentItem();
    const { item_name } = item?.details || {};
    
    useEffect(() => {
        dispatch(setTransactionType(TRANSACTION_TYPES.EDU_CREDIT));
        dispatch(setItemId(itemId));
    }, [dispatch, itemId]);

    useEffect(() => {
        dispatch(setItemName(item_name));
        dispatch(setAmount(0));
    }, [dispatch, item_name]);

    return (
        <>
            <PageTitle title={item_name && `Redeem credits for ${item_name}`}><Main /></PageTitle>;
            <ViewTransactionPageEvent type={TRANSACTION_TYPES.EDU_CREDIT} itemId={itemId} itemName={item_name} />
        </>
    );
};

export default EduCreditRedemption;
