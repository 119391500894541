import styled from 'styled-components';
import { CoreMessage } from './Message/CoreMessage';

export const Panel = styled.div`
    background-color: ${props => props.theme.panelBackgroundColor};
    box-shadow: 1px 1px 3px rgb(0 0 0 / 15%);

    + * {
        margin-top: ${props => props.theme.gutterSize};
    }

    ${CoreMessage} {
        margin-left: -30px;
        margin-right: -30px;
        width: calc(100% + 60px);
        max-width: unset;
    }

    ${props => {
        if (props.padded) {
            return `
                padding: ${props.theme.gutterSize};

                @media screen and (max-width: 500px) {
                    padding: 20px;
                }
            `;
        }
    }}

    ${props => {
        if (props.textCenter) {
            return `
                text-align: center;
            `;
        }
    }}

    ${props => {
        if (props.inactive) {
            return `
                opacity: 0.3;

                * {
                    pointer-events: none;
                }
            `;
        }
    }}

    ${props => {
        if (props.sticky) {
            return `
                position: sticky;
                top: ${props.theme.gutterSize};
            `;
        }
    }}

    ${props => {        
        if (props.$loading) {
            // TODO: add spinner
            return `
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(255, 255, 255, 0.7);
                }
            `
        }
    }}
`;
