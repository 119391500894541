import styled from 'styled-components';

export const PaymentMethodButton = styled.button`
    border: 1px solid #b1b1b1;
    background: none;
    outline: none;
    padding: 5px 20px;
    cursor: pointer;

    margin: 0 5px;

    display: flex;
    align-items: center;

    img {
        height: 20px;
    }
    
    &:hover {
        background-color: #eee; 
    }
`;
