const scrollToTopOnErrorMiddleware = () => next => action => {
    if (action.type === 'transaction/setError') {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    next(action);
};

export default scrollToTopOnErrorMiddleware;
