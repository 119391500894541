import { TransactionContext } from 'components/TransactionContext';
import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useCreateAccountMutation } from 'store/features/user-api';

export const useNewAccount = () => {
    const fixedCacheKey = useSelector(state => state.mutationCacheKeys.newAccount);
    const { recaptcha } = useContext(TransactionContext);

    const [_createAccount, result] = useCreateAccountMutation({ fixedCacheKey });
    const resp = result?.data;

    const createAccount = useCallback(payload => {
        (async () => {
            const recaptchaToken = await recaptcha.executeAsync();
            recaptcha.reset();
            
            payload.recaptcha = recaptchaToken;
            
            _createAccount(payload);
        })();
    }, [recaptcha, _createAccount])

    return [createAccount, result, resp];
};
