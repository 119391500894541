import styled from 'styled-components';

export const LinkButton = styled.button`
    border: none;
    background: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    font-size: 15px;
    color: #000;

    &:hover {
        text-decoration: underline;
    }
`;
