import React from 'react';
import styled from 'styled-components';
import { countryData } from 'shared/country-region-data';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const StyledPhoneInput = styled(PhoneInput)`

    margin-bottom: 20px;

    input {
        padding: 15px 20px;
        outline: #000;
        transition: all 0.2s;
        width: 100%;
        background-color: inherit;
        appearance: none;
        font-size: 14px;
        color: #000;
        border: 1px solid #000;

        &:placeholder-shown {
            color: #b1b1b1;
            border: 1px solid #b1b1b1;
        }

        &:focus {
            border: 1px solid #000;
        }
    }

    ${props => {
        if (props.$error) {
            return `
                input {
                    border: 1px solid ${props.theme.errorColor} !important;
                    color: ${props.theme.errorColor} !important;

                    &::placeholder {
                        color: ${props.theme.errorColor} !important;

                    }
                }
            `;
        }
    }}
`;

export const PhoneField = ({ country, error, ...props }) => {
    const countryKey = () => {
        if (!country) return 'US';
        const match = countryData.find(c => c.text === country);
        return match?.key || 'US';
    };

    return (
        <StyledPhoneInput
            placeholder="Phone number"
            defaultCountry={countryKey()}
            $error={error}
            {...props}
        />
    );
};
