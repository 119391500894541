import { useCallback, useState } from "react";
import { ACCOUNT_PROPERTIES } from "shared/account-constants";
import { isValidPhoneNumber } from 'react-phone-number-input';

export const useProfileValidation = requiredFields => {
    const [errors, setErrors] = useState([]);
    const [validationRan, setValidationRan] = useState();

    requiredFields = ACCOUNT_PROPERTIES.filter(p => requiredFields.includes(p.key));

    const validate = useCallback(({ profile: values }) => {
        const nextErrors = requiredFields.reduce((a, field) => {
            const { text, key } = field;
            const value = values[key] && values[key].trim();
    
            if (!value || value === '') {
                a.push({
                    field: key,
                    text: `${text} is required`
                });
            } else {
                if (value.length < 2) {
                    a.push({
                        field: key,
                        text: `${text} must be at least 2 characters long`
                    });
                } else {
                    if (key !== 'industry' && value && value.length > 50) {
                        a.push({
                            field: key,
                            text: `${text} must be less than 50 characters long`
                        });
                    }

                    if (key === 'phone' && !isValidPhoneNumber(value)) {
                        a.push({
                            field: key,
                            text: `${text} must be a valid phone number`
                        });
                    }
                }
            }

            return a;
        }, []);

        setErrors(nextErrors);
        setValidationRan(true);
        
        return nextErrors.length === 0;
    }, [requiredFields]);

    return [errors, validate, validationRan];
};
