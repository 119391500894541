import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrap = styled.div`
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;

    a {
        text-decoration: none;
    }
`;

const Disabled = styled.span`
    opacity: 0.3;
`;

const Pagination = ({ nextPage, prevPage, urlFormatter }) => {
    if (!nextPage && !prevPage) {
        return null;
    }

    return (
        <Wrap>
            {prevPage ? <Link to={urlFormatter(prevPage)}>Previous Page</Link> : <Disabled>Previous Page</Disabled>}
            {nextPage ? <Link to={urlFormatter(nextPage)}>Next Page</Link> : <Disabled>Next Page</Disabled>}
        </Wrap>
    );
};

export default Pagination;
