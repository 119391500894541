import moment from 'moment';

const renewsOrExpiredLine = sub => {
    if (sub.state === 'canceled') {
        return `Ending on ${moment(sub.current_period_ends_at).format('MM/DD/Y h:mm a')}`;
    }

    if (sub.state === 'active') {
        if (sub.auto_renew) {
            return `Renews on ${moment(sub.current_period_ends_at).format('MM/DD/Y h:mm a')}`;
        }
        
        return `Ending on ${moment(sub.current_period_ends_at).format('MM/DD/Y h:mm a')}`;
    }

    return `Ended on ${moment(sub.expires_at).format('MM/DD/Y h:mm a')}`;
};

export default renewsOrExpiredLine;
