import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FullScreenLoader, FullScreenWrapper } from 'ui';

const Wrap = styled.div`
    text-align: center;
    overflow: auto;
    max-width: 750px;
    padding: 15px;

    h1 {
        font-size: 3.5rem;
        margin: 0;
    }

    @media screen and (max-width: 500px) {
        h1 {
            font-size: 2.8rem;
        }
    }
`;

export const TransactionMessage = ({ isFetching, heading, children, loaderText = "Loading purchase", showDashboardLink = true }) => {
    if (isFetching) {
        return <FullScreenLoader text={loaderText} />;
    }

    return (
        <FullScreenWrapper>
            <Wrap>
                {heading && <h1>{heading}</h1>}
                {children}
                {showDashboardLink && <Link to="/">View your Skift dashboard</Link>}
            </Wrap>
        </FullScreenWrapper>
    );
};
