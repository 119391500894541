import styled from 'styled-components';

export const createButton = tag => styled(tag).attrs(props => {
    props.display = 'inline-flex';
    props.width = 'inherit';
    props.height = '40px';
    props.minwidth = 'unset';
    props.padding = '0 30px';
    props.backgroundcolor = props.theme.yellowColor;
    props.backgroundcolorhover = '#fff895';
    props.backgroundcoloractive = '#c8bc00';
    props.textcolor = '#000';
    props.cursor = 'pointer';
    props.fontSize = '1rem';
    props.border = 'none';
    props.borderhover = 'none';
    
    if (props.color === 'black' || props.black) {
        props.black = true; // set for color = 'black' for future use
        props.backgroundcolor = '#000';
        props.backgroundcolorhover = '#333';
        props.backgroundcoloractive = '#000';
        props.textcolor = '#fff';
    }

    if (props.color === 'outline' || props.$outline) {
        props.$outline = true; // set for color = 'outline' for future use
        props.backgroundcolor = props.backgroundcoloractive = props.backgroundcolorhover = 'transparent';
        props.textcolor = '#000';
        props.border = '2px solid #000';
        props.borderhover = '2px solid #333';
    }
    
    if (props.disabled) {
        if (!props.$outline) {
            props.backgroundcolor = props.backgroundcoloractive = props.backgroundcolorhover = '#eaeaea';
        } else {
            props.border = props.borderhover = '2px solid #b1b1b1';
        }

        props.textcolor = '#b1b1b1';
        props.cursor = 'not-allowed';
    }
    
    if (props.fluid || props.block) {
        props.display = 'flex';
        props.width = '100%';
    }

    if (props.wide) {
        props.minwidth = '300px';
    }
    
    if (props.$loading) {
        props.backgroundcolorhover = props.backgroundcoloractive = props.backgroundcolor;
        props.cursor = 'default';
    }

    switch(props.size) {
        case 'xsmall':
            props.fontSize = '0.7rem';
            props.padding = '0 18px';
            props.height = '30px';
            break;
        case 'small':
            props.fontSize = '0.9rem';
            props.padding = '0 24px';
            props.height = '35px';
            break;
        case 'large':
            props.fontSize = '1.1rem';
            props.padding = '0 35px';
            props.height = '45px';
            break;
        case 'xlarge':
            props.fontSize = '1.2rem';
            props.padding = '0 40px';
            props.height = '50px';
            break;
        default: //medium
            break;
    }
    
    props.disabled = props.disabled || props.$loading;
    
    return props;
})`
    display: ${props => props.display};
    width: ${props => props.width};
    height: ${props => props.height};
    max-width: 100%;
    min-width: ${props => props.minwidth};
    text-decoration: none;

    line-height: normal;
    text-align: center;

    justify-content: center;
    align-items: center;


    appearance: none;
    border-radius: 0;

    border: ${props => props.border};
    cursor: ${props => props.cursor};

    transition: background-color 0.2s, color 0.2s, border-color 0.2s;

    font-size: ${props => props.fontSize};
    padding: ${props => props.padding};

    background-color: ${props => props.backgroundcolor};
    color: ${props => props.textcolor};

    &:hover {
        background-color: ${props => props.backgroundcolorhover};
        border: ${props => props.borderhover};
    }

    &:active {
        background-color: ${props => props.backgroundcoloractive};
    }

    .loader {
        width: 30px;

        ${props => {
            if (props.black) {
                return 'filter: invert(100%);';
            }
        }}
    }

    .icon {
        margin-right: 5px;
    }
`;

export const CoreButton = createButton('button');
export const AnchorLink = createButton('a');
