import React, { useEffect, useMemo, useState } from 'react';
import { Button, LinkButton, Message } from 'ui';
import { validateEmail } from 'utils/validateEmail';
import EmailField from './EmailField';
import { useAuth0 } from 'components/auth0/Auth0Context';
import { STAGES } from '.';

const ForgotPassword = ({ setStage, setEmail: _setEmail, email: _email, setResetEmailSent, reset }) => {
    const [error, setError] = useState();
    const [isSending, setIsSending] = useState(false);
    const [email, setEmail] = useState(_email);
    const { forgotPassword } = useAuth0();
    
    // on mount, focus the email field
    useEffect(() => {
        const emailField = document.getElementById('email-field');
        if (emailField) {
            emailField.focus();
        }
    }, []);

    const disabled = useMemo(() => {
        if (!email || !email.length) {
            return true;
        }

        return false;
    }, [email]);

    const handleEmailChange = e => {
        setError(false);
        setEmail(e.target.value);
    };

    const handleFormSubmit = e => {
        e.preventDefault();

        if (!validateEmail(email)) {
            return setError('Enter a valid email address');
        }

        (async () => {
            try {            
                setIsSending(true);
                
                await forgotPassword(email);
                _setEmail(email);
                setStage(STAGES.ENTER_PASSWORD);
                setResetEmailSent(true);
            } catch (err) {
                setIsSending(false);

                setError("An error occurred. Please try again.");
            }
        })();
    };
    
    return (
        <form onSubmit={handleFormSubmit}>
            {error && <Message error>{error}</Message>}
            <p>Enter your email address below to send a reset password link.</p>
            <EmailField id="email-field" disabled={isSending} value={email} onChange={handleEmailChange} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button disabled={disabled} $loading={isSending} black>Send Reset Link</Button>
                <LinkButton onClick={reset} disabled={isSending} style={{ marginLeft: '10px' }}>Start over</LinkButton>
            </div>
        </form>
    );
};

export default ForgotPassword;
