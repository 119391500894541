/* 
Shared Account Constants
Version 2
*/

const constants = {};

constants.ACCOUNT_PROPERTIES = [
    { key: 'first_name', value: 'first_name', text: 'First Name' },
    { key: 'last_name', value: 'last_name', text: 'Last Name' },
    { key: 'company', value: 'company', text: 'Company' },
    { key: 'job', value: 'job', text: 'Position' },
    { key: 'job_level', value: 'job_level', text: 'Job Level', choicesConstant: 'JOB_LEVELS' },
    { key: 'department', value: 'department', text: 'Department', choicesConstant: 'DEPARTMENTS' },
    { key: 'industry', value: 'industry', text: 'Industry', choicesConstant: 'INDUSTRIES' },
    { key: 'address', value: 'address', text: 'Address' },
    { key: 'city', value: 'city', text: 'City' },
    { key: 'region', value: 'region', text: 'Region' },
    { key: 'zip', value: 'zip', text: 'Postal Code' },
    { key: 'country', value: 'country', text: 'Country' },
    { key: 'nationality', value: 'nationality', text: 'Nationality' },
    { key: 'phone', value: 'phone', text: 'Phone Number' }
];

constants.STOVA_MAPPABLE_FIELDS = [
    'first_name', 
    'last_name',
    'company',
    'job',
    'job_level',
    'department',
    'industry',
    'phone'
];

constants.INDUSTRIES = [
    'Academia/Higher Education/University Faculty',
    'Aviation (Airlines, Airports, Vendors)',
    'Corporate Travel (Travel Management Company, Buyer)',
    'Cruise',
    'Destinations (DMO/CVB, Tourism Organization)',
    'Hospitality (Hotels, Rentals)',
    'Financial Services (Venture Capital/Investor)',
    'Ground Transport (Car Rental, Ridesharing, Taxi, Rail, Buses)',
    'Insurance',
    'Meetings & Events (Planner, Venue Provider, Event Technology)',
    'Non-industry travel enthusiast',
    'Online Booking (Online Travel Agencies, Metasearch)',
    'Press / Media',
    'Professional Services (Ad Agency, PR, Marketing, Consulting)',
    'Technology Vendor/Platform',
    'Tour Operators/Wholesalers',
    'Tours & Activities/Attractions',
    'Travel Agency (Travel Agent, Consortium)'
];

constants.JOB_LEVELS = [
    'Manager',
    'Director',
    'SVP/VP-Level', // change on HS, merge VP-Level
    'C-Level', // change on HS, merge C-level/Owner
    'Founder/Owner', // change on HS - merge Founder / Owner
    'Non-Manager'
];

constants.DEPARTMENTS = [
    'C-suite/Owner/Executive',
    'Creative',
    'Data/Analytics',
    'E-commerce',
    'Finance/Revenue Management',
    'Loyalty',
    'Marketing',
    'Product/Operations',
    'Research & Development',
    'Sales'
];

constants.SWAPCARD_MATCHING_FIELDS = [
    {
        key: 'applicable_industries',
        name: 'Industries that apply to you',
        options: [
            'Airlines/Airports',
            'Hotels',
            'Short-term rentals',
            'Travel software providers',
            'Cruise lines',
            'Tours and activities',
            'Meetings and events',
            'Destinations/Tourism Boards',
            'Travel Advisors',
            'Corporate Travel ',
            'Online Travel Agencies',
            'Ground transport'
        ]
    },
    {
        key: 'applicable_bus_funcs',
        name: 'Business functions that apply to you',
        options: [
            'Distribution',
            'Finance',
            'Revenue management',
            'Marketing and advertising',
            'Social media',
            'User-generated content',
            'Real estate',
            'Architecture',
            'Design',
            'Merchandising',
            'Product and operations',
            'Customer experience',
            'Investment and VC',
            'Payment products',
            'Loyalty',
            'Enterprise software',
            'E-commerce'
        ]
    },
    {
        key: 'applicable_topics',
        name: 'Travel industry topics that apply to you',
        options: [
            'Sustainability',
            'Future of cities and destinations',
            'Wellness',
            'Covid-19 business recovery',
            'Digital transformation',
            'Diversity and inclusion',
            'AI and machine learning'
        ]
    }
];

constants.PERMISSION_SYSTEM_ACTIVE = true;

constants.PERMISSION_MODULES = {
    USERS: 'users',
    ADMIN_USERS: 'admin_users',
    SUBSCRIPTIONS: 'subscriptions',
    COMPANIES: 'companies',
    INDIVIDUAL_CONTENT: 'individual_content',
    CONFIGURATION: 'configuration',
    COUPONS: 'coupons',
    TRANSACTIONS: 'transactions',
    EVENTS: 'events',
    EVENT_REGISTRATIONS: 'event_registrations',
    EVENT_CHECK_IN: 'event_check_in',
    EDU_CREDITS: 'edu_credits',
    EDU_CREDIT_REDEMPTIONS: 'edu_credit_redemptions'
};

constants.PERMISSION_LEVELS = {
    NO_ACCESS: 0,
    READ_ONLY: 1,
    READ_WRITE: 2,
    READ_WRITE_DELETE: 3
};

constants.PERMISSION_LEVEL_LABELS = ['No Access', 'Read-Only', 'Read/Write', 'Read/Write/Delete'];

module.exports = constants;
