import React from 'react';
import { PricingLine, PricingLabel, PricingValue, ProductHeading, TransactionSummary } from 'ui';
import { useContentItem } from 'hooks';

const EduItemTransactionSummary = () => {
    const { data: item } = useContentItem();
    const details = item?.details || {};

    return (
        <TransactionSummary logoUrl={details.site_id?.logo_url}>
            <ProductHeading>{details.item_name}</ProductHeading>
            <PricingLine large>
                <PricingLabel>Credits</PricingLabel>
                <PricingValue>{details.credits} {(details.credit_type || '').toUpperCase()}</PricingValue>
            </PricingLine>

        </TransactionSummary>
    );
};

export default EduItemTransactionSummary;
