import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useIpGatewayQuery } from 'store/features/user-api';
import { FullScreenLoader, FullScreenMessage, Flex, ProductSummary } from 'ui';

const UnlockedSites = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    min-height: 100vh;
    max-width: 1500px;
    margin: 0 auto;

    h1 {
        font-size: 3.5rem;
        margin: 0;
    }
`;

const IPGateway = () => {
    const { data, error } = useIpGatewayQuery();

    useEffect(() => {
        if (data && data.unlocked_sites && data.unlocked_sites.length === 1 && data.unlocked_sites[0].start_reading) {
            window.location.href = data.unlocked_sites[0].start_reading;
        }
    }, [data]);

    if (data && data.unlocked_sites && data.unlocked_sites.length === 1 && data.unlocked_sites[0].start_reading) {
        return <FullScreenLoader text="Redirecting" />;
    }

    if (data && data.unlocked_sites && data.unlocked_sites.length > 0) {
        const heading = data.matching_company_names.length > 1 ? 'Welcome to Skift!' : `Welcome, ${data.matching_company_names[0]}!`;
        return (
            <UnlockedSites>
                <h1>{heading}</h1>
                <p>You have unlocked the following product{data.unlocked_sites.length > 1 && 's'}:</p>
                <Flex wrap="wrap" justifyContent="center" alignItems="stretch" style={{ margin: '20px 0'}}>
                    {data.unlocked_sites.map(s => <ProductSummary key={`prod-${s.id}`} product={s} />)}
                </Flex>
            </UnlockedSites>
        );
    }

    if (data && data.ip) {
        return (
            <FullScreenMessage heading="Your IP Does Not Have Access" error>
                <p>Your IP address, <strong>{data.ip}</strong>, does not have access to Skift.</p>
                <p>If this is an error please contact your administrator with the ip address listed above.</p>

            </FullScreenMessage>
        );
    }

    if (error) {
        return (
            <FullScreenMessage heading="An Error Occured" error>
                Please try again. If the issue persists, please contact <a href="mailto:support@skift.com">support@skift.com</a>.
            </FullScreenMessage>
        );
    }

    return <FullScreenLoader text="Authenticating" />;
};

export default IPGateway;
