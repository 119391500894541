import styled from 'styled-components';

const mobileBreakpoint = 900;

export const TransactionBody = styled.div`
`;

export const TransactionRail = styled.div`
    flex-basis: 350px;
    flex-shrink: 0;
`;

export const TransactionWrapper = styled.div`
    max-width: 900px;
    margin: 0 auto;
    padding: ${props => props.theme.gutterSize} 0;

    @media screen and (min-width: ${mobileBreakpoint}px) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;   
        
        ${TransactionBody} {
            flex-grow: 1;
        }

        ${TransactionRail} {
            margin-left: ${props => props.theme.gutterSize};
        }
    }

    @media screen and (max-width: ${mobileBreakpoint - 1}px) {
        padding: 15px;
        
        ${TransactionRail} {
            margin-bottom: ${props => props.theme.gutterSize};
        }
    }
`;