import { useTransactionTotal } from 'hooks';
import { useEffect } from 'react';

const ScrollToActiveSection = ({ accountCreated, profileCompleted }) => {
    const { data: transactionData } = useTransactionTotal();

    useEffect(() => {
        let eleId;
        if (accountCreated && !profileCompleted) {  
            // scroll to profile
            eleId = 'profile-form';
        } else if (accountCreated && profileCompleted && transactionData?.require_payment) {
            // scroll to billing
            eleId = 'step-two';
        }

        const ele = eleId && document.getElementById(eleId);

        if (ele) {
            window.scrollTo({
                top: ele.offsetTop - 20,
                behavior: 'smooth'
            });
        }
    }, [accountCreated, profileCompleted, transactionData?.require_payment]);

    return null;
};

export default ScrollToActiveSection;
