import { useContentItem } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, ProductSummary, TransactionMessage } from 'ui';

const TransactionCompleteMultiUser = () => {
    const { invite_code, seats } = useSelector(state => state.transaction.transactionResponse) || {};

    const { data: item, isFetching } = useContentItem();
    const sites = item?.details?.site_access || [];

    let body = <p>Click the link below to view your dashboard</p>;

    const link = `${process.env.REACT_APP_DOMAIN}/enterprise-welcome/${invite_code}/`;

    if (sites.length) {
        body = (
            <>
                <p>You have purchased {seats} seats to the following product{sites.length > 1 && 's'}</p>
                <Flex wrap="wrap" justifyContent="center" alignItems="stretch" style={{ margin: '20px 0'}}>
                    {sites.map(s => <ProductSummary key={`prod-${s.id}`} product={s} />)}
                </Flex>
                <p><strong>Next Steps:</strong> To use a seat, copy the link below and share it with your colleagues. If you have further questions, please email <a href="mailto:support@skift.com">support@skift.com</a>.</p> 

                <div style={{ textAlign: 'center', marginBottom: '40px' }}>
                    <h2 style={{ margin: '40px 0 15px 0' }}>Your Unique Onboarding Link</h2>
                    <strong><a href={link} target="_blank" rel="noopener noreferrer">{link}</a></strong>
                    <p>Save this link somewhere safe before leaving this page.</p>
                </div>
            </>
        );
    }

    return (
        <TransactionMessage
            heading="Thank you"
            isFetching={isFetching}
        >{body}</TransactionMessage>
    );
};

export default TransactionCompleteMultiUser;
