import React from 'react';
import styled from 'styled-components';
import LoginCreateAccount from './LoginCreateAccount';
import { TransactionContextProvider } from 'components/TransactionContext';
import { FullScreenWrapper } from 'ui';
import SkiftLogo from 'assets/skift-logo.svg';
import Recaptcha from './Recaptcha';
import { usePreLoginQuery } from 'store/features/user-api';
import { Link } from 'react-router-dom';

const OuterWrap = styled(FullScreenWrapper)`
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
`;

const Logo = styled.img`
    width: 100px;
`;

const Wrap = styled.div`
    padding: 80px 30px;
    background: #fff;
    width: 400px;
    max-width: 100%;

    background-color: #fff;
    box-shadow: 1px 1px 3px rgb(0 0 0 / 15%);

    h1 {
        font-size: 18px;
        margin: 10px 0;
    }
`;

const IPLogin = styled.div`
    margin-top: 20px;
    font-size: 16px;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }   
    }
`;

const Help = styled.p`
    margin: 20px 0;
    font-size: 14px;
`;

const Login = () => {
    const { data } = usePreLoginQuery();

    // intentionally not showing the loader here

    return (
        <OuterWrap>
            <Wrap>
                <Logo src={SkiftLogo} alt="Skift" />
                <TransactionContextProvider value={{}}>
                    <LoginCreateAccount 
                        allowNewAccounts={false} 
                        passwordOnly={false} 
                        newAccountHeading="Enter your email address to get started"
                        existingAccountHeading=""
                    />
                    {data?.has_ip_match && <IPLogin><Link to="/ip-gateway">Login with IP Whitelisting</Link></IPLogin>}
                    <Recaptcha />
                </TransactionContextProvider>
            </Wrap>
            <Help>Need help? Email <a href="mailto:support@skift.com">support@skift.com</a>.</Help>
        </OuterWrap>
    )
};

export default Login;
