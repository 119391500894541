import React, { useState } from 'react';
import styled from 'styled-components';

const Heading = styled.h3`
    cursor: pointer;

    position: relative;
    padding-left: 30px;

    &::before {
        content: '';
        width: 10px;
        height: 10px;
        border: 1px solid #000;
        border-top: none;
        border-left: none;
        transform-origin: center center;
        position: absolute;
        top: 7px;
        left: 2px;
        transform: rotate(-45deg);
        transition: transform 0.3s;
    }
`;
const Wrap = styled.div`
    + * {
        margin-top: 30px;
    }
    
    ${props => {
        if (props.open) {
            return `
                ${Heading}::before {
                    transform: rotate(45deg);
                }
            `;
        }
    }}
`;
const Content = styled.div``;

export const CollaspableSection = ({ heading, initialState, children }) => {
    const [open, setOpen] = useState(initialState);

    return (
        <Wrap open={open}>
            <Heading onClick={() => setOpen(!open)}>{heading}</Heading>
            {open && <Content>{children}</Content>}
        </Wrap>
    );
};
