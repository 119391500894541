import React, { useState } from 'react';
import { DEFAULT_ERROR_MESSAGE, PRIVACY_POLICY_LINK, SUBSCRIPTION_TOS_LINK, TRANSACTION_TYPES } from 'utils/constants';
import { useSelector } from 'react-redux';

import { useContentItem, useTransactionTotal } from 'hooks';
import { 
    TransactionWrapper, 
    TransactionBody, 
    TransactionRail, 
    TransactionProcessing, 
    TransactionErrors,
    DisclaimerText,
    FullScreenLoader, 
    FullScreenMessage, 
    FullHeightContainer, 
    TransactionMessage,
    SubmitTransactionBtn
} from 'ui';

import { TransactionContextProvider } from 'components/TransactionContext';

import AccountForm from 'components/AccountForm';
import ProfileForm from 'components/ProfileForm';
import RecurlyBilling from 'components/RecurlyBilling';
import ScrollToActiveSection from 'components/ScrollToActiveSection';

import TransactionComplete from './TransactionComplete';
import SupportFooter from 'components/SupportFooter';
import Recaptcha from 'components/Recaptcha';
import IncompatiblePlanWarning from './IncompatiblePlanWarning';
import MultiSeatQuantity from './MultiSeatQuantity';
import TransactionCompleteMultiUser from './TransactionCompleteMultiUser';
import PurchaseTransactionSummary from './PurchaseTransactionSummary';

const Main = () => {
    const { data: item, isLoading, error: contentError } = useContentItem();
    const [accountCreated, setAccountCreated] = useState(false);
    const [profileCompleted, setProfileCompleted] = useState(false);
    const transactionComplete = useSelector(state => state.transaction.complete);
    const transactionType = useSelector(state => state.transaction.type);
    const isSubscription = transactionType === TRANSACTION_TYPES.SUBSCRIPTION;
    const isMultiUser = transactionType === TRANSACTION_TYPES.MULTI_USER_SUBSCRIPTION;

    const { data: totalData } = useTransactionTotal();
    const isFreeTransaction = totalData && !totalData.require_payment;

    if (isLoading) {
        return <FullScreenLoader text="Loading Item" />
    }
    
    if (!item || contentError) {
        return <FullScreenMessage error heading="Error loading item">{contentError ? (contentError?.data?.error?.message || DEFAULT_ERROR_MESSAGE) : 'Item not found'}</FullScreenMessage>;
    }

    if (item?.details?.already_purchased) {
        return <TransactionMessage heading="You already purchased this" body={`Click "Start Reading" below to sign in`} />;
    }

    if (transactionComplete) {
        if (isMultiUser) {
            return <TransactionCompleteMultiUser />;
        }

        return <TransactionComplete />;
    }

    const context = {
        submitBtnText: isSubscription ? 'Subscribe' : 'Buy',
        banSharedEmails: !isMultiUser
    };

    return (
        <TransactionContextProvider {...context}>
            {isSubscription && <IncompatiblePlanWarning />}
            <TransactionWrapper>
                <TransactionRail>
                    <PurchaseTransactionSummary />
                </TransactionRail>
                <TransactionBody>
                    <TransactionErrors />
                    <AccountForm onAccountCreated={() => setAccountCreated(true)} />
                    <ProfileForm 
                        showContinueBtn={!isFreeTransaction}
                        inactive={!accountCreated || profileCompleted} 
                        onProfileCompleted={profile => setProfileCompleted(profile)} 
                        onClick={() => profileCompleted && setProfileCompleted(false)} 
                    />
                    <FullHeightContainer id="step-two">
                        {isMultiUser && <MultiSeatQuantity inactive={!(accountCreated && profileCompleted)} />}
                        <RecurlyBilling inactive={!(accountCreated && profileCompleted)} />
                        <SubmitTransactionBtn disabled={!(accountCreated && profileCompleted)} />
                        <Recaptcha />
                        <DisclaimerText>
                            <p>Sharing of login info or use of team/group emails to subscribe are prohibited, single user email only.</p>
                            <p>By submitting this form, you agree to receive email communication from Skift.</p>
                            <p>By purchasing, you agree to our <a href={SUBSCRIPTION_TOS_LINK} target="_blank" rel="noopener noreferrer">Terms of Service</a> and our <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">privacy policy</a>.</p>
                            <p>Skift, Inc. takes credit card data security very seriously. For that reason, we use <a href="https://www.braintreepayments.com/" target="_blank" rel="noopener noreferrer">Braintree</a> to securely handle credit card information. Braintree is a Validated Level 1 PCI DSS Compliant Service Provider.</p>
                        </DisclaimerText>
                        <SupportFooter />
                    </FullHeightContainer>
                </TransactionBody>
                <ScrollToActiveSection accountCreated={accountCreated} profileCompleted={profileCompleted} />
            </TransactionWrapper>
            <TransactionProcessing />
        </TransactionContextProvider>
    );
};

export default Main;
