import React from 'react';
import { useGetPurchasesQuery } from 'store/features/user-api';
import { Button, Loader, BoxedItem } from 'ui';
import { ucFirst } from 'utils/ucFirst';
import DashboardFrame from './DashboardFrame';
import TransactionsPanel from './TransactionsPanel';

const ContentScreen = () => {
    const { data: transactions, isFetching } = useGetPurchasesQuery();

    if (isFetching) {
        return <DashboardFrame><Loader small>Loading content</Loader></DashboardFrame>;
    }

    return (
        <DashboardFrame breadcrumbs={[['Home', '/'], ['Content', null]]}>
            <h2>Content Purchases</h2>
            {!(transactions?.content || []).length && <p>You haven't purchased anything yet</p>} 
            {(transactions?.content || []).map(c => {
                return (
                    <BoxedItem
                        key={c.id}
                        heading={c.name}
                        category={ucFirst(c.type)}
                        RightChildren={<Button as="a" size="small" href={c.link} black>Read&nbsp;Now</Button>}
                    />
                );
            })}
            <TransactionsPanel />
        </DashboardFrame>
    )
};

export default ContentScreen;
