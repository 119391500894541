import React, { useState, useEffect, useMemo } from "react";
import { Radio } from "ui";
import RecurlyBillingRoot from "./RecurlyBillingRoot";
import { useDispatch } from "react-redux";
import { setPaymentToken } from "store/features/transaction";
import { useGetBillingInfoQuery } from 'store/features/user-api';

const PreviousPaymentMethod = () => {
    const dispatch = useDispatch();
    const [useExisting, setUseExisting] = useState(true);
    const { data } = useGetBillingInfoQuery(null);

    const billingInfo = useMemo(() => data?.billingInfo, [data]);

    useEffect(() => {
        dispatch(setPaymentToken(useExisting ? 'usePreviousMethod' : null));
    }, [dispatch, useExisting]);

    const handleChange = value => {
        setUseExisting(value);
    };

    const cardLabel = () => {
        if (billingInfo.paymentMethod.cardType) {
            return `${billingInfo.firstName} ${billingInfo.lastName}'s ${billingInfo.paymentMethod.cardType} ending in ${billingInfo.paymentMethod.lastFour}`;
        }

        return `${billingInfo.firstName} ${billingInfo.lastName}'s previously used method`;
    };
 
    return (
        <>
            <Radio 
                id="use-existing-radio"
                label={cardLabel()}
                checked={useExisting} 
                name="useExisting" 
                value={true} 
                onChange={() => handleChange(true)}
            />
            <Radio 
                id="new-method-radio"
                label="New Payment Method"
                checked={!useExisting} 
                name="useExisting" 
                value={false} 
                onChange={() => handleChange(false)}
            />
            {
            !useExisting && <RecurlyBillingRoot />
            }
        </>
    );
};

export default PreviousPaymentMethod;
