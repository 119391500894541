import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";

import { ACCEPTED_CURRENCIES, DEFAULT_ERROR_MESSAGE, PROCESS_TRANSACTION_ENDPOINTS } from 'utils/constants';
import { paramsFromQuerystring } from 'utils/paramsFromQuerystring';
import { generateTransactionPayload } from "utils/generate-transaction-payload";

import { getAccessToken } from "store/add-auth-header";
import { countryData } from 'shared/country-region-data';

export const submitTransaction = createAsyncThunk('transaction/submitTransaction', async (p, thunkAPI) => {
    const state = thunkAPI.getState();
    const transactionType = state.transaction.type;

    try {
        const payload = generateTransactionPayload(state);

        const config = {
            headers: {}
        };

        const token = await getAccessToken();
        if (token) {
            config.headers.authorization = `Bearer ${token}`;
        }
        
        const resp = await axios.post(`${process.env.REACT_APP_SKIFT_API_DOMAIN}/api/${PROCESS_TRANSACTION_ENDPOINTS[transactionType]}`, payload, config);
        return resp?.data;
    } catch (err) {
        const errorMessage = err?.response?.data?.error?.message || DEFAULT_ERROR_MESSAGE;
        throw new Error(errorMessage);
    }
});

const addQueryStringVarsToState = (state, params) => {
    if (params['currency']) {
        params['currency'] = (params['currency'] || '').toUpperCase();
        
        if (ACCEPTED_CURRENCIES.includes(params['currency'])) {
            state.currency = params['currency'].toUpperCase();
        }
    }

    if (params['promo']) {
        state.promo = params['promo'];
    }

    if (params['badge_type']) {
        state.badgeType = params['badge_type'];
    }
    
    if (params['seats'] && !isNaN(parseInt(params['seats'])) && parseInt(params['seats']) > 1) {
        state.seats = parseInt(params['seats']);
    }

    return state;
};

const initialState = addQueryStringVarsToState({ 
    currency: 'USD', 
    quantity: 1,
    billingAddress: {}, 
    profile: {},
    optIns: { dataConsent: true, filmingConsent: true }
}, paramsFromQuerystring());

const transaction = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setTransactionType: (state, action) => { state.type = action.payload },
        setItemId: (state, action) => { state.itemId = action.payload },
        setItemName: (state, action) => { state.itemName = action.payload },
        setCurrency: (state, action) => { state.currency = action.payload },
        setAmount: (state, action) => { state.amount = state.total = action.payload },
        setQuantity: (state, action) => { state.quantity = action.payload },
        setMultiSeatAmount: (state, action) => {
            delete state.seats;
            state.quantity = action.payload.quantity;
            state.amount = state.total = action.payload.amount;
        },
        setPromoCode: (state, action) => { state.promo = action.payload },
        setBadgeType: (state, action) => { state.badgeType = action.payload },
        setAccessCode: (state, action) => { state.accessCode = action.payload },
        setGroupCode: (state, action) => { state.groupCode = action.payload },
        setPropertiesFromUrl: (state, action) => addQueryStringVarsToState(state, paramsFromQuerystring(action.payload)),
        setBillingAddress: (state, action) => {
            if (!Object.keys(action.payload).includes('card_valid')) {
                state.billingAddressTouched = true;
            }
            state.billingAddress = {
                ...state.billingAddress,
                ...action.payload
            };
        },
        setProfile: (state, action) => {
            state.profile = {
                ...state.profile,
                ...action.payload
            };

            if (!state.billingAddressTouched) {
                // billing address isn't set
                // const nextBillingAddress = Object.keys(action.payload).reduce((a, k) => {

                //     a[`billing_${k}`] = action.payload[k];
                //     return a;
                // }, {});

                const nextBillingAddress = {};
                if (action.payload.country) {
                    nextBillingAddress.billing_country = countryData.find(country => country.text === action.payload.country)?.key;
                }
                if (action.payload.zip) {
                    nextBillingAddress.billing_zip = action.payload.zip;
                }

                state.billingAddress = {
                    ...state.billingAddress,
                    ...nextBillingAddress
                };
            }
        },
        setOptins: (state, action) => {
            state.optIns = {
                ...state.optIns,
                ...action.payload
            };
        },
        setPaymentToken: (state, action) => { state.paymentToken = action.payload },
        setRecaptchaToken: (state, action) => { state.recaptchaToken = action.payload },
        setError: (state, action) => { state.error = action.payload },
        resetTransaction: () => initialState
    },
    extraReducers: builder => {
        builder.addCase(submitTransaction.pending, (state, action) => {
            state.error = null;
            state.processing = true;
        });

        builder.addCase(submitTransaction.fulfilled, (state, action) => {
            state.processing = false;
            state.complete = true;
            state.transactionResponse = action.payload;
        });

        builder.addCase(submitTransaction.rejected, (state, action) => {
            state.processing = false;
            state.error = action.error.message;
        });
    }
});

export const { 
    setTransactionType, 
    setItemId,
    setItemName,
    setCurrency, 
    setAmount,
    setQuantity,
    setMultiSeatAmount,
    setPromoCode, 
    setAccessCode,
    setBadgeType,
    setGroupCode,
    setPropertiesFromUrl,
    setBillingAddress, 
    setProfile, 
    setOptins,
    setPaymentToken, 
    setRecaptchaToken,
    setError,
    resetTransaction
} = transaction.actions;

export const name = transaction.name;

export default transaction.reducer;
