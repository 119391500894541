import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Message, Loader } from 'ui';
import LogoutLink from 'components/LogoutLink';
import { useLoggedInUser } from 'hooks/useLoggedInUser';
import { useDispatch } from 'react-redux';
import { maybeSetTrackingEmail } from 'store/features/tracking';
import { twoPropsOrOne } from "utils/two-props-or-one";

const Wrap = styled.div`
    * { margin: 0; }
`;
const Name = styled.h2`
    font-size: 50px;
    // margin-bottom: 10px;

    ${props => props.large && `font-size: 18px;`}
`;
const JobLine = styled.h3`
    font-size: 18px;
    font-family: ${props => props.theme.bodyFont};
    font-weight: bold;
`;

const MiscAttr = styled.h4`
    font-size: 16px;
    color: ${props => props.theme.grayText};
    font-family: ${props => props.theme.bodyFont};
    margin-top: 2px;
`;

const LoggedIn = () => {
    const { data: profile, error, isFetching } = useLoggedInUser();
    const dispatch = useDispatch();

    useEffect(() => {
        if (profile && profile.email) {
            dispatch(maybeSetTrackingEmail(profile.email));
        }
    }, [dispatch, profile]);

    if (isFetching) {
        return <Loader small>Loading profile</Loader>;
    }
    
    if (error || !profile) {
        return <Message error>Error Loading profile</Message>;
    }

    if (!profile.first_name && !profile.last_name && !profile.company) {
        return (
            <Wrap>
                <h1>Welcome to Skift</h1>
                <p>An account has been created for {profile.email}. You are now signed in.</p>
                <LogoutLink style={{ marginTop: '15px', display: 'block', fontSize: '14px' }} />       
            </Wrap>
        );
    }

    const jobLine = twoPropsOrOne(profile.job, profile.company, ' at ');
    const nameLine = twoPropsOrOne(profile.first_name, profile.last_name, ' ', profile.email);

    const firstPartOfNameLine = nameLine.split(' ')[0];

    return (
        <>
            <h1>Welcome back</h1>
            <Wrap>
                <Name large={firstPartOfNameLine.length > 15}>{nameLine}</Name>
                {jobLine && <JobLine>{jobLine}</JobLine>}
                {profile.industry && <MiscAttr>{profile.industry}</MiscAttr>}
                <LogoutLink style={{ marginTop: '15px', display: 'block', fontSize: '14px' }} />       
            </Wrap>
        </>
    );
};

export default LoggedIn;
