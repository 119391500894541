import React from 'react';
import { FormGroup, FormLabel, PasswordInput } from 'ui';

const PasswordField = props => {
    return (
        <FormGroup>
            <PasswordInput name="password" {...props} />
            <FormLabel>Password</FormLabel>
        </FormGroup>
    );
};

export default PasswordField;
