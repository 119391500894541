import React from "react";
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { Message, Loader } from 'ui';
import { useSelector } from 'react-redux';
import { useBraintreeDeviceData } from 'hooks/useBraintreeDeviceData';
import MethodPicker from "./MethodPicker";

const RecurlyBillingRoot = () => {
    const currency = useSelector(state => state.transaction.currency);

    const { isLoading, error, deviceData } = useBraintreeDeviceData();

    if (isLoading || !deviceData) {
        return <Loader small>Loading payment form</Loader>;
    }

    if (error) {
        return <Message error>Error loading payment form</Message>;
    }
    
    return (
        <RecurlyProvider
            publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}
            currency={currency}
            fraud={{ braintree: { deviceData } }}
            required={['cvv', 'postal_code', 'billing_country', 'billing_state']}
        >
            <Elements>
                <MethodPicker />
            </Elements>
        </RecurlyProvider>
    );
};

export default RecurlyBillingRoot;
