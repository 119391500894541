import React from 'react';
import { TransactionMessage } from 'ui';


const TransactionComplete = () => ( 
    <TransactionMessage heading="Your Credits Have Been Claimed">
        <p>You have successfully claimed credit for this item.</p>
    </TransactionMessage>
);

export default TransactionComplete;
