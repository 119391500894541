import React, { useMemo } from 'react';
import styled from 'styled-components';
import { MobileOnlyShare, ShareButton, ShareFlag } from 'ui';

const Wrap = styled.div`
    padding: 30px 0;
    margin: 50px 0;
    border: 1px solid #eaeaea;

    border-right: none;
    border-left: none;

    display: flex;
    justify-content: center;

    ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding-left: 0px;
    }

    ul li {
        margin: 0 10px;
        display: block;
        line-height: 0;
    }

    li {
        text-align: center;
    }
`;

const EventShare = ({ socialUrl = null }) => {

    const encodedSocialUrl = useMemo(() => encodeURIComponent(socialUrl), [socialUrl]);

    if (!encodedSocialUrl) return

    const openInNewTab = (url, width, height) => {
        window.open(
            `${url}`,
            'mywindow',
            `width=${width}, height=${height}, menubar=no, resizable=no, scrollbars=no, status=no, toolbar=no, titlebar=no`
        );
    };

    return (
        <Wrap>
            <ul>
                <li>
                    <ShareFlag>Share</ShareFlag>
                </li>
                <li>
                    <ShareButton
                        role='link'
                        onClick={() =>
                            openInNewTab(
                                `http://www.linkedin.com/shareArticle?mini=true&url=${encodedSocialUrl}`,
                                555,
                                450
                            )
                        }
                    >
                        <img
                            src='https://skift.com/wp-content/themes/skift-2021/assets/img/social-icons/linkedin.svg'
                            data-src='https://skift.com/wp-content/themes/skift-2021/assets/img/social-icons/linkedin.svg'
                            width='24'
                            height='24'
                            alt='LinkedIn Logo'
                        />
                    </ShareButton>
                </li>
                <li>
                    <ShareButton
                        role='link'
                        onClick={() =>
                            openInNewTab(
                                `https://twitter.com/intent/tweet?url=${encodedSocialUrl}`,
                                555,
                                275
                            )
                        }
                    >
                        <img
                            src='https://skift.com/wp-content/themes/skift-2021/assets/img/social-icons/twitter.svg'
                            data-src='https://skift.com/wp-content/themes/skift-2021/assets/img/social-icons/twitter.svg'
                            width='24'
                            height='24'
                            alt='Twitter Logo'
                        />
                    </ShareButton>
                </li>
                <li>
                    <ShareButton
                        role='link'
                        onClick={() =>
                            openInNewTab(
                                `http://www.facebook.com/share.php?u=${encodedSocialUrl}`,
                                555,
                                350
                            )
                        }
                    >
                        <img
                            src='https://skift.com/wp-content/themes/skift-2021/assets/img/social-icons/facebook.svg'
                            data-src='https://skift.com/wp-content/themes/skift-2021/assets/img/social-icons/facebook.svg'
                            width='24'
                            height='24'
                            alt='Facebook Logo'
                        />
                    </ShareButton>
                </li>
                <MobileOnlyShare>
                    <a
                        href={`whatsapp://send?text=${encodedSocialUrl}`}
                        data-action='share/whatsapp/share'
                    >
                        <img
                            src='https://skift.com/wp-content/themes/skift-2021/assets/img/social-icons/whatsapp.svg'
                            data-src='https://skift.com/wp-content/themes/skift-2021/assets/img/social-icons/whatsapp.svg'
                            width='24'
                            height='24'
                            alt='Whatsapp Logo'
                        />
                    </a>
                </MobileOnlyShare>
                <li>
                    <ShareButton
                        onClick={e => {
                            window.location.href = `mailto:?to=&body=${encodedSocialUrl}`;
                            e.preventDefault();
                        }}
                    >
                        <img
                            src='https://skift.com/wp-content/themes/skift-2021/assets/img/social-icons/mail.svg'
                            data-src='https://skift.com/wp-content/themes/skift-2021/assets/img/social-icons/mail.svg'
                            width='24'
                            height='24'
                            alt='Email icon'
                        />
                    </ShareButton>
                </li>
            </ul>
        </Wrap>
    );
};

export default EventShare;
