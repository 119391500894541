import { createSlice } from '@reduxjs/toolkit';

const randomString = (length = 30) => {
    // Declare all characters
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    // Pick characers randomly
    let str = '';
    for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return str;
};

const keys = ['newAccount'];
const initialState = keys.reduce((a, k) => { a[k] = randomString(); return a; }, {});
const reducers = keys.reduce((a, k) => {
    a[`rotate${k.charAt(0).toUpperCase() + k.slice(1)}CacheKey`] = state => { state[k] = randomString(); };
    return a;
}, {});

const mutationCacheKeys = createSlice({
    name: 'mutationCacheKeys',
    initialState,
    reducers
});

export const { rotateNewAccountCacheKey } = mutationCacheKeys.actions;
export default mutationCacheKeys.reducer;
