import { TransactionContext } from 'components/TransactionContext';
import React, { useContext } from 'react';
import { FullScreenLoader } from './FullScreenLoader';

export const TransactionProcessing = () => {
    const { processing } = useContext(TransactionContext);

    if (!processing) {
        return null;
    }

    return <FullScreenLoader text="Processing transaction" />;
};
