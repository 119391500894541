export const querystringFromArray = arr => {
    if (!arr) return '';
    
    return Object.keys(arr).reduce((a, key) => {
        if (arr[key]) {
            a.push(`${key}=${arr[key]}`)
        }
        return a;
    }, []).join('&');
};
