import React, { useContext, useEffect, useState } from 'react';

import DropIn from 'braintree-web-drop-in-react';
import { useBraintreeToken } from 'hooks/useBraintreeToken';
import { Loader, Message } from 'ui';
import { TransactionContext } from 'components/TransactionContext';
import { useContentItem, useTransactionTotal } from 'hooks';
import { useSelector } from 'react-redux';

const BraintreeDropin = () => {
    const [instance, setInstance] = useState();
    const [tokenizeError, setTokenizeError] = useState();
    const { isLoading: isLoadingToken, token, error: tokenError } = useBraintreeToken();
    const { setTokenize } = useContext(TransactionContext);
    const { data: total } = useTransactionTotal();
    const { data: item, isFetching: isFetchingItem } = useContentItem();
    const currency = useSelector(state => state.transaction.currency);
    const [cardType, setCardType] = useState();
    
    useEffect(() => {
        if (instance) {
            setTokenize(async () => {
                setTokenizeError(null);
                try {
                    const { nonce, deviceData } = await instance.requestPaymentMethod();
                    return { nonce, deviceData };
                } catch(err) {
                    setTokenizeError(err);
                    return null;
                }
            });

            // instance.on('card:cardTypeChange', function (event) {
            //     console.log({ event });
            // });
        }

        return function cleanup() {
            setTokenize(null);
        };

    }, [instance, setTokenize, setTokenizeError]);

    useEffect(() => {
        if (instance) {
            instance.on('card:cardTypeChange', function (event) {
                if (event.cards.length) {
                    setCardType(event.cards[0].type);
                }
            });
        }

    }, [instance, setCardType]);

    if (isLoadingToken || isFetchingItem) {
        return <Loader small>Loading billing form</Loader>;
    }

    if (tokenError) {
        return <Message error heading="Error loading billing form">Error loading billing form</Message>;
    }

    const dropInStyle = instance ? { marginTop: '-30px '} : { display: 'none' };

    return (
        <>
            {tokenizeError && <Message error>{tokenizeError.message}</Message>}
            {!instance && <Loader small>Loading billing form</Loader>}
            {cardType === 'american-express' && currency !== 'USD' && <Message warning>We do not accept American Express for currencies other than USD. We apologize for the inconvenience.</Message>}
            <div style={dropInStyle}>
                <DropIn
                    options={
                        { 
                            authorization: token, 
                            dataCollector: { kount: true },
                            paypal: { flow: 'vault', commit: false, currency },
                            applePay: {
                                displayName: 'Skift',
                                paymentRequest: {
                                    total: {
                                        label: item?.name || 'Skift',
                                        amount: total?.total
                                    },
                                    requiredBillingContactFields: ["postalAddress"]
                                }
                            }
                        }
                    }
                    onInstance={instance => setInstance(instance)}
                />
            </div>
            {/* <Button style={{ marginTop: '10px' }} disabled={!instance || processing} loading={processing} onClick={handleSubmit} fluid black size="large">{submitBtnText}</Button> */}
        </>
    );

};

export default BraintreeDropin;
