import React, { useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { useContentItem } from 'hooks';
import { 
    TransactionMessage
} from 'ui';

import ExclusiveEventMessage from './ExclusiveEventMessage';
import AccessCodeInput from './AccessCodeInput';
import { paramsFromQuerystring } from 'utils/paramsFromQuerystring';

const AccessCheck = ({ children }) => {
    const { data: item } = useContentItem();
    const [enterAccessCodeScreen, setEnterAccessCodeScreen] = useState();
    const validAccessCode = useSelector(state => state.transaction.accessCode);
    const { access_code: accessCodeQS } = paramsFromQuerystring();

    const itemDetails = item?.details || {};

    const forceAccessCodeInput = useMemo(() => {
        return enterAccessCodeScreen || (accessCodeQS && itemDetails.require_access_code);
    }, [enterAccessCodeScreen, accessCodeQS, itemDetails.require_access_code]);

    if (itemDetails.already_purchased) {
        return <TransactionMessage heading="You already registered for this event" />;
    }
    
    if (!itemDetails.reg_open) {
        return (
            <TransactionMessage heading="Registration closed" showDashboardLink={false}>
                <p>Registration for this event is closed.</p>
            </TransactionMessage>
        );
    }

    // if event is exclusive or access codes do stuff
    const isExclusive = (itemDetails.exclusive_for_plans || []).length > 0;
    const requireAccessCode = itemDetails.require_access_code;

    if (isExclusive && itemDetails.no_access_missing_exclusive_plan && !forceAccessCodeInput) {
        // The first screen the user sees when they are not allowed to register for an exclusive event
        return <ExclusiveEventMessage enterAccessCode={() => setEnterAccessCodeScreen(true)} />;
    } else if (isExclusive && !itemDetails.no_access_missing_exclusive_plan) {
        // check if the user has access via subscription
        return children;
    } else if (requireAccessCode && !validAccessCode) {
        // check if an access code is required and if the user has entered one
        return <AccessCodeInput accessCodeQS={accessCodeQS} />;
    }


    return children;
};

export default AccessCheck;
