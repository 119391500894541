import { useContentItem } from 'hooks';
import React from 'react';
import { Button, Message } from 'ui';

const Upsell = () => {
    const { data: item } = useContentItem();

    const upsellPlan = item?.details?.upsell_plan;
    const upsellHeading = item?.details?.upsell_heading;
    const upsellText = item?.details?.upsell_text;

    if (!upsellPlan || !upsellText) {
        return null;
    }

    return (
        <Message light heading={upsellHeading} jumbotron>
            <p>{upsellText}</p>
            <Button fluid onClick={() => window.location.href = `/subscribe/${upsellPlan.plan_code}${window.location.search}`}>Upgrade</Button>
        </Message>
    );
};

export default Upsell;
