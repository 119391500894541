import React, { useMemo } from 'react';
import { useGetPurchasesQuery } from 'store/features/user-api';

const NothingPurchasedYet = () => {
    const { data: transactions } = useGetPurchasesQuery();

    // flatten all transactions into one array so we can determine if nothing is displayed
    const allItems = useMemo(() => {
        return Object.keys(transactions || {}).reduce((a, key) => {
            a.push(...(transactions[key] || []));
            return a;
        }, []);
    }, [transactions]);

    if (allItems.length) {
        return null;
    }

    return <h2>You haven't purchased anything yet</h2>;
};

export default NothingPurchasedYet;
