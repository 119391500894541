import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useUpgradeSubscriptionMutation } from 'store/features/user-api';
import { Button, Message } from 'ui';

const Upsell = ({ subscription }) => {
    const upsellPlan = subscription?.plan?.upsell_plan;
    const upsellHeading = subscription?.plan?.upsell_heading;
    const upsellText = subscription?.plan?.upsell_text;

    const [upgradeSubscription, { isLoading, error }] = useUpgradeSubscriptionMutation();

    useEffect(() => {
        if (error) {
            toast.error('Error upgrading subscription. Contact Support to cancel.');
        }
    }, [error]);


    const handleClick = useCallback(() => {
        if (window.confirm(`Are you sure you want to upgrade this subscription?\n\nYour subscription will be canceled and the prorated amount will be credited towards your new subscription.`)) {
            upgradeSubscription(subscription.id);
        }
    }, [upgradeSubscription, subscription]);


    if (!upsellPlan || !upsellText) {
        return null;
    }

    return (
        <Message light jumbotron heading={upsellHeading}>
            <p>{upsellText}</p>
            <Button loading={isLoading} fluid onClick={handleClick}>Upgrade</Button>
        </Message>
    );
};

export default Upsell;
