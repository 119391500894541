import React from 'react';
import { Message } from 'ui';
import { useLoggedInUser } from 'hooks/useLoggedInUser';

const SharingWarningBanner = () => {
    const { data: profile } = useLoggedInUser();

    if (!profile || !profile?.show_sharing_banner) {
        return null;
    }

    return (
        <Message heading="This account has been flagged as it may be shared with multiple people" error>
            <p>Our T&Cs clearly state 'sharing of login info or use of team/group emails to subscribe are prohibited.' We have attempted to reach you multiple times but have not received a response and will therefore be terminating this subscription with no refund.</p>

            <p>If you would like to explore group subscription options or have questions, please reach out to <a href="mailto:support@skift.com">support@skift.com</a></p>
        </Message>
    );
};

export default SharingWarningBanner;
