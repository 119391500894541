import React, { useCallback, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';

import { Button, FormGroup, FormLabel, LinkButton, Message } from 'ui';
import { useAuth0 } from 'components/auth0/Auth0Context';
import { CoreInput } from 'ui/Input/CoreInput';
import { STAGES } from '.';

const VerifyCode = ({ email, reset, setStage, allowPassword }) => {
    const [code, setCode] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState();
    const [resendAllowed, setResendAllowed] = useState(false);
    const { verifyCode, startPasswordless } = useAuth0();

    const submitCode = useCallback(() => {
        (async () => {
            setIsSending(true);
           
            try {
                await verifyCode(email, code);
            } catch (err) {
                let errorMessage = err.message;
                if (err.message === 'Wrong email or verification code.') {
                    errorMessage = 'The code you entered is incorrect.';
                }
                
                setError(errorMessage);
                setCode('');
            }
            setIsSending(false);
        })();
    }, [code, email, verifyCode]);
  
    const handleFormSubmit = useCallback(e => {
        e.preventDefault();
       
        submitCode()
    }, [submitCode]);

    useEffect(() => {
        if (code.length === 6) {
            submitCode();
        }
    }, [code, submitCode]);

    const resendCode = async () => {
        if (!resendAllowed) {
            return;
        }

        setIsSending(true);
        setResendAllowed(false);
        await startPasswordless(email);
        setIsSending(false);

        setTimeout(() => {
            setResendAllowed(true);
        }, 30000);
    };

    const handlePasswordMode = e => {
        e.preventDefault();
        setStage(STAGES.ENTER_PASSWORD);
    };

    return (
        <form onSubmit={handleFormSubmit}>
            {error && <Message error>{error}</Message>}
            <FormGroup>
                <FormLabel>Enter the code sent to {email} below</FormLabel>
                <OtpInput
                    disabled={isSending}
                    value={code}
                    onChange={setCode}
                    numInputs={6}
                    renderSeparator={<span style={{ width: '10px' }} />}
                    inputType="tel"
                    renderInput={(props) => <CoreInput {...props} />}
                    inputStyle={{ width: '40px', height: '40px', fontSize: '20px', textAlign: 'center', padding: '15px 10px' }}
                    containerStyle={{ marginTop: '10px' }}
                />
            </FormGroup>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button $loading={isSending} black>Login</Button>
                <LinkButton onClick={reset} disabled={isSending} style={{ marginLeft: '10px' }}>Start over</LinkButton>
            </div>
            {(resendAllowed || allowPassword) && <div style={{ marginTop: '10px' }}>
                {resendAllowed && <LinkButton onClick={resendCode} disabled={isSending} style={{ marginTop: '10px' }}>Resend code</LinkButton>}
                {resendAllowed && allowPassword && <> &bull; </>}
                {allowPassword && <><LinkButton onClick={handlePasswordMode} disabled={isSending} style={{ marginRight: '10px' }}>Use a password instead</LinkButton></>}
            </div>}
            <p style={{ fontSize: '12px', marginBottom: 0 }}><em>If you do not see the confirmation email in your inbox, please check promotions or junk folders.</em></p>
        </form>
    );
};

export default VerifyCode;
