import React, { useEffect, useMemo, useState, useCallback } from "react";
import { toast } from 'react-toastify';
import { useChangeEmailMutation } from 'store/features/user-api';
import { useLoggedInUser } from "hooks/useLoggedInUser";
import EmailField from "components/LoginCreateAccount/EmailField";
import { Button, LinkButton, Loader, Message } from "ui";
import { validateEmail } from "utils/validateEmail";
import SummaryPane from "../SummaryPane";

const Properties = () => {
    const { data: profile, isFetching } = useLoggedInUser();

    if (isFetching) {
        return <Loader small>Loading profile</Loader>;
    }

    return (
        <p>{profile?.email}</p>
    );
};

const EditForm = ({ setEditing }) => {
    const { data: profile } = useLoggedInUser();
    const [uiError, setUIError] = useState();
    const [nextEmail, setNextEmail] = useState(profile.email);
    const [saveEmail, { isLoading: isUpdating, error: updateError, isSuccess }] = useChangeEmailMutation();

    const error = useMemo(() => uiError || updateError?.data?.error?.message, [uiError, updateError]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('Email updated successfully');
            setEditing(false);
        }
    }, [isSuccess, setEditing]);

    const handleSave = useCallback(e => {
        e.preventDefault();

        if (!validateEmail(nextEmail)) {
            return setUIError('Enter a valid email address');
        }
        
        setUIError(null);
        saveEmail(nextEmail);
    }, [nextEmail, setUIError, saveEmail]);

    return (
        <>
            {error && <Message error>{error}</Message>}
            <EmailField disabled={isUpdating} value={nextEmail} onChange={e => setNextEmail(e.target.value)} />
            <Button loading={isUpdating} black fluid onClick={handleSave}>Save</Button>
            {!isUpdating && <p style={{ textAlign: 'center' }}><LinkButton onClick={() => setEditing(false)}>Cancel</LinkButton></p>}
        </>
    )
};

const Email = () => {
    const [editing, setEditing] = useState();

    return (
        <SummaryPane heading="Email" onClick={() => setEditing(!editing)} buttonText={editing ? 'Cancel' : 'Edit'}>
            {editing ? <EditForm setEditing={setEditing} /> : <Properties />}
        </SummaryPane>
    );
};

export default Email;
