import React from 'react';
import { useSelector } from 'react-redux';
import { Message } from 'ui';

const AmexWarning = ({ cardType }) => {
    const currency = useSelector(state => state.transaction.currency);

    if (!(currency !== 'USD' && cardType === 'american_express')) {
        return null;
    }

    return <Message warning>We do not accept American Express for currencies other than USD. We apologize for the inconvenience.</Message>;
};

export default AmexWarning;
