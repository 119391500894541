import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const transaction = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setBraintreeToken: (state, action) => { state.token = action.payload },
        setBraintreeDeviceData: (state, action) => { state.deviceData = action.payload }
    }
});

export const { setBraintreeToken, setBraintreeDeviceData } = transaction.actions;
export default transaction.reducer;
