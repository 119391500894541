import React from 'react';
import styled from 'styled-components';
import { useGetPurchasesQuery } from 'store/features/user-api';
import { FullScreenLoader, FullScreenMessage } from 'ui';
import { DEFAULT_ERROR_MESSAGE } from 'utils/constants';
import 'react-toastify/dist/ReactToastify.css';

import Main from './Main';
import Header from './Header';

const HeaderOffset = styled.div`
    height: 65px;
`;

const DashboardFrame = ({ children, breadcrumbs }) => {
    const { isFetching, error } = useGetPurchasesQuery();

    if (error) {
        return <FullScreenMessage error heading="Error loading dashboard">{error?.data?.error?.message || DEFAULT_ERROR_MESSAGE}</FullScreenMessage>;
    }

    if (isFetching) {
        return <FullScreenLoader text="Loading dashboard" />;
    }

    return (
        <>
            <Header />
            <HeaderOffset />
            <Main children={children} breadcrumbs={breadcrumbs} />
        </>
    );
};

export default DashboardFrame;
