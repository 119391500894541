import React from 'react';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { TransactionContextProvider } from 'components/TransactionContext';
import { useBraintreeDeviceData } from 'hooks/useBraintreeDeviceData';
import { Loader, Message } from 'ui';
import ChangeBillingForm from './ChangeBillingForm';

const ChangeBillingRoot = props => {
    const { isLoading, error, deviceData } = useBraintreeDeviceData();

    if (isLoading || !deviceData) {
        return <Loader small>Loading payment form</Loader>;
    }

    if (error) {
        return <Message error>Error loading payment form</Message>;
    }

    return (
        <TransactionContextProvider>
            <RecurlyProvider
                publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}
                currency="USD"
                fraud={{ braintree: { deviceData } }}
                required={['cvv', 'postal_code', 'billing_country', 'billing_state']}
            >
                <Elements>
                    <ChangeBillingForm {...props} />
                </Elements>
            </RecurlyProvider>
        </TransactionContextProvider>
    );
};

export default ChangeBillingRoot;
