import styled from "styled-components";

export const CoreRadio = styled.input`
    position: absolute;
    opacity: 0;
    z-index: 0;
    height: 22px;
    width: 22px;

    + label {
        display: flex;
        align-items: center;
        font-size: 0.95rem;
        line-height: 1.5rem;
        margin: 5px 0;
        cursor: pointer;

        .indicator {
            flex-shrink: 0;
            position: relative;
            display: block;
            width: 22px;
            height: 22px;

            background-color: #fff;
            border: 1px solid #b1b1b1;
            border-radius: 50%;
            cursor: pointer;
            margin-right: 10px;
            transition: background-color 0.2s;

            &::after {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;

                width: 6px;
                height: 6px;

                background-color: #fff;

                border-radius: 50%;
                opacity: 0;
                transform: translate(-50%, -50%) scale(0);

                transition: all 0.2s;

            }
        }
    }

    &:checked + label .indicator {
        background-color: #000;
        border-color: #000;

        &::after {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }
`;
