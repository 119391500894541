import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCompanyPurchasesQuery, useGetCompanyQuery } from 'store/features/user-api';
import { BoxedItem, CollaspableSection, Loader } from 'ui';
import { splitCollectionIntoCategories } from 'utils/splitCollectionIntoCategories';
import { ucFirst } from 'utils/ucFirst';
import DashboardFrame from '../DashboardFrame';
import renewsOrExpiredLine from '../SubscriptionsScreen/renewsOrExpiredLine';
import TransactionsPanel from '../TransactionsPanel';

const MultiUserPurchases = () => {
    const { id } = useParams();
    const { data: purchaseData, isFetching: isFetchingPurchases } = useGetCompanyPurchasesQuery(id);
    const { data: companyData, isFetching: isFetchingCompany } = useGetCompanyQuery(id);

    const items = useMemo(() => purchaseData?.purchases?.subscriptions || [], [purchaseData]);

    const categories = useMemo(() => {
        return splitCollectionIntoCategories(items, sub => {
            if (sub?.state === 'future') return 'future';
            return sub?.state === 'active' || sub?.state === 'canceled' ? 'active' : 'expired';
        }, ['active', 'future', 'expired']);
    }, [items]);

    if (isFetchingCompany) {
        return <DashboardFrame><Loader small text="Loading company" /></DashboardFrame>;
    }

    return (
        <DashboardFrame breadcrumbs={[['Home', '/'], ['Multi-User', '/multi-user'], ['Subscriptions', null]]}>
            <h2>{companyData?.company?.name} Subscriptions</h2>
            {isFetchingPurchases ? <Loader small text="Loading purchases" /> : <>
            {!(categories || []).length && <p>You don't have any subscriptions yet</p>} 

            {categories.map((cat, catI) => {
                let Wrap = React.Fragment;
                let wrapProps = {};
                
                if (categories.length > 1) {
                    Wrap = CollaspableSection;
                    wrapProps = { heading: cat.heading, initialState: catI === 0 };
                }
                
                return (
                    <Wrap key={`cat-${catI}`} {...wrapProps}>
                        {cat.items.map((sub, i) => (
                            <BoxedItem
                                key={`${cat.key}-${i}`}
                                heading={sub.plan?.name || 'Deleted plan'}
                                category={ucFirst(sub.state)}
                            >
                                <h4 style={{ margin: 0 }}>{renewsOrExpiredLine(sub)}</h4>
                            </BoxedItem>
                        ))}
                    </Wrap>
                );
            })}
            </>}
            <TransactionsPanel to={`/multi-user/${id}/transactions`} />
        </DashboardFrame>
    );
};

export default MultiUserPurchases;
