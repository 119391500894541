import React, { useMemo, useState, useCallback, useEffect } from "react";
import { toast } from 'react-toastify';
import { useChangePasswordMutation } from 'store/features/user-api';
import PasswordField from "components/LoginCreateAccount/PasswordField";
import { Button, LinkButton, Message } from "ui";
import SummaryPane from "../SummaryPane";
import { useLoggedInUser } from "hooks/useLoggedInUser";

const EditForm = ({ setEditing }) => {
    const [uiError, setUIError] = useState();
    const [nextPassword, setNextPassword] = useState('');
    const [savePassword, { isLoading: isUpdating, error: updateError, isSuccess }] = useChangePasswordMutation();

    const error = useMemo(() => uiError || updateError?.data?.error?.message, [uiError, updateError]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('Password changed successfully');
            setEditing(false);
        }
    }, [isSuccess, setEditing]);

    const handleSave = useCallback(e => {
        e.preventDefault();

        if (!nextPassword || !nextPassword.length) {
            return setUIError('Please enter a password below');
        } else if (!(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(nextPassword)) {
            return setUIError('Your password must be at least 8 characters long and contain the following: One upper case letter (a-z), one lower case letter (A-Z), and one number (0-9)');
        }

        setUIError(null);

        savePassword(nextPassword);
    }, [nextPassword, setUIError, savePassword]);

    return (
        <>
            {error && <Message error>{error}</Message>}
            <PasswordField disabled={isUpdating} value={nextPassword} onChange={e => setNextPassword(e.target.value)} />
            <Button loading={isUpdating} black fluid onClick={handleSave}>Save</Button>
            {!isUpdating && <p style={{ textAlign: 'center' }}><LinkButton onClick={() => setEditing(false)}>Cancel</LinkButton></p>}
        </>
    )
};


const Password = () => {
    const { data: profile } = useLoggedInUser();

    const [editing, setEditing] = useState();

    if (!profile.allow_password) {
        return null;
    }

    return (
        <SummaryPane heading="Password" onClick={() => setEditing(!editing)} buttonText={editing ? 'Cancel' : 'Edit'}>
            {editing ? <EditForm setEditing={setEditing} /> : <p>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</p>}
        </SummaryPane>
    );
};

export default Password;
