import React from 'react';
import { Message, TransactionMessage } from 'ui';
import { useSelector } from 'react-redux';
import { DEFAULT_ERROR_MESSAGE } from 'utils/constants';
import { useParams } from 'react-router-dom';

const TransactionComplete = () => {
    const { eventid } = useParams();
    const { code } = useSelector(state => state.transaction.transactionResponse) || {};

    if (!code) {
        return <Message error heading={DEFAULT_ERROR_MESSAGE}>Please refresh the page and try again.</Message>;
    }

    const link = `${process.env.REACT_APP_DOMAIN}/event/${eventid}/group/${code}`;

    return (
        <TransactionMessage heading="Thank you">
            <p><strong>Next Steps:</strong> To register for the event, copy the link below and share it with your colleagues. If you have further questions, please email <a href="mailto:support@skift.com">support@skift.com</a>.</p> 

            <div style={{ textAlign: 'center', marginBottom: '40px' }}>
                <h2 style={{ margin: '40px 0 15px 0' }}>Your Unique Registration Link</h2>
                <strong><a href={link} target="_blank" rel="noopener noreferrer">{link}</a></strong>
                <p>Save this link somewhere safe before leaving this page.</p>
            </div>
        </TransactionMessage>
    );
};

export default TransactionComplete;
