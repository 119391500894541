import React from 'react';
import { TRANSACTION_TYPES } from 'utils/constants';
import { useSelector } from 'react-redux';

import { TransactionSummary } from 'ui';

import SubscriptionTransactionSummary from './SubscriptionTransactionSummary';
import MultiUserTransactionSummary from './MultiUserTransactionSummary';

const PurchaseTransactionSummary = () => {
    const transactionType = useSelector(state => state.transaction.type);
    const isSubscription = transactionType === TRANSACTION_TYPES.SUBSCRIPTION;
    const isMultiUser = transactionType === TRANSACTION_TYPES.MULTI_USER_SUBSCRIPTION;


    if (isSubscription) {
        return <SubscriptionTransactionSummary />;
    }

    if (isMultiUser) {
        return <MultiUserTransactionSummary />;
    }

    return <TransactionSummary />;

};

export default PurchaseTransactionSummary;
