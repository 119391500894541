import { useTransactionTotal } from 'hooks';
import useTriggerEvent from 'hooks/useTriggerEvent';
import { useEffect } from 'react';
import { MYSK_EVENT_TYPES, TRANSACTION_TYPES } from 'utils/constants';

const ViewTransactionPageEvent = ({ itemId, itemName, type = TRANSACTION_TYPES.SUBSCRIPTION }) => {
    const triggerEvent = useTriggerEvent();
    const { data: total } = useTransactionTotal();

    useEffect(() => {
        if (!itemId || !itemName || !total?.total) return;

        const payload = {
            type, 
            itemId, 
            itemName
        };
        
        triggerEvent(MYSK_EVENT_TYPES.VIEW_TRANSACTION_PAGE, payload);
    }, [triggerEvent, itemId, itemName, total, type]);

    return null;
};

export default ViewTransactionPageEvent;
