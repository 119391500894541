import React, { useState } from 'react';
import { Input } from './Input';
import styled from 'styled-components';

import eye from 'assets/icons/eye-solid.svg';
import eyeSlash from 'assets/icons/eye-slash-solid.svg';

const EyeButton = styled.div`
    position: absolute;
    top: 0;
    right: 15px;
    border: none;
    background: none;
    outline: none;

    width: 20px;
    height: 100%;
    
    cursor: pointer;

    display: flex;
    align-items: center;

    img {
        max-width: 100%;
    }
`;

const StyledPasswordInput = styled(Input)`
    padding-right: 45px;
`;

export const PasswordInput = props => {
    const [showPassword, setShowPassword] = useState(false);

    const handleEyeClick = e => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    return (
        <>
            <EyeButton onClick={handleEyeClick}>
                <img src={showPassword ? eyeSlash : eye } alt={showPassword ? 'Hide Password' : 'Show Password'} />
            </EyeButton>
            <StyledPasswordInput {...props} type={showPassword ? 'text' : 'password'} />
        </>
    )
};
