import { MYSK_EVENT_TYPES } from 'utils/constants';

const listeners = {};
const triggeredEvents = {};

const eventTrackingMiddleware = store => next => action => {
    next(action);

    const sendEvent = ({ type, payload }) => {
        if (!type) {
            return;
        }

        (listeners[type] || []).forEach(cb => cb(payload));

        triggeredEvents[type] = triggeredEvents[type] || [];
        triggeredEvents[type].push(payload);

        // send to the GTM datalayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: type,
            ...payload
        });
    };

    if (action.type.indexOf('transaction/set') === 0) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            ...parseTransactionStateForEvents(store.getState().transaction)
        });
    }

    if (action.type === 'transaction/submitTransaction/fulfilled') {
        const state = store.getState();

        sendEvent({
            type: MYSK_EVENT_TYPES.TRANSACTION_COMPLETE,
            payload: {
                transaction_id: action.payload.transaction_id,
                email: state.tracking.email,
                ...parseTransactionStateForEvents(state.transaction),
            }
        });
    }

    if (action.type === 'transaction/submitTransaction/rejected') {
        const state = store.getState();
        const transactionType = state.transaction.type;

        sendEvent({
            type: MYSK_EVENT_TYPES.TRANSACTION_FAILED,
            payload: {
                type: transactionType,
                error: {
                    message: action.error.message
                }
            }
        });
    }

    if (action.type === 'tracking/triggerEvent') {
        sendEvent(action.payload);
    }
};

const addListener = ({ type, cb }) => {
    if (typeof cb !== 'function') {
        console.error('MySk event callack must be a function');
        return;
    }
    
    listeners[type] = listeners[type] || [];
    listeners[type].push(cb);

    // if events have already happened before the listener was added, resend them
    (triggeredEvents[type] || []).forEach(e => cb(e));
};

export const initializeEvents = (() => {
    (window.myskEventListeners || []).forEach(listener => addListener(listener));

    window.myskEventListeners = {
        push: payload => addListener(payload)
    };
})();

export const parseTransactionStateForEvents = transaction => {
    transaction = {...transaction};
    delete transaction.error;
    delete transaction.processing;
    delete transaction.billingAddress;
    delete transaction.paymentToken;
    delete transaction.profile;
    delete transaction.optIns;
    return transaction;
};

export default eventTrackingMiddleware;
