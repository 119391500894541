import { useState, useCallback } from "react";

export const useOptInValidation = () => {
    const [errors, setErrors] = useState([]);
    const [validationRan, setValidationRan] = useState();

    const validate = useCallback(({ optIns: values }) => {
        const requiredOptIns = [
            { key: 'dataConsent', text: 'You must allow us to store and process your data.' },
            { key: 'filmingConsent', text: 'You must agree to the notice of filming.' } 
        ];
        
        const nextErrors = requiredOptIns.reduce((a, field) => {
            const { text, key } = field;
            const value = values[key];
    
            if (!value) {
                a.push({
                    field: key,
                    text
                });
            }

            return a;
        }, []);

        setErrors(nextErrors);
        setValidationRan(true);
        
        return nextErrors.length === 0;
    }, []);

    return [errors, validate, validationRan];
};
