const updateQueryStringParameter = (key, value) => {
    let uri = window.location.href;
    const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    const uriContainsKey = uri.match(re);

    if (!value && !uriContainsKey) {
        return uri;
    }

    let addition = key + "=" + value;

    if (uriContainsKey) {
        if (!value) {
            // remove key
            addition = '';
        }

        uri = uri.replace(re, '$1' + addition + '$2');
    } else {
        uri = uri + (uri.indexOf('?') !== -1 ? '&' : '?') + addition;
    }

    // remove trailing & and ?
    while(['?', '&'].includes(uri.charAt(uri.length - 1))) {
        uri = uri.slice(0, -1)
    }

    return uri;
};

const appendToQuerystringMiddleware = () => next => action => {
    let nextUrl;
    if (action.type === 'transaction/setPromoCode') {
        nextUrl = updateQueryStringParameter('promo', action.payload);
    }

    if (action.type === 'transaction/setAccessCode') {
        nextUrl = updateQueryStringParameter('access_code', action.payload.access_code);
    }

    if (action.type === 'transaction/setMultiSeatAmount') {
        nextUrl = updateQueryStringParameter('seats', action.payload.quantity);
    }

    if (nextUrl) {
        window.history.replaceState({}, '', nextUrl);
    }

    next(action);
};

export default appendToQuerystringMiddleware;
