import SummaryPane from 'components/Dashboard/SummaryPane';
import React, { useState } from 'react';
import BillingProperties from './BillingProperties';
import ChangeBillingRoot from './ChangeBillingRoot';

const BillingInfo = () => {
    const [editing, setEditing] = useState();

    return (
        <SummaryPane heading="Billing Information" onClick={() => setEditing(!editing)} buttonText={editing ? 'Cancel' : 'Update'}>
            {editing ? <ChangeBillingRoot cancel={() => setEditing(false)} /> : <BillingProperties />}
        </SummaryPane>
    );
};

export default BillingInfo;
