import React, { useContext, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useContentItem } from 'hooks';
import { TransactionContext } from 'components/TransactionContext';
import { Checkbox, DisclaimerText, Errors } from 'ui';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setOptins } from 'store/features/transaction';
import { useOptInValidation } from 'hooks/useOptInValidation';

const OptIn = styled.div`
    margin: 20px 0;
    border-bottom: 1px solid #ccc;

    &:last-child {
        border: none;
    }
`;

const Wrap = styled.div`
    margin: 30px 0 40px;

    ${props => {
        if (props.inactive) {
            return `opacity: 0.3;`;
        }
    }}
`;

const OptIns = ({ inactive }) => {
    const dispatch = useDispatch();
    const values = useSelector(state => state.transaction.optIns);
    const { data: item } = useContentItem();
    const { addValidator, removeValidator, processing } = useContext(TransactionContext);
    const [errors, validate, validationRan] = useOptInValidation();

    useEffect(() => {
        addValidator('opt_ins', validate);
        
        return function cleanup() {
            removeValidator('opt_ins');
        };
    }, [addValidator, validate, removeValidator]);

    const additionalOptIns = item?.details?.additional_optins || [];
    const showNoticeOfFilming = item?.details?.show_notice_of_filming || false;

    const handleChange = e => {
        const nextValue = { [e.target.name]: !values[e.target.name] };

        dispatch(setOptins(nextValue));

        if (validationRan) {
            validate({ optIns: { ...values, ...nextValue } });
        }
    };

    return (
        <Wrap inactive={inactive}>
            <Errors errors={errors} />
            <DisclaimerText>  
                <OptIn>
                    <p>Skift is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other partner content that may be of interest to you. If you consent to us or Skift and our partners contacting you for this purpose, please tick below.</p>
                    <Checkbox
                        id="marketingConsent"
                        name="marketingConsent"
                        disabled={processing}
                        checked={values.marketingConsent || false}
                        onChange={handleChange} 
                        label="I agree to receive other communications from Skift."
                    />
                    <Checkbox
                        id="partnerConsent"
                        name="partnerConsent"
                        disabled={processing}
                        checked={values.partnerConsent || false}
                        onChange={handleChange} 
                        label="I agree to receive other communications from Skift's partners."
                    />
                </OptIn>
                <OptIn>
                    <p>In order to register for this event, we need to store and process your personal data. If you consent to us storing your personal data for this purpose, please tick the checkbox below.</p>
                    <Checkbox
                        id="dataConsent"
                        name="dataConsent"
                        disabled={processing}
                        checked={values.dataConsent || false}
                        onChange={handleChange} 
                        label="I agree to allow Skift to store and process my personal data."
                    />
                </OptIn>

                {showNoticeOfFilming && (
                    <OptIn>
                        <p>To attend an in-person Skift event you must agree to our <a href="https://live.skift.com/notice-of-filming-and-photography/" target="_blank" rel="noopener noreferrer">notice of filming and photography</a>.</p>
                        <Checkbox
                            id="filmingConsent"
                            name="filmingConsent"
                            disabled={processing}
                            checked={values.filmingConsent || false}
                            onChange={handleChange} 
                            label="I agree to the notice of filming and photography."
                        />
                    </OptIn>
                )}

                {additionalOptIns.map((optin, idx) => {
                    return (
                        <OptIn key={`optin-${idx}`}>
                            <ReactMarkdown>{optin.text}</ReactMarkdown>
                            <Checkbox
                                id={`optin-${optin?.id}`}
                                name={optin?.id}
                                disabled={processing}
                                checked={values[optin?.id] || false}
                                onChange={handleChange} 
                                label={optin?.label}
                            />
                        </OptIn>
                    );
                })}
            </DisclaimerText>
        </Wrap>
    );
};

export default OptIns;