import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { Panel } from './Panel';
import { formatCurrency } from 'utils/formatNumber';
import { Message } from './Message';
import { Loader } from './Loader';
import { PromoCode } from './PromoCode';
import skiftLogo from 'assets/skift-logo.svg';
import { useContentItem, useTransactionTotal } from 'hooks';
import { TransactionContext } from 'components/TransactionContext';
import { setAmount } from 'store/features/transaction';


export const ProductHeading = styled.h2`
    font-size 22px;
`;

const ProductLogo = styled.img`
    height: 65px;
    width: 200px;
    object-fit: contain;
    object-position: left;
    max-width: 100%;
    margin-bottom: 15px;
`;
const ProductDescription = styled.div`
    font-size: 16px;
`;
const ProductPricing = styled.div`
    border-top: 1px solid ${props => props.theme.borderColor};
    padding-top: ${props => props.theme.gutterSize};
    margin-top: ${props => props.theme.gutterSize};
`;

export const PricingLabel = styled.p`
    font-weight: bold;
    font-family: ${props => props.theme.serifFont};
`;
export const PricingValue = styled.p`
    font-family: ${props => props.theme.serifFont};
`;

export const PricingLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    + * {
        margin-top: 5px;
    }

    p {
        margin: 0;
    }

    ${props => {
        if (props.large) {
            return `
                font-size: 19px;
            `;
        }

        if (props.gray) {
            return `
                color: ${props.theme.grayText};
            `;
        }
    }}
`;

const PricingSection = ({ total, loading, error }) => {
    const currency = useSelector(state => state.transaction.currency);

    if (error) {
        return <Message error small heading="Error calculating total">{error.data.error.message || 'Error calculating total. Please refresh the page to try again.'}</Message>;
    }

    if (loading) {
        return (
            <ProductPricing>
                <PricingLine large>
                    <PricingLabel>Due Today</PricingLabel>
                    <Loader small style={{ margin: 0 }} />
                </PricingLine>
            </ProductPricing>
        );
    }

    if (total.payWhatYouWant) {
        return (
            <ProductPricing>
                <PricingLine large>
                    <PricingLabel>Due Today</PricingLabel>
                    <PricingValue>Pay what you want</PricingValue>
                </PricingLine>
            </ProductPricing>
        );
    }

    return (
        <ProductPricing>
            {(total.discount > 0 || total.tax > 0) &&
                <PricingLine gray>
                    <PricingLabel>Subtotal</PricingLabel>
                    <PricingValue>{formatCurrency(total.subtotal, currency)}</PricingValue>
                </PricingLine>
            }
            {total.discount > 0 && 
                <PricingLine gray>
                    <PricingLabel>Discount</PricingLabel>
                    <PricingValue>{formatCurrency(total.discount, currency, true)}</PricingValue>
                </PricingLine>
            }
            {total.tax > 0 && 
                <PricingLine gray>
                    <PricingLabel>Sales Tax</PricingLabel>
                    <PricingValue>{formatCurrency(total.tax, currency)}</PricingValue>
                </PricingLine>
            }
            <PricingLine large>
                <PricingLabel>Due Today</PricingLabel>
                <PricingValue>{total.total > 0 ? formatCurrency(total.total, currency) : 'FREE'}</PricingValue>
            </PricingLine>
        </ProductPricing>
    );
};

export const TransactionSummary = ({
    productName,
    logoUrl,
    description,
    children,
}) => {
    const { 
        data: total, 
        isFetching: isFetchingTotal, 
        error: errorLoadingTotal 
    } = useTransactionTotal();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!total) return;
        
        dispatch(setAmount(total.total));
    }, [dispatch, total]);

    const { data: item, isFetching: isFetchingItem } = useContentItem();

    const { name, description: itemDescription, has_active_coupons, logo_url: logoFromItem } = item?.details || {};
    const { promoCodesEnabled = true, showPricingSection = true } = useContext(TransactionContext)

    // allow props to override everything
    productName = productName || name;
    description = description || itemDescription;
    logoUrl = logoUrl || logoFromItem || skiftLogo;
    children = children || <ProductHeading>{productName}</ProductHeading>;

    return (
        <Panel padded showLoader={isFetchingItem} sticky>
            <ProductLogo src={logoUrl} alt={productName} />
            {children}
            <ProductDescription><ReactMarkdown>{description}</ReactMarkdown></ProductDescription>
            {has_active_coupons && promoCodesEnabled && <PromoCode loading={isFetchingTotal} total={total} />}
            {showPricingSection && <PricingSection total={total} loading={isFetchingTotal} error={errorLoadingTotal} />}
        </Panel>
    );
};
