import React from 'react';
import axios from 'axios';

import { TextField } from 'ui';

export const PostalCodeField = ({ onChange, ...props }) => {
    const handleChange = e => {
        const zip = e.target.value;

        if (zip && zip.length > 4) {
            (async () => {
                try {
                    const resp = await axios.get(`https://api.zippopotam.us/us/${zip}`);
                    const data = resp?.data || {};
                
                    const changes = {
                        zip,
                        country: data['country abbreviation'],
                    };

                    const places = data?.places || [];
                    const place = places.length && places[0];

                    if (place) {
                        changes.city = place['place name']; // the space is intentional
                        changes.region = place['state'];
                    }

                    onChange(changes);
                } catch {
                    onChange({ zip });
                }
            })();
        } else {
            onChange({ zip });
        }
    };
    
    return <TextField onChange={handleChange} {...props} />;
};
