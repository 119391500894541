import React, { useState, useCallback } from 'react';
import { useSearchCompaniesWithEmailQuery } from 'store/features/user-api';
import { Panel, TransactionMessage } from 'ui';
import EnterpriseSearchBar from './EnterpriseSearchBar';
import EnterpriseSearchResult from './EnterpriseSearchResult';

const EnterpriseSearch = () => {
    const [email, setEmail] = useState('');

    const {
        data: companies,
        isFetching,
        error: serverError
    } = useSearchCompaniesWithEmailQuery(email, { skip: !email });

    const reset = useCallback(() => {
        setEmail('');
    }, []);

    return (
        <TransactionMessage
            heading=''
            isFetching={isFetching}
            loaderText='Loading companies'
            showDashboardLink={false}
        >
            <Panel padded>
                <h1>Skift for Enterprise</h1>
                <h2 style={{ fontSize: '1.25rem' }}>
                    See if your company has an enterprise subscription
                </h2>
                {email && companies ? (
                    <EnterpriseSearchResult companies={companies} reset={reset} />
                ) : (
                    <>
                        <EnterpriseSearchBar
                            email={email}
                            setEmail={setEmail}
                            serverError={serverError?.data?.error?.message}
                        />
                    </>
                )}
            </Panel>
        </TransactionMessage>
    );
};

export default EnterpriseSearch;
