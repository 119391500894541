import styled from 'styled-components';

export const ShareFlag = styled.div`
    background-color: #fff22f;
    color: #000;
    padding: 0 20px;
    font-size: 0.95rem;
    height: 42px;
    line-height: 42px;
    position: relative;

    &:before,
    &:after {
        content: '';
        border-right: 8px solid transparent;
        width: 0;
        height: 0;
        position: absolute;
        left: 100%;
    }

    &:before {
        top: 0;
        border-bottom: 21px solid #fff22f;
    }

    &:after {
        bottom: 0;
        border-top: 21px solid #fff22f;
    }
`;

export const ShareButton = styled.button`
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    cursor: pointer;

    img {
        display: flex;
    }
`;

export const MobileOnlyShare = styled.li`
    display: block !important;

    cursor: pointer;

    @media only screen and (min-width: 645px) {
        display: none !important;
    }
`;