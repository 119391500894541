import React, { useState } from 'react';

import { Radio, Input } from 'ui';
import { CURRENCY_SYMBOLS } from 'utils/constants';
import { formatCurrency } from 'utils/formatNumber';
import { Flex } from './Flex';

const VariableAmountPicker = ({ 
    processing, 
    value, 
    onChange, 
    allowOther, 
    currency,
    options
}) => {    
    const [otherChecked, setOtherChecked] = useState();

    const useOther = otherChecked || !options.includes(value);

    return (
        <>
            {options.map((option, i) => (
                <Radio 
                    key={`amount-option-${i}`}
                    id={`amount-option-${i}`}
                    label={formatCurrency(option, currency)}
                    name="amount" 
                    value={option} 
                    checked={value === option && !otherChecked}
                    disabled={processing}
                    onChange={() => { setOtherChecked(false); onChange(option); }}
                />
            ))}
            {allowOther && 
                <>
                    <Radio 
                        id="other-amount"
                        label="Other Amount"
                        name="other-amount" 
                        value={-1} 
                        checked={useOther}
                        processing
                        onChange={() => setOtherChecked(true)}
                    />
                    {useOther && <Flex alignItems="center"><div style={{ marginRight: '10px' }}>{CURRENCY_SYMBOLS[currency]}</div><Input placeholder="Other amount" type="number" name="other-amount" min={0} value={value} onChange={e => onChange(e.target.value ? +e.target.value : '')} /></Flex>}
                </>
            }
        </>
    );
};

export default VariableAmountPicker;
