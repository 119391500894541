import { useState, useEffect, useCallback } from 'react';
import { useBraintreeToken } from './useBraintreeToken';
import Braintree from 'braintree-web/client';
import BraintreeDataCollector from 'braintree-web/data-collector';
import { useDispatch, useSelector } from 'react-redux';
import { setBraintreeDeviceData } from 'store/features/braintree';

export const useBraintreeDeviceData = () => {
    const { isLoading: isLoadingToken, token, error: tokenError } = useBraintreeToken();
    const deviceData = useSelector(state => state.braintree.deviceData);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    
    const dispatch = useDispatch();

    const fetchData = useCallback(() => {
        if (!token) {
            return;
        }
        
        Braintree.create({ authorization: token }, (err, clientInstance) => {
            if (err) {
                return setError(err);
            }
            
            setIsLoading(true);

            BraintreeDataCollector.create({
                client: clientInstance,
                kount: true,
            }, (err, dataCollectorInstance) => {
                setIsLoading(false);

                if (err) {
                    return setError(err);
                }
                dispatch(setBraintreeDeviceData(dataCollectorInstance.deviceData))
            });
        });
    }, [token, setIsLoading, dispatch, setError]);
    
    useEffect(() => {
        if (deviceData) return;
        fetchData();
    }, [deviceData, fetchData]);

    return { error: error || tokenError, deviceData, isLoading: isLoading || isLoadingToken }
};
