import { useContentItem } from 'hooks';
import { useLoggedInUser } from 'hooks/useLoggedInUser';
import React from 'react';
import { Message } from 'ui';

const SubscriberThankYou = () => {
    const { data: item } = useContentItem();
    const { valid_free_plan } = item?.details;
    const { data: profile, isFetching: isFetchingUser } = useLoggedInUser();

    if (!valid_free_plan || isFetchingUser) {
        return null;
    }

    return (
        <Message 
            style={{ marginTop: 0, maxWidth: 'unset' }}
            black 
            jumbotron
            heading={`Thanks for being a subscriber, ${profile.first_name}.`} 
        >Subscribers to {valid_free_plan.name} can register for this event for free.</Message>
    );
};

export default SubscriberThankYou;
