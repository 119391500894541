import React, { useMemo } from 'react';
import { useGetPurchasesQuery } from 'store/features/user-api';
import SummaryPane from '../SummaryPane';

const SubscriptionSummary = () => {
    const { data: transactions } = useGetPurchasesQuery();
    const subscriptions = transactions?.subscriptions;

    const text = useMemo(() => {
        let text = 'You have ';

        const activeStatus = ['active', 'canceled'];
        const activeSubscriptions = (subscriptions || []).filter(s => activeStatus.includes(s.state));
        const inactiveSubscriptions = (subscriptions || []).filter(s => !activeStatus.includes(s.state));
        
        if (activeSubscriptions.length) {
            const activeSites = activeSubscriptions.reduce((a, sub) => {
                sub.plan.site_access.forEach(site => {
                    if (!a.includes(site.name)) {
                        a.push(site.name);
                    }
                });
                
                return a;
            }, []);

            if (activeSites.length > 1) {
                activeSites[activeSites.length - 1] = `and ${activeSites[activeSites.length - 1]}`;
            }

            text += `${activeSubscriptions.length === 1 ? 'an ' : ''}active subscription${activeSubscriptions.length > 1 ? 's' : ''} to ${activeSites.join(activeSites.length > 2 ? ', ' : ' ')}`;
        }

        if (inactiveSubscriptions.length) {
            if (activeSubscriptions.length) {
                text += '. You also have ';
            }

            text += `${inactiveSubscriptions.length} inactive subscription${inactiveSubscriptions.length > 1 ? 's' : ''}`;
        }

        text += '.';

        return text;
    }, [subscriptions]);


    if (!subscriptions.length) {
        return;
    }

    return (
        <SummaryPane headingMargin="0 0 20px 0" heading="Subscriptions" to="/subscriptions">
            <p>{text}</p>
        </SummaryPane>
    );
};

export default SubscriptionSummary;
